import React, { createContext, useMemo } from 'react';
import { ethers } from 'ethers';
import chains from 'config/constants/chains';
import { sample } from 'lodash';
import { Chain } from 'state/types';

interface ChainsContextApi {
  simpleRpcProviders: Record<string, ethers.providers.JsonRpcProvider>;
  chains: Record<number, Chain>;
}

export const ChainsContext = createContext<ChainsContextApi>(undefined);

export const ChainsProvider: React.FC = ({ children }) => {
  const simpleRpcProviders = useMemo(() => Object.keys(chains).reduce((prev, key) => {
    const simpleRpcProvider = new ethers.providers.JsonRpcProvider(sample(chains[key].rpcUrls));
    return { [key]: simpleRpcProvider, ...prev };
  }, {}), [chains]);

  return (
    <ChainsContext.Provider value={{ simpleRpcProviders, chains }}>
      {children}
    </ChainsContext.Provider>
  );
};
