import { Button, styled, Theme } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import React from "react";
import TimeMachineFilterItem from "./TimeMachineFilterItem";
import ComingSoonTooltip from "components/ComingSoonTooltip";


const StyledTimeMachineFilterItem = styled(TimeMachineFilterItem)(
  sx<Theme>({
    WebkitTextFillColor: (theme) => theme.palette.text.secondary,
    "&.MuiButton-root": {
      bgcolor: 'white',
    },
    "&.MuiButton-root:hover":{
      bgcolor: 'white',
    },
  })
)

const TimeMachineComingSoonFilterItem:React.FC<{active?: boolean, [key: string]: any,}> = ({
  children,
  ...otherProps
}) => {
  return <ComingSoonTooltip>
    <StyledTimeMachineFilterItem 
      active={false}
      {...otherProps}
    >
      {children}
    </StyledTimeMachineFilterItem>
  </ComingSoonTooltip>
}

export default TimeMachineComingSoonFilterItem;