import React from 'react';
import { SxProps, experimental_sx as sx, GridProps } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import { Typography } from '@mui/material';

const StyledGrid = styled(Grid)(
  sx({
    alignItems: 'center',
    flexWrap: 'nowrap',
  })
);

export const InlineMiddleFlex:React.FC<GridProps & {[key: string]: any}> = ({
  children,
  ...props
}) => {
  return <StyledGrid container gap={1} {...props}>
    {children}
  </StyledGrid>
}