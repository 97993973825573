import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, Divider, Grid, Paper, styled, Tab, Tabs } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken } from "components/Image/helpers";
import images from "assets/images";
import { Text } from "components/Text";
import { useChainList } from "lib/timeMachineHelpers";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from "lodash";

const FilterGroupItemsGrid = styled(Grid)(
  sx({
    gap: 1.5,
  })
)

const TimeMachineFilterGroup:React.FC<{label: any}> = ({
  label = "DEX",
  children,
}) => {
  return <Grid container flexWrap={'nowrap'} alignItems={'center'}>
    <Grid flex="0 1 120px">
      <Text typography="body2" bold="600">{label}</Text>
    </Grid>
    <Grid flex="1 1 min-content">
      <FilterGroupItemsGrid container>
        {children}
      </FilterGroupItemsGrid>
    </Grid>
  </Grid>
}

export default TimeMachineFilterGroup;