import React, { useState } from "react";
import { Box, SxProps } from "@mui/system";
import { Button, Divider, Grid, Paper, styled, Tab, Tabs } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken } from "components/Image/helpers";
import images from "assets/images";
import { Text } from "components/Text";
import { useChainList } from "lib/timeMachineHelpers";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { grey } from '@mui/material/colors';
import _ from "lodash";


const StyledButton = styled(Button)(
  sx({
    py: 0.5,
    px: 2,
    border: '1px solid white',
    borderRadius: 1,
    "& .MuiButton-startIcon": {
      height: '18px',
    },
    "&.MuiButton-root:hover":{
      bgcolor: 'primary.dark',
    },
  })
)

const TimeMachineFilterItem:React.FC<{active?: boolean, [key: string]: any, sx?: SxProps}> = ({
  active = false,
  sx,
  children,
  ...otherProps
}) => {
  return <StyledButton 
    sx={[
      !active && {
        "&.MuiButton-root":{
          bgcolor: 'grey.100',
          borderColor: 'grey.100',
          color: "#656E78",
          "&:hover": {
            bgcolor: 'primary.light',
          }
        }
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    color="primary"
    variant={active?'contained':'outlined'}
    {...otherProps}
  >
    {children}
  </StyledButton>
}

export default TimeMachineFilterItem;