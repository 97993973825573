import React from 'react';

/* eslint-disable max-len */
export const itemDescriptions = {
  "Rank": `Rank according to 24-hour AROI (with Price Effect) in the Chain across available DEXs`,
  // "HHR": [`Harvest Healthiness Ratio (` ,React.createElement("span", {style: {color: "#E84142"}}, "-ve: Loss"), ` / `, React.createElement("span", {style: {color: "#2DCE89"}}, "+ve: Gain"), `)
  // This figure indicates the harvest healthiness of the pool — how trading fee and DEX reward outweigh price impact. The ratio ranges from -1 to infinity.`],
  "HHR": "Harvest healthiness of the pool based on 30-day APR, measuring the weight of yield compared to price effect in total return. Refer our docs for more",
  "Growth Rate": `7-day / 90-day Trading Fees APR, measuring recent liquidity utilization compared to long term`,
  "RRR": `Total Annual Return on Investment in USD, calculated based on backward-simulation over the considered timeframe`,
  "Metric LP Reward": `Pairs with trading fees APR higher than yield farming APR in the past 30 days.\nThe yield from those pairs is usually more long-term, as the yield farming reward distributed by DEX is not guaranteed.`,
  "Metric Price Impact": `Pairs with net equity value of the LP tokens increased compared to 30 days ago.\nFarming these pairs won't have to suffer a loss of value due to the price change of both tokens.`,
  "Metric Growth Rate": `Pairs with 7-day trading fees APR higher than that of 90-day, indicating recently improved liquidity utilization. \nFarming these pairs usually result in higher trading fees income in the long term.`,
  "Metric Positive Return": `Pairs with positive total return in 180 days. \nIt indicates the total yield can cover the loss due to the price effect in the long run.`,
  "Last Hour": "Last Hour",
  "Volume 24H": "Up to last hour",
  "Simulate Principal": "Principal was 50/50 split between the two tokens",
  "Dex Yield": "{Dex Yield}",
  "Liquidity": "As of last hour",
  "Volume": "Last 24 hour total",
  "Yield Farming": "Reward token value based on hourly closing price",
};
