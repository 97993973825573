import { styled } from '@mui/material/styles';

const Svg = styled('svg')`
  align-self: center; // Safari fix
  flex-shrink: 0;
`;

Svg.defaultProps = {
  color: 'text',
  width: '20px',
  xmlns: 'http://www.w3.org/2000/svg',
};

export default Svg;
