import {
 DateRangePicker, TabContext, TabList, TabPanel,
} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
 alpha, Button, Divider, FormControlLabel, Grid, Paper, Skeleton, Switch, Tab, Tabs, TextField, Theme, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx, SxProps } from '@mui/system';
import images from 'assets/images';
import { GradientButton } from 'components/GradientButton';
import { ArrowRepeatIcon, CalenderIcon } from 'components/Svg';
import { Text, TrendText } from 'components/Text';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TokenInfoDto } from 'lib/timeMachineHelpers';
import React, {
 createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
 formatNumber, formatPercent, formatTokenAmount, formatPriceCurrency, formatPrice,
} from 'utils/formatNumber';
import _ from 'lodash';
import TokenImage from 'components/Image/TokenImage';
import { ChartResult, FarmSimulateDto } from '@iddy/time-machine';
import { format } from 'prettier';
import { TabsContext } from '@mui/base';
import TokenPairImage from 'components/Image/TokenPairImage';
import { getImageUrlFromTokenDto } from 'components/Image/helpers';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { CircleIcon } from 'components/CircleIcon';
import InfoDescriptionPopover from 'components/InfoDescriptionPopover';
import { GraphLegendItem } from 'components/GraphLegendItem';
import { RadiusButtonToggleButtonGroup } from 'components/RadiusButtonToggleButtonGroup';
import HistoricalCardPaper from './HistoricalCardPaper';
import TimeMachineTimeTunnelFilter from './TimeMachineTimeTunnelFilter';
import { useFormattedGraphDataForChartLib } from '../hooks/useFormattedGraphDataForChartLib';

import { useFormattedCoinDataForChartLib } from '../hooks/useFormattedCoinDataForChartLib';
import TimeMachineTimeTunnelRealReturnRateGraph from './TimeMachineTimeTunnelRealReturnRateGraph';
import TimeMachineTimeTunnelTotalEvGraph from './TimeMachineTimeTunnelTotalEvGraph';
import { useDetailPageContext } from '../context';
import TimeMachineLoadingScreen from './TimeMachineLoadingScreen';

const GraphDetailContext = createContext<{
  lossView: 'priceImpact' | 'impermanentLoss',
  setLossView:(v) => void,
}>(null);

const TimeMachineTimeTunnelDetail:React.FC<{
  isReady: boolean,
  data: ChartResult,
  summary: {roi: FarmSimulateDto, apr: FarmSimulateDto},
  principal,
}> = ({
  data,
  summary,
  isReady,
  principal,
}) => {
  const { info } = useDetailPageContext();
  const [lossView, setLossView] = useState<'priceImpact' | 'impermanentLoss'>('priceImpact');
  const loading = !info || !isReady;
  const handleChange = (e, v) => {
    setLossView(v);
  };
  return (
    <>
      <GraphDetailContext.Provider value={{ lossView, setLossView }}>
        <HistoricalCardPaper title="Simulated Performance">
          <Box ml={2} mb={2} pt={2}>
            <RadiusButtonToggleButtonGroup
              color="primary"
              exclusive
              value={lossView}
              onChange={handleChange}
            >
              <ToggleButton key="priceImpact" value="priceImpact">Return incl. Price Effect</ToggleButton>
              <ToggleButton key="impermanentLoss" value="impermanentLoss">Return incl. Impermanent Loss</ToggleButton>
            </RadiusButtonToggleButtonGroup>
          </Box>
          {!loading
      ? (
        <Grid container>
          <Grid item flex="1000 1 500px" sx={{ width: '100%' }}>
            <PerformanceGraphPaper data={data} principal={principal} />
          </Grid>
          <Grid item flex="1 1 280px">
            <PerformanceSummaryPaper summary={summary} info={info} principal={principal} />
          </Grid>
        </Grid>
      )
      : <TimeMachineLoadingScreen />}
        </HistoricalCardPaper>
      </GraphDetailContext.Provider>
    </>
);
};

const PrincipalPaper = styled(Paper)(
  sx({
    height: '100%',
    p: 3,
    display: 'flex',
    alignItems: 'center',
  }),
);

const PrincipalInitialValue:React.FC<{info: TokenInfoDto, priceRatio: number, assumedLPToken: number}> = ({
  info,
  priceRatio,
  assumedLPToken,
}) => (
  <PrincipalPaper>
    <Grid container flexDirection="column" justifyContent="center" alignItems="flex-start" gap={2}>
      <Grid item>
        <InlineMiddleFlex>
          <Text bold="700" typography="body2">Principal initial value</Text>
          <InfoDescriptionPopover description="Simulate Principal" />
        </InlineMiddleFlex>
      </Grid>
      <Grid item gap={1}>
        <Text typography="h4">
          <InlineMiddleFlex>
            <Grid flex="0 0 40px">
              <TokenPairImage
                primarySrc={getImageUrlFromTokenDto(info.token)}
                secondarySrc={getImageUrlFromTokenDto(info.quoteToken)}
                width={40}
                height={24}
              />
            </Grid>
            <Text>
              <b>{formatTokenAmount(assumedLPToken)}</b>
              {' '}
              <Text sx={{ display: 'inline-block' }}>
                {info.token.symbol}
                -
                {info.quoteToken.symbol}
              </Text>
            </Text>
          </InlineMiddleFlex>
        </Text>
      </Grid>
      <Grid sx={{ mt: 'auto' }} item>
        <Text typography="small">
          1
          {' '}
          {info.token.symbol}
          {' '}
          =
          {' '}
          {formatNumber(priceRatio, 0, 4)}
          {' '}
          {info.quoteToken.symbol}
        </Text>
        <Text typography="small">
          1
          {' '}
          {info.quoteToken.symbol}
          {' '}
          =
          {' '}
          {formatNumber(1 / priceRatio, 0, 4)}
          {' '}
          {info.token.symbol}
        </Text>
      </Grid>
    </Grid>
  </PrincipalPaper>
);

const SummaryBreakDownGrid = styled(Grid)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    p: 2,
  }),
);

const SummaryTotalGrid = styled(SummaryBreakDownGrid)(
  sx<Theme>({
    background: (theme) => alpha(theme.palette.primary.main, 0.05),
  }),
);

const SummaryBreakDownItem = styled(Box)(
  sx({
    gap: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    '& > *:first-child': {
      flex: '1 1 auto',
      typography: 'body2',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
    },
    '& > *:last-child': {
        flex: '0 0 max-content',
        typography: 'h4',
        fontWeight: 600,
      },
  }),
);

const StyledArrowRepeatIcon = styled(ArrowRepeatIcon)(
  sx<Theme>({
    fill: (theme) => theme.palette.text.secondary,
  }),
);

const SwitchButton = styled(InlineMiddleFlex)(({ active }) => sx([{
    cursor: 'pointer',
    fontWeight: 600,
    '& .MuiSwitch-track': {
      bgcolor: 'text.secondary',
    },
  },
    !active && { color: 'text.secondary' },
  ]));

const SummaryContainer = styled(Box)(
  sx({
    height: '100%',
    p: 3,
    display: 'flex',
  }),
);

const SummaryContentGrid = styled(Grid)(
  sx({
    border: 1,
    borderColor: 'greyColor.light',
    borderRadius: 1,
  }),
);

const SummaryHeader = styled(Grid)(
  sx({
    px: 2,
    py: 1.5,
    borderBottom: 1,
    borderBottomColor: 'greyColor.light',
  }),
);

const SummaryBreakDownBox = styled(Box)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
    p: 1.5,
    background: '#F6F9FC',
    borderRadius: 1,
  }),
);

const PerformanceSummaryPaper:React.FC<{
  info: TokenInfoDto,
  summary: {roi: FarmSimulateDto, apr: FarmSimulateDto},
  principal: number,
}> = ({
  summary,
  info,
  principal,
}) => {
  const [view, setView] = useState('roi');
  const { lossView } = useContext(GraphDetailContext);
  const data:FarmSimulateDto = useMemo(() => summary.roi, [view, summary]);
  const dataApr:FarmSimulateDto = useMemo(() => summary.apr, [view, summary]);

  const [totalYieldValue, totalYieldValueApr] = useMemo(() => {
    // has extra reward token
    if (summary.apr.extraReward !== [] && summary.roi.extraReward && summary.roi.extraReward !== []) {
      const totalRewarderYieldUsdArr = summary.roi.extraReward.map((v) => v.totalRewarderYieldUsd);
      const totalRewarderYieldUsdAprArr = summary.apr.extraReward.map((v) => v.totalRewarderYieldUsd);
      return [
        data.totalDexYield + totalRewarderYieldUsdArr.reduce((partialSum, a) => partialSum + a, 0),
        dataApr.totalDexYield + totalRewarderYieldUsdAprArr.reduce((partialSum, a) => partialSum + a, 0),
      ];
    }
    return [data.totalDexYield, dataApr.totalDexYield];
  }, [view, summary]);
  const handleSwitchClick = () => {
    setView(view === 'apr' ? 'roi' : 'apr');
  };
  return (
    <SummaryContainer>
      <SummaryContentGrid container flexDirection="column">
        <SummaryHeader container justifyContent="space-between" item gap={0.5}>
          <Text typography="body2" color="secondary" bold="600">Return (USD)</Text>
          <Text typography="body2" color="secondary" bold="600">{view === 'apr' ? 'AROI*' : 'ROI*'}</Text>
        </SummaryHeader>
        <SummaryBreakDownGrid item container gap={2.5}>
          <PerformanceSummaryItem
            title={(
              <>
                Yield Farming
                <InfoDescriptionPopover
                  description="Yield Farming"
                />
                {/* <TokenImage width={18} height={18} token={info.rewardToken} /> */}
              </>
)}
            value={totalYieldValue}
            aprValue={totalYieldValueApr}
            view={view}
            principal={principal}
          />
          <PerformanceSummaryItem
            title="Trading Fees"
            value={data.totalTradingfee}
            aprValue={dataApr.totalTradingfee}
            view={view}
            principal={principal}
          />
          <PerformanceSummaryItem
            active={lossView === 'priceImpact'}
            title="Price Effect"
            value={data.priceImpact}
            aprValue={dataApr.priceImpact}
            view={view}
            principal={principal}
          />
          <PerformanceSummaryItem
            active={lossView === 'impermanentLoss'}
            title="Impermanent Loss"
            value={data.impermanentLoss}
            aprValue={dataApr.impermanentLoss}
            view={view}
            principal={principal}
          />
        </SummaryBreakDownGrid>
        <SummaryTotalGrid item container gap={2.5}>
          <PerformanceSummaryItem
            title="Total Yield"
            value={data.totalDexYield + data.totalTradingfee}
            aprValue={dataApr.totalDexYield + dataApr.totalTradingfee}
            view={view}
            principal={principal}
          />
          <PerformanceSummaryItem
            active={lossView === 'priceImpact'}
            title="Return (incl. Price Effect)"
            value={data.totalDexYield + data.totalTradingfee + data.priceImpact}
            aprValue={dataApr.totalDexYield + dataApr.totalTradingfee + dataApr.priceImpact}
            view={view}
            principal={principal}
          />
          <PerformanceSummaryItem
            active={lossView === 'impermanentLoss'}
            title="Return (incl. Imp. Loss)"
            value={data.totalDexYield + data.totalTradingfee + data.impermanentLoss}
            aprValue={dataApr.totalDexYield + dataApr.totalTradingfee + dataApr.impermanentLoss}
            view={view}
            principal={principal}
          />
        </SummaryTotalGrid>
        <Grid sx={{ mt: 2, mb: 1.5 }} container alignItems="center" justifyContent="center" flexDirection="column" gap={1.25}>
          <Text typography="small" color="secondary">
            {view === 'apr'
            ? '*AROI: Annual Return on Investment'
            : '*ROI: Return on Investment'}
          </Text>
          <Button onClick={handleSwitchClick}>
            <Text sx={{ display: 'flex', alignItems: 'center', gap: 1 }} color="secondary" typography="body2">
              <img height={16} width={16} src={images.arrowRepeat} />
              {' '}
              <u>
                Change to
                {view === 'apr' ? 'ROI' : 'AROI'}
              </u>
            </Text>
          </Button>
        </Grid>
      </SummaryContentGrid>
    </SummaryContainer>
  );
};

const PerformanceSummaryItem = ({
  title,
  value,
  aprValue,
  view,
  principal,
  header = false,
  active = true,
}) => {
  const disableSx = {
    opacity: 0.5,
  };
  const viewValue = (view === 'apr') ? aprValue : value;
  return (
    <Box sx={[!active && disableSx]}>
      <Text color={active ? 'primary.main' : 'text.secondary'} bold typography={header ? 'body2' : 'small'}>
        <InlineMiddleFlex gap={0}>
          {title}
        </InlineMiddleFlex>
      </Text>
      <InlineMiddleFlex sx={{ mt: 0.75, width: '100%' }} justifyContent="space-between" alignItem="baseline">
        <Text color={active ? null : 'text.secondary'} bold="600" typography={header ? 'h4' : 'body2'}>{formatPrice(value * principal)}</Text>
        <TrendText bold="600" typography={header ? 'h4' : 'body1'} value={viewValue}>{formatPercent(viewValue)}</TrendText>
      </InlineMiddleFlex>
    </Box>
);
};

const GraphPaper = styled(Paper)();

const PerformanceGraphPaper:React.FC<{data: ChartResult, principal: number}> = ({
  data,
  principal,
}) => {
  const [view, setView] = useState('roi');
  // const [lossView, setLossView] = useState<"priceImpact" | "impermanentLoss">("priceImpact");
  const { lossView, setLossView } = useContext(GraphDetailContext);
  const [showPrincipal, setShowPrincipal] = useState(true);
  const [showBreakDown, setShowBreakDown] = useState(true);
  const [fields, setFields] = useState<{[key: string]: {name: string, alias: string, color: string, active: boolean}}>({
    totalTradingfee: {
 name: 'totalTradingfee', alias: 'Trading Fees', color: '#BAE6FF', active: true,
},
    totalDexYield: {
 name: 'totalDexYield', alias: 'Yield Farming', color: '#00D1B2', active: true,
},
    priceImpact: {
 name: 'priceImpact', alias: 'Price Effect', color: '#E57EC3', active: true,
},
    impermanentLoss: {
 name: 'impermanentLoss', alias: 'Impermanent Loss', color: '#E57EC3', active: true,
},
  });
  const graphHeight = 500;

  const graphFields = useMemo(() => {
    if (lossView === 'impermanentLoss') {
      return _.filter(fields, (v) => v.name !== 'priceImpact');
    }
    if (lossView === 'priceImpact') {
      return _.filter(fields, (v) => v.name !== 'impermanentLoss');
    }
  }, [fields, lossView]);

  const keyFields = useMemo(() => {
    const result = _(graphFields).filter({ active: true }).map((v) => v.name).value();
    return result;
  }, [graphFields, lossView]);
  const keyFieldsForToken = ['tokenPrice', 'quoteTokenPrice'];
  const tokenData = useFormattedCoinDataForChartLib({ data, keyFields: keyFieldsForToken, view: 'roi' });
  const graphData = useFormattedGraphDataForChartLib({
    data,
    principal,
    showPrincipal,
    keyFields,
    view: 'roi',
  });
  const graphDataApr = useFormattedGraphDataForChartLib({
    data,
    principal,
    showPrincipal,
    keyFields,
    view: 'apr',
  });
  const handleClick = (e, v) => {
    const newValue:any = {
      ...fields,
      [v.name]: {
        ...v,
        active: !v.active,
      },
    };
    let filterNewValue;
    if (lossView === 'impermanentLoss') {
      filterNewValue = _.filter(newValue, (v) => v.name !== 'priceImpact');
    }
    if (lossView === 'priceImpact') {
      filterNewValue = _.filter(newValue, (v) => v.name !== 'impermanentLoss');
    }
    if (_.some(_.values(filterNewValue), { active: true })) {
      setFields({
        ...fields,
        [v.name]: {
          ...v,
          active: !v.active,
        },
      });
    }
  };

  return (
    <Box width="100%">
      <Grid container sx={{ px: 3, py: 1.5, width: '100%' }} gap={2}>
        <InlineMiddleFlex flex="1 1 100%" component="div" gap={2} sx={{ width: 'min-content', flexWrap: 'wrap' }}>
          {_.map(graphFields, (v) => (
            <GraphLegendItem color={v.color} active={v.active} component="li" key={v.name} onClick={(e) => handleClick(e, v)}>
              <CircleIcon />
              {' '}
              <Text component="span">{v.alias}</Text>
            </GraphLegendItem>
          ))}
          <Divider flexItem orientation="vertical" />
          <InlineMiddleFlex flex="0 1 auto" component="div" gap={2} sx={{ width: 'min-content' }}>
            <GraphLegendItem sx={{ cursor: 'initial' }} color="primary.main" active component="li">
              <CircleIcon />
              {' '}
              <Text component="span">{lossView === 'priceImpact' ? 'Return incl. Price Effect' : 'Return incl. Impermanent Loss'}</Text>
            </GraphLegendItem>
          </InlineMiddleFlex>
        </InlineMiddleFlex>
        <Grid item sx={{ ml: 'auto' }}>
          <SwitchButton active={!showBreakDown} gap={0} onClick={() => setShowBreakDown(!showBreakDown)}>
            <Switch checked={!showBreakDown} />
            <Text typography="body2" ellipsis>Show total only</Text>
          </SwitchButton>
        </Grid>
      </Grid>

      <Box sx={{ mx: 1 }} position="relative" height={graphHeight}>
        <Box position="absolute" width="100%">
          <TimeMachineTimeTunnelTotalEvGraph
            lossView={lossView}
            fields={fields}
            height={graphHeight}
            data={graphData}
            dataApr={graphDataApr}
            tokenData={tokenData}
            showBreakDown={showBreakDown}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TimeMachineTimeTunnelDetail;
