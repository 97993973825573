import { SimulationListApiBody } from '@iddy/time-machine';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { useRouter } from 'hooks/useRouter';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTableData } from '../hooks/useTableData';
import TimeMachineFilterForm from './TimeMachineFilterForm';
import TimeMachineTable from './TimeMachineTable';

const TimeMachineContentController:React.FC<{}> = () => {
  const ref = useRef(null);
  const route = useRouter<{type: string, chain: string}>();
  const { params } = route.match;
  const { search } = useLocation();
  const dex = new URLSearchParams(search).get('dex');
const defaultDex = dex ? [dex] : [];
  const methods = useForm({
    defaultValues: {
      query: '',
      assetsType: [],
      dex_in: defaultDex,
      metric: [],
      totalValueLock_between_in: [],
      pagination: {
        page: 0,
        pageSize: 25,
      },
      sort: { id: 'liquidity_current', direction: 'desc' },
    },
  });
  const { data, isReady, fetch } = useTableData();

  const watchFilters = methods.watch(['assetsType', 'dex_in', 'metric', 'totalValueLock_between_in']);
  const sort = methods.watch('sort');
  const pagination = methods.watch('pagination');
  const query = methods.watch('query');
  const routeFilter:SimulationListApiBody = useMemo(() => {
    const result:SimulationListApiBody = {
      where: {},
    };
    if (params.type == 'latest') {
      result.where.newPools = true;
    }
    if (params.chain) {
      result.where.chain = params.chain;
    }
    return result;
  }, [params]);

  const onSubmit = (values) => {
    const params = _.cloneDeep(routeFilter);
    if (values.assetsType && values.assetsType.length > 0) {
      _.set(params, 'where.assetsType', values.assetsType);
    }
    if (values.dex_in && values.dex_in.length > 0) {
      _.set(params, 'where.dex_in', values.dex_in);
    }
    if (values.metric && values.metric.length > 0) {
      _.forEach(values.metric, (v) => {
        _.set(params, `where.${v}`, true);
      });
    }
    if (values.totalValueLock_between_in && values.totalValueLock_between_in.length > 0) {
      _.set(params, 'where.totalValueLock_between_in', values.totalValueLock_between_in);
    }
    if (values.sort) {
      params.sort = { [values.sort.id]: values.sort.direction };
    }
    if (values.pagination) {
      _.set(params, 'take', values.pagination.pageSize);
      _.set(params, 'skip', values.pagination.page * values.pagination.pageSize);
    }
    if (values.query) {
      _.set(params, 'query', values.query);
    }
    fetch(params);
  };

  useEffect(() => {
    onSubmit(methods.getValues());
  }, [routeFilter, ...watchFilters]);

  useEffect(() => {
    onSubmit(methods.getValues());
  }, [sort, pagination, query]);

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TimeMachineFilterForm my={4} />
          <div ref={ref}>
            <TimeMachineTable scrollRef={ref} data={data?.data} total={data?.count} isReady={isReady} />
          </div>
        </form>
      </FormProvider>
    </Box>
);
};

export default TimeMachineContentController;
