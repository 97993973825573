import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import images from 'assets/images';
import Footer from 'components/Footer';
import TokenImage from 'components/Image/TokenImage';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import MainContainer from 'components/MainContainer';
import SEO from 'components/SEO';
import { Text } from 'components/Text';
import { WelcomeBannerComponent } from 'components/WelcomeBannerComponent';
import { useRouter } from 'hooks/useRouter';
import { getChainBySymbol } from 'lib/timeMachineHelpers';
import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import TimeMachineContentController from './components/TimeMachineContentController';
import { TimeMachinePageContextProvider } from './context';

const Content = styled(Container)(
  sx({
    position: 'relative',
    mt: 7.5,
    width: '100%',
  }),
);

const HeaderContainer = styled(Box)(
  sx({
    minHeight: 110,
    flexDirection: 'column',
    display: 'flex',
    '& > div': {
      mt: 2,
    },
  }),
);

const TimeMachineBackground = styled(Box)(
  sx({
    zIndex: -1,
    display: {
      xs: 'none',
      lg: 'block',
    },
    m: 0,
    p: 0,
    top: -85,
    right: 0,
    position: 'absolute',
    height: 270,
    overflow: 'hidden',
    '& > img': {
      height: 270,
    },
  }),
);

const TimeMachine: React.FC = () => {
  const route = useRouter<{chain: string}>();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route.pathname]);
  const chain = useMemo(() => {
    const { params } = route.match;
    if (params.chain) {
      return getChainBySymbol(params.chain);
    }
    return null;
  }, [route.match.params]);
  // [SEO] Remainder  server side SEO title description in server/index.js after update client side
  const title = (chain) ? `${chain.name} Liquidity pool analytics & yield farming rewards tracker` : 'Liquidity pool analytics & yield farming rewards tracker';
  const description = 'Pick your winning liquidity pools with ease. Powered with real historical performance and yield farming rewards marked to USD, you can ditch misleading APYs and focus on screening liquidity pools that make real return with our metrics.';

  if (chain && chain.launch === false) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <SEO
        title={title}
        description={description}
      />
      <TimeMachinePageContextProvider value={{ chain: chain?.symbol }}>
        <MainContainer>
          <WelcomeBannerComponent />
          <Content>
            <HeaderContainer>
              {chain
              ? (
                <InlineMiddleFlex>
                  <TokenImage width={48} height={48} token={chain.symbol} type="chain" />
                  <Text typography="h3" bold>{chain.name}</Text>
                </InlineMiddleFlex>
              )
              : (
                <>
                  <InlineMiddleFlex>
                    <Text typography="h3" bold>LP Time Machine</Text>
                  </InlineMiddleFlex>

                  <Text align="justify" sx={{ maxWidth: 764 }}>
                    Pick your winning liquidity pools with ease. Powered with real historical performance and yield farming rewards marked to USD, you can ditch misleading APYs and focus on screening liquidity pools that make real return with our metrics.
                    {' '}
                    <a href="https://docs.singlefinance.io/home/lp-time-machine/introduction ">Learn More</a>
                  </Text>
                </>
)}
            </HeaderContainer>
            <TimeMachineContentController />
            <TimeMachineBackground>
              <img src={images.timeMachineBg} />
            </TimeMachineBackground>
          </Content>
        </MainContainer>
      </TimeMachinePageContextProvider>
    </>
  );
};

export default TimeMachine;
