import {
 alpha, Grid, Button, Theme, Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import MainContainer from 'components/MainContainer';
import { Text, TrendText } from 'components/Text';
import { useRouter } from 'hooks/useRouter';
import React, {
 createContext, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { RadiusButton } from 'components/RadiusButton';
import { FarmSummaryData } from '@iddy/time-machine';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { borderColor } from '@mui/lab/node_modules/@mui/system';
import { formatPercent } from 'utils/formatNumber';
import { TrendDownIcon, TrendUpIcon } from 'components/Svg';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import InfoDescriptionPopover from 'components/InfoDescriptionPopover';
import { useDetailPageContext } from '../context';
import { useFarmSummary } from '../hooks/useFarmSummary';

const HoverContext = createContext<{
  hover:string,
  setHover:(e, value) => void,
}>(null);

function createData(name, calories, fat, carbs, protein) {
  return {
 name, calories, fat, carbs, protein,
};
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const StyledTable = styled(Table)(
  sx({
    typography: 'body2',
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: 'text.secondary',
      fontWeight: 500,
    },
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      p: 3,
      py: 1,
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      borderBottom: '1px solid white',
      borderBottomColor: 'greyColor.light',
      py: 1,
    },
    '& .MuiTableRow-root .MuiTableCell-root:first-child': {
      borderRight: '1px solid white',
      borderRightColor: 'greyColor.light',
      // position: 'sticky',
      // left: 0,
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      fontWeight: 600,
    },
  }),
);

const TimeMachineDetailReturnRateTable:React.FC<{
  data: FarmSummaryData[],
  [key: string]: any,
}> = ({
  data,
  ...otherProps
}) => {
  const [hover, setHover] = useState(null);
  const { info } = useDetailPageContext();
  const summary = useFarmSummary();
  useEffect(() => {
    if (info) {
      summary.fetch({ poolId: info.poolId });
    }
  }, [info]);
  const handleSetHover = (e, value) => {
    setHover(value);
  };
  const contextData = {
    hover,
    setHover: handleSetHover,
  };

  return (
    <HoverContext.Provider value={contextData}>
      <TableContainer component={Paper} {...otherProps}>
        <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 200 }}>Return Rate</TableCell>
              <TableCell>Rank 24H  (90D)</TableCell>
              <TableCell>24H</TableCell>
              <TableCell>7D</TableCell>
              <TableCell>30D</TableCell>
              <TableCell>90D</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ReturnRateTableRow data={data} relativeWith={['totalReturn', 'returnExcludePrice', 'returnIncludeIl']} label="Yield Farming" field="dexYield" />
            <ReturnRateTableRow data={data} relativeWith={['totalReturn', 'returnExcludePrice', 'returnIncludeIl']} label="Trading Fees" field="tradingFee" />
            <ReturnRateTableRow data={data} relativeWith={['totalReturn']} label="Price Effect" field="priceImpact" />
            <ReturnRateTableRow data={data} relativeWith={['returnIncludeIl']} label="Impermanent Loss" field="impermanentLoss" sx={{ borderBottom: '1px solid white', borderBottomColor: 'greyColor.light' }} />
            <ReturnRateTotalTableRow data={data} description="Yield Farming + Trading Fees" label="Total Yield" field="returnExcludePrice" />
            <ReturnRateTotalTableRow data={data} description="Total Yield + Price Effect" label="Return (incl. Price Effect)" field="totalReturn" />
            <ReturnRateTotalTableRow data={data} description="Total Yield + Impermanent Loss" label="Return (incl. Impermanent Loss)" field="returnIncludeIl" />
          </TableBody>
        </StyledTable>
      </TableContainer>
    </HoverContext.Provider>
  );
};

const ReturnRateTableRow:React.FC<{
  data: FarmSummaryData[],
  label: string | JSX.Element,
  field: string,
  labelBold?: boolean,
  relativeWith?: string[],
  [key: string]: any
}> = ({
  data,
  label,
  field,
  labelBold,
  sx,
  relativeWith,
  ...otherProps
}) => {
  const { hover, setHover } = useContext(HoverContext);
  const rankField = `${field}Rank`;
  const aprField = field;
  const d1 = _.find(data, { period: '24h' });
  const d7 = _.find(data, { period: '7d' });
  const d30 = _.find(data, { period: '30d' });
  const d90 = _.find(data, { period: '90d' });
  const rankD1 = _.get(d1, rankField);
  const rankD90 = _.get(d90, rankField);
  const rankTrend = (rankD1 && rankD90) ? rankD90 - rankD1 : 0;
  const aprD1 = _.get(d1, aprField);
  const aprD7 = _.get(d7, aprField);
  const aprD30 = _.get(d30, aprField);
  const aprD90 = _.get(d90, aprField);
  const active = hover === field;
  const activeRelative = _.indexOf(relativeWith, hover) !== -1;

  const display = (v) => (v ? <TrendText value={v}>{formatPercent(v)}</TrendText> : <Text color="secondary">-</Text>);
  return (
    <TableRow
      onMouseEnter={(e) => setHover(e, field)}
      onMouseLeave={(e) => setHover(e, null)}
      {...otherProps}
      sx={[
      active && {
        '& > .MuiTableCell-root': {
          backgroundColor: (theme) => alpha('#EAE4FC', 0.66),
        },
      },
      activeRelative && {
        '& > .MuiTableCell-root': {
          backgroundColor: (theme) => alpha('#EAE4FC', 0.33),
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <TableCell sx={{ typography: 'body2' }}>{label}</TableCell>
      <TableCell>
        <InlineMiddleFlex gap={0}>
          {rankTrend > 0 && <TrendUpIcon />}
          {rankTrend < 0 && <TrendDownIcon />}
          {rankTrend == 0 && <Text sx={{ width: 20, textAlign: 'center' }} component="span" color="secondary">-</Text>}
          <TrendText value={rankTrend}>{rankD1 || <Text component="span" color="secondary">-</Text>}</TrendText>
          <Text sx={{ ml: 1 }}>
            (
            {rankD90 || <Text component="span" color="secondary">-</Text>}
            )
          </Text>
        </InlineMiddleFlex>
      </TableCell>
      <TableCell>{display(aprD1)}</TableCell>
      <TableCell>{display(aprD7)}</TableCell>
      <TableCell>{display(aprD30)}</TableCell>
      <TableCell>{display(aprD90)}</TableCell>
    </TableRow>
);
};

const ReturnRateTotalTableRow:React.FC<{
  data: FarmSummaryData[],
  label: string,
  field: string,
  labelBold?: boolean,
  description?: string,
  [key: string]: any
}> = ({
  label,
  description,
  ...otherProps
}) => (
  <ReturnRateTableRow
    label={(
      <>
        <Text bold typography="body1">{label}</Text>
        <Text typography="small" color="text.secondary">{description}</Text>
      </>
)}
    {...otherProps}
  />
);

export default TimeMachineDetailReturnRateTable;
