import {
 Button, Collapse, Divider, Grid, Paper, styled, Tab, TableCell, Theme,
} from '@mui/material';
import { Box, experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken, getImageUrlFromTokenDto } from 'components/Image/helpers';
import { SimpleTable, SimpleFarmActions } from 'components/SimpleTable';
import {
 ExpandIcon, LightBulbIcon, ZoomInIcon, ZoomOutIcon,
} from 'components/Svg';
import { Text } from 'components/Text';
import _ from 'lodash';
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import {
 formatPercent, formatPrice, formatPriceCurrency, formatNumber,
} from 'utils/formatNumber';
import {
 useForm, FormProvider, useFormContext, useController,
} from 'react-hook-form';
import InfoPopover from 'components/InfoPopover';
import { useHistory } from 'react-router-dom';
import { getDetailFullLink } from 'utils/linkHelpers';
import InfoDescriptionPopover from 'components/InfoDescriptionPopover';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { getDexBySymbol } from 'lib/timeMachineHelpers';
import dayjs from 'dayjs';
import TokenImage from 'components/Image/TokenImage';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TokenPairImage from 'components/Image/TokenPairImage';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FarmTvlPopoverContent from './cell/FarmTvlPopoverContent';
import RealReturnTotal from './cell/RealReturnTotal';
import RealReturnRateDetail from './cell/RealReturnRateDetail';
import RealReturnBreakDown from './cell/RealReturnBreakDown';
import { useTableData } from '../hooks/useTableData';
import TimeMachineSearchInput from './TimeMachineSearchInput';

const StyledRealReturn = styled(Box)(
  sx<Theme>({
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    color: 'text.primary',
    '& svg': {
      fill: (theme) => theme.palette.text.secondary,
    },
    '&.active': {
      color: 'primary.main',
    },
    '&.active svg': {
      fill: (theme) => theme.palette.primary.main,
    },
  }),
);

const ViewTabList = styled(TabList)(
  sx({
    bgcolor: 'background.paper',
    m: 0,
    pt: 1,
    px: 2,
    '& .MuiTabs-flexContainer > *': {
      flex: '0 1 max-content',
      p: 1,
    },
  }),
);

const StyledExpandIcon = styled(ExpandIcon)(
  sx({
    ml: 0.5,
  }),
);

const TableExpandButton = styled(Button)(
  sx<Theme>({
    color: 'text.secondary',
    textDecoration: 'underline',
    typography: 'body2',
    fontWeight: 600,
    '& svg': {
      fill: (theme) => theme.palette.text.secondary,
    },
  }),
);

const TimeMachineTable:React.FC<{
  data: any[],
  scrollRef: any,
  total: number,
  isReady: boolean,
}> = ({
  total,
  data,
  scrollRef,
  isReady,
  ...props
}) => {
  // use Boolean object make sure same value can be rerender the component when set same boolean value
  const [expandAll, setExpandAll] = useState(new Boolean(true));
  const { watch, setValue } = useFormContext();
  const history = useHistory();

  const tableRef = useRef<HTMLElement>();

  const pagination = watch('pagination');
  const sort = watch('sort');

  const handleClick = (item) => {
    const { pool } = item;
    window.open(getDetailFullLink(pool.chain, pool.dex, pool.lpAddress, pool.lpSymbol), '_blank');
    // history.push(getDetailFullLink(farm.chain, farm.exchange, farm.id));
  };

  const handleBreakDownAll = () => {
    setExpandAll(new Boolean(true));
  };

  const handleCollapseAll = () => {
    setExpandAll(new Boolean(false));
  };

  const [view, setView] = React.useState<('priceImpact' | 'impermanentLoss')>('priceImpact');

  const handleChange = (event: React.SyntheticEvent, newValue: 'priceImpact' | 'impermanentLoss') => {
    setView(newValue);
  };

  const columns = useMemo(() => ([
    {
      id: 'name',
      sort: 'rank_total_return_1day',
      label: <><InfoDescriptionPopover description="Rank">#</InfoDescriptionPopover></>,
      minWidth: 20,
      sortDirections: ['asc'],
      render: (v, item) => (
        <Box textAlign="center">
          <TokenImage width={18} solid type="chain" token={_.get(item, 'pool.chain')} />
          <Text typography="small">{_.get(item, 'rankRRR')}</Text>
        </Box>
),
    },
    {
      id: 'pair',
      label: 'Pair / Dex',
      minWidth: 170,
      render: (v, item) => (
        <Box sx={{ '& > *': { mb: 1 } }}>
          {/* <Text bold={'600'}>{_.get(item, 'token.symbol')} - {_.get(item, 'quoteToken.symbol')}</Text> */}
          <InlineMiddleFlex gap={0.5}>
            <TokenPairImage
              primarySrc={getImageUrlFromTokenDto(_.get(item, 'pool.tokenEntity'))}
              secondarySrc={getImageUrlFromTokenDto(_.get(item, 'pool.quoteTokenEntity'))}
              width={28}
              height={18}
            />
            <Text bold="600">{_.get(item, 'pool.lpSymbol')}</Text>
          </InlineMiddleFlex>
          <InlineMiddleFlex gap={0.5}>
            <TokenImage width={18} height={18} token={_.get(item, 'pool.dex')} type="dex" />
            <Text typography="small" color="secondary">{getDexBySymbol(_.get(item, 'pool.dex'))?.name}</Text>
          </InlineMiddleFlex>
        </Box>
),
    },

    {
      id: 'total',
      label: <><InfoDescriptionPopover description="RRR">Total ROI</InfoDescriptionPopover></>,
      minWidth: 120,
      render: (v, item, { expandableRowOpen, toggleExpandableRowOpen }) => (
        <StyledRealReturn>
          <Text bold="600" component="span" typography="body2">
            <TabPanel sx={{ p: 0 }} value="priceImpact">
              Return incl. Price Effect
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="impermanentLoss">
              Return incl. Imp. Loss
            </TabPanel>
          </Text>
        </StyledRealReturn>
),
      expandedRender: (v, item, open) => (
        <Collapse in={open}>
          <RealReturnRateDetail
            title="Breakdown"
            tradingFee="Trading Fees"
            dexYield="Yield Farming"
            priceImpact="Price Effect"
            impermanentLoss="Impermanent Loss"
          />
        </Collapse>
        ),
},
    {
      id: 'dataKey.24h',
      sort: (view === 'priceImpact') ? 'total_return_1day' : 'return_include_il_1day',
      sortDirections: ['desc', 'asc'],
      label: '24H',
      minWidth: 50,
      render: (v) => <RealReturnTotal value={v} />,
      expandedRender: (v, item, open) => (
        <Collapse in={open}>
          <RealReturnBreakDown value={v} />
        </Collapse>
      ),
},
    {
      id: 'dataKey.7d',
      sort: (view === 'priceImpact') ? 'total_return_7day' : 'return_include_il_7day',
      sortDirections: ['desc', 'asc'],
      label: '7D',
      minWidth: 50,
      render: (v) => <RealReturnTotal value={v} />,
      expandedRender: (v, item, open) => (
        <Collapse in={open}>
          <RealReturnBreakDown value={v} />
        </Collapse>
      ),
},
    {
      id: 'dataKey.30d',
      sort: (view === 'priceImpact') ? 'total_return_30day' : 'return_include_il_30day',
      sortDirections: ['desc', 'asc'],
      label: '30D',
      minWidth: 50,
      render: (v) => <RealReturnTotal value={v} />,
      expandedRender: (v, item, open) => (
        <Collapse in={open}>
          <RealReturnBreakDown value={v} />
        </Collapse>
      ),
},
    {
      id: 'dataKey.90d',
      sort: (view === 'priceImpact') ? 'total_return_90day' : 'return_include_il_90day',
      sortDirections: ['desc', 'asc'],
      label: '90D',
      minWidth: 50,
      render: (v) => <RealReturnTotal value={v} />,
      expandedRender: (v, item, open) => (
        <Collapse in={open}>
          <RealReturnBreakDown value={v} />
        </Collapse>
      ),
},
    {
      id: 'totalValueLocked',
      sort: 'liquidity_current',
      sortDirections: ['desc', 'asc'],
      label: <>
        Liquidity
        <br />
        (USD)
             </>,
      minWidth: 105,
      render: (v, item) => (
        <Box>
          {formatPriceCurrency(v)}
          <InfoPopover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <FarmTvlPopoverContent item={item} />
          </InfoPopover>
        </Box>
),
    },
    {
      id: 'totalVolume',
      sort: 'volume_current',
      sortDirections: ['desc', 'asc'],
      label: <>
        24H Volume
        <br />
        (USD)
             </>,
      minWidth: 120,
      render: (v) => (v ? formatPrice(v) : <Text color="secondary" bold>-</Text>),
      // formatPrice,
    },
    {
      id: 'hhr',
      sort: 'hhr',
      sortDirections: ['desc', 'asc'],
      label: <><InfoDescriptionPopover description="HHR">30D HHR</InfoDescriptionPopover></>,
      minWidth: 70,
      render: (v) => (v ? formatNumber(v) : <Text color="secondary" bold>-</Text>),
      // formatNumber(v),
    },
    {
      id: 'growthRate',
      sort: 'growth_rate',
      sortDirections: ['desc', 'asc'],
      label: <><InfoDescriptionPopover description="Growth Rate">Fees Growth Rate</InfoDescriptionPopover></>,
      minWidth: 120,
      render: (v) => (v ? `${formatNumber(v)}x` : <Text color="secondary" bold>-</Text>),
    },
    {
      id: 'more',
      label: '',
      minWidth: 50,
      render: (v, item, { expandableRowOpen, toggleExpandableRowOpen }) => (
        <InlineMiddleFlex sx={{ cursor: 'pointer', userSelect: 'none' }} gap={0.5} onClick={(e) => { e.stopPropagation(); toggleExpandableRowOpen(); }}>
          <Text bold="600" component="span" color="secondary">
            Details
          </Text>
          <KeyboardArrowUpIcon sx={[{ color: 'text.secondary' }, !expandableRowOpen && { transform: 'rotate(180deg)' }]} />
        </InlineMiddleFlex>
),
    },
  ]), [view]);

  const handleFormChange = (form: SimpleFarmActions) => {
    const sort = form.sort !== null ? form.sort : { id: 'liquidity_current', direction: 'desc' };
    setValue('sort', sort);
    setValue('pagination', form.pagination);
    if (tableRef && tableRef.current) {
      tableRef.current.scrollIntoView();
    }
  };

  const refreshDate = useMemo(() => _.max(_.map(data, 'query_ts')), [data]);

  return (
    <Box ref={tableRef} {...props}>
      <TabContext value={view}>
        <TimeMachineSearchInput sx={{ my: 2 }} />
        <Grid container sx={{ mb: 3 }} justifyContent="space-between" gap={1} alignItems="center">
          <InlineMiddleFlex gap={0.5} flex="0 0 max-content">
            <LightBulbIcon height={16} width={16} />
            <Text typography="small">All returns and values are in USD base. Data are compiled hourly.</Text>
          </InlineMiddleFlex>
          <InlineMiddleFlex flex="0 0 max-content">
            <TableExpandButton startIcon={<ZoomOutIcon />} onClick={handleBreakDownAll}>Breakdown All</TableExpandButton>
            <Divider flexItem orientation="vertical" variant="middle" />
            <TableExpandButton startIcon={<ZoomInIcon />} onClick={handleCollapseAll}>Collapse All</TableExpandButton>
          </InlineMiddleFlex>
        </Grid>
        <ViewTabList onChange={handleChange}>
          <Tab label="Return incl. Price Effect" value="priceImpact" />
          <Tab label="Return incl. Impermanent Loss" value="impermanentLoss" />
        </ViewTabList>
        <SimpleTable
          scroll={{ y: '80vh' }}
          component={Paper}
          onRowClick={handleClick}
          loading={!isReady}
          total={total}
          pagination={pagination}
          sort={sort}
          onChange={handleFormChange}
          columns={columns}
          data={data}
          expandedRowRender={(row, { columns, expandableRowOpen }) => <ExpandedTableRow row={row} columns={columns} open={expandableRowOpen} />}
          expandAll={expandAll}
        />
      </TabContext>
    </Box>
);
};

const ExpandedTableRow = ({ row, columns, open }) => (
  <>
    {_.map(columns, (column, idx) => {
      const handleClick = typeof column.onClick === 'function' ? () => column.onClick(row) : null;
      return (
        <TableCell onClick={handleClick} sx={[column.onClick && { cursor: 'pointer' }, { py: 0, verticalAlign: 'top' }]} key={column.id}>
          {column.expandedRender && column.expandedRender(_.get(row, column.id), row, open)}
        </TableCell>
);
    })}
  </>
);

export default TimeMachineTable;
