import React, { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { ChartResult, FarmSimulateDto } from '@iddy/time-machine';
import _ from 'lodash';
import { useDetailPageContext } from '../context';

export const useFormattedCoinDataForChartLib = (param: {
  data: ChartResult,
  keyFields: any[],
  view?: string
}) => useMemo(() => {
    const {
 data, view, keyFields,
} = param;
    const cur:FarmSimulateDto[] = data[view];
    const result = [];
    const keyList = keyFields;
    _.forEach(cur, (item) => {
      const obj = {};
      const time = item.query_ts;
      _.forEach(keyList, (key, idx) => {
        const firstValue = cur[0][key];
        const value = _.get(item, key);
        obj[key] = {
          valueToken: value,
          percentageToken: (value - firstValue) / firstValue,
        };
      });
      _.forEach(keyList, (key) => {
        const cur = obj[key];
        result.push({
          time,
          type: key,
          ...cur,
        });
      });
    });
    return result;
  }, [param]);
