import { FarmDataDto } from '@iddy/time-machine';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { TrendText, Text } from 'components/Text';
import _ from 'lodash';
import React from 'react';
import { formatPercent } from 'utils/formatNumber';
import RealReturnRateDetail from './RealReturnRateDetail';

const StyledEmptyIcon = styled(ErrorOutlineIcon)(
  sx({
    fill: (theme) => theme.palette.error.main,
  }),
);

const RealReturnBreakDown: React.FC<{value:FarmDataDto}> = ({
  value: v,
}) => (
  <RealReturnRateDetail
    tradingFee={
      v
      ? (
        <TrendText value={_.get(v, 'tradingFee')}>
          {formatPercent(_.get(v, 'tradingFee'))}
        </TrendText>
      )
      : <Text sx={{ width: 20, textAlign: 'center' }} bold color="text.secondary">-</Text>
    }
    dexYield={
      v
      ? (
        <TrendText value={_.get(v, 'dexYield')}>
          {formatPercent(_.get(v, 'dexYield'))}
        </TrendText>
      )
      : <Text sx={{ width: 20, textAlign: 'center' }} bold color="text.secondary">-</Text>
    }
    priceImpact={
      v
      ? (
        <TrendText value={_.get(v, 'priceImpact')}>
          {formatPercent(_.get(v, 'priceImpact'))}
        </TrendText>
      )
      : <Text sx={{ width: 20, textAlign: 'center' }} bold color="text.secondary">-</Text>
    }
    impermanentLoss={
      v
      ? (
        <TrendText value={_.get(v, 'impermanentLoss')}>
          {formatPercent(_.get(v, 'impermanentLoss'))}
        </TrendText>
      )
      : <Text sx={{ width: 20, textAlign: 'center' }} bold color="text.secondary">-</Text>
    }
  />
);

export default RealReturnBreakDown;
