import { ChartResult, FarmSimulateDto } from '@iddy/time-machine';
import dayjs from 'dayjs';
import { useSimulatePoolApi } from 'lib/api';
import { useState } from 'react';
import _ from 'lodash';

export const useFarmPerformanceGraphData = () => {
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<ChartResult>();
  const [summary, setSummary] = useState<{roi: FarmSimulateDto, apr: FarmSimulateDto}>();
  const api = useSimulatePoolApi();
  const fetch = async ({
    poolId,
    principal,
    fromDate,
    toDate,
  }) => {
    setIsReady(false);
    const period:number = dayjs(toDate).diff(dayjs(fromDate), 'day');
    const result = await api.simulate(poolId, principal, String(period), toDate, 'historical');
    setData(result.data.result.chart);
    setSummary({
      roi: _.maxBy(result.data.result.chart.roi, 'query_ts'),
      apr: _.maxBy(result.data.result.chart.apr, 'query_ts'),
    });
    setIsReady(true);
  };

  return {
    isReady,
    data,
    summary,
    fetch,
  };
};
