import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import MainContainer from 'components/MainContainer';
import { Text } from 'components/Text';
import { useRouter } from 'hooks/useRouter';
import React from 'react';
import _ from 'lodash';
import { Grid, Button, Theme } from '@mui/material';
import { RadiusButton } from 'components/RadiusButton';

const ComingSoonContainer = styled(Box)(
  sx<Theme>({
    flex: 0,
    gap: 3,
    p: 2.5,

    // display: 'flex',
    // wait api ok display this if the pair is support this farm
    display: 'none',

    alignItems: 'center',
    position: "relative",
    minHeight: 98,
    minWidth: 475,
    justifyContent: 'space-between',
    "&::before":{
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%", 
      height: "100%",  
      opacity: 0.16,
      zIndex: -1,
      background: (theme) => theme.palette.primary.mainGradient,
    }
  }),
);

const TimeMachineDetailHeader:React.FC<{}> = (props) => {
  const router = useRouter();
  const handleBackButton = () => {
    router.history.goBack();
  };
  return (
    <Grid container justifyContent={'space-between'} {...props}>
      <Grid item sx={{mt: 1}}>
        <Button onClick={handleBackButton}>
          <Text typography="body2" bold="500" color="text.primary">
            &nbsp;
          </Text>
        </Button>
      </Grid>
      <ComingSoonContainer>
          <Grid item flex={"1 1 min-content"}>
            <Text color="primary.main" typography='caption' bold>Coming Soon</Text>
            <Text typography='body2'>Trade with pseudo market-neutral strategy</Text>
          </Grid>
          <Grid item flex={"0 0 146px"}>
            <RadiusButton fullWidth variant='contained' color={"greyColor" as any}>
              Single Click
            </RadiusButton>
          </Grid>
      </ComingSoonContainer>
    </Grid>
);
};

export default TimeMachineDetailHeader;
