import React from 'react';
import { SxProps, experimental_sx as sx } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Suggested use new style solution system for font size control
// ref: https://mui.com/system/typography/

export const TextH1 = (prop) => <Typography variant="h1" {...prop}>{prop.children}</Typography>;
export const TextH2 = (prop) => <Typography variant="h2" {...prop}>{prop.children}</Typography>;
export const TextH3 = (prop) => <Typography variant="h3" {...prop}>{prop.children}</Typography>;
export const TextH4 = (prop) => <Typography variant="h4" {...prop}>{prop.children}</Typography>;
export const TextH5 = (prop) => <Typography variant="h5" {...prop}>{prop.children}</Typography>;
export const TextH6 = (prop) => <Typography variant="h6" {...prop}>{prop.children}</Typography>;
export const TextBody1 = (prop) => <Typography variant="body1" {...prop}>{prop.children}</Typography>;
export const TextBody2 = (prop) => <Typography variant="body2" {...prop}>{prop.children}</Typography>;
export const TextSubtitle1 = (prop) => <Typography variant="subtitle1" {...prop}>{prop.children}</Typography>;
export const TextSubtitle2 = (prop) => <Typography variant="subtitle2" {...prop}>{prop.children}</Typography>;

export const withTextGradient = (element) => styled(element)`
background-image: ${({ theme }) => theme.palette.primary.singleClickGradient};
background-color: ${({ theme }) => theme.palette.primary.main};
background-size: 100%;
background-clip: text;
text-fill-color: transparent;
`;

type TextProps = {
  sx?: SxProps,
  component?: any,
  typography?: string,
  bold?: boolean | string,
  ellipsis?: boolean,
  color?: 'secondary' | string,
  textTransform?: "uppercase" | "lowercase" | "capitalize",
  align?: "center" | "left" | "right" | "justify",
  [key: string]: any,
}

export const Text:React.FC<TextProps> = ({
  sx,
  component = 'div',
  typography,
  bold = false,
  ellipsis = false,
  color,
  textTransform,
  children,
  align,
  ...otherProps
}) => (
    <Box 
      component={component}
      sx={[{
        typography,
        color: (color === 'secondary') ? 'text.secondary' : color,
        fontWeight: (bold === true) ? 'bold' : Number.parseInt(String(bold)),
        textTransform: textTransform,
        textAlign: align,
      },
      ellipsis && {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
    >
      {children}
    </Box>
)

export const TrendText:React.FC<TextProps & {value: number}> = ({
  value,
  children,
  ...otherProps
}) => (
  <Text 
    sx={[
      value > 0 && {color: 'success.main'},
      value < 0 && {color: 'error.main'},
    ]} 
    {...otherProps}
  >
    {children}
  </Text>
)