import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { experimental_sx as sx } from '@mui/system';

export const TextToggleButtonGroup = styled(ToggleButtonGroup)(
  sx<Theme>({
    '&.MuiToggleButtonGroup-root': {
      gap: 1.5,
    },
    '& .MuiToggleButtonGroup-grouped': {
      background: 'unset',
      color: 'greyColor.dark',
      fontWeight: 500,
      margin: 0.5,
      border: 0,
      gap: 3,
      "& svg": {
        fill: (theme) => theme.palette.text.secondary,
      },
      '&.Mui-selected': {
        background: 'unset',
        color: 'primary.main',
        fontWeight: 700,
        "& svg": {
          fill: (theme) => theme.palette.primary.main,
        }
      },
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: 1,
      },
      '&:first-of-type': {
        borderRadius: 1,
      },
    },
    '& .MuiButtonBase-root': {
      m:0,
      p:0.5,
    }
  })
)