import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';

export const GraphLegendItem = styled(InlineMiddleFlex)<{
  color: string, active: boolean,
}>( ({color, active}) =>
  sx(
    [
      {
        flexBasis: 'max-content',
        whiteSpace: 'nowrap',
        typography: 'body2',
        fontWeight: 600,
        userSelect: 'none',
        cursor: 'pointer',
        color: 'text.primary',
        "& .circle-icon": {
          bgcolor: color,
        }
      },
      !active && {
        color: 'text.secondary',
        "& .circle-icon": {
          bgcolor: 'text.secondary',
        }
      },
    ]
  )
);