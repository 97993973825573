import { ChartResult, FarmSimulateDto, FarmVolumeAndTVLSummaryDto } from '@iddy/time-machine';
import dayjs from 'dayjs';
import { usePoolApi } from 'lib/api';
import React, { useState } from 'react';
import _ from 'lodash';

export const useFarmLiquidityVolumeSummaryData = () => {
  const api = usePoolApi();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<FarmVolumeAndTVLSummaryDto[]>();

  const fetch = async ({
    poolId,
  }) => {
    setIsReady(false);
    const toDate = dayjs().startOf('d');
    const result = await api.volumeAndTVSummary(poolId, toDate.format('YYYY-MM-DD'));
    setData(result.data.result);
    setIsReady(true);
  };

  return {
      isReady,
      data,
      fetch,
  };
};
