import React from 'react';
import { ChainsProvider } from 'contexts/ChainsContext';
import { getLibrary } from 'utils/web3React';
import { Web3ReactProvider } from '@web3-react/core';
import { ModalProvider } from 'contexts/ModalContext';
import { RefreshContextProvider } from 'contexts/RefreshContext';
import { DrawerProvider } from 'contexts/DrawerContext';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';
import { SnackbarProvider as ToastsProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'state';
import { MixpanelProvider } from 'contexts/MixpanelContext';
import { GoogleAnalyticsProvider } from 'contexts/GoogleAnalyticsContext';

const Providers: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <ReduxProvider store={store}>
      <ChainsProvider>
        <CustomMuiThemeProvider>
          <RefreshContextProvider>
            <ToastsProvider>
              <DrawerProvider>
                <ModalProvider>
                  {children}
                </ModalProvider>
              </DrawerProvider>
            </ToastsProvider>
          </RefreshContextProvider>
        </CustomMuiThemeProvider>
      </ChainsProvider>
    </ReduxProvider>
  </Web3ReactProvider>
);

export default Providers;
