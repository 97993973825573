/* eslint-disable max-len */
import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.99999 0L11.1961 9H0.803833L5.99999 0Z" fill="#00DB5C" />
  </Svg>
);

export default Icon;
