import React from 'react';
import {
 BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
// import './App.css';
import { styled } from '@mui/material/styles';
import BigNumber from 'bignumber.js';

import TimeMachineDetail from 'pages/TimeMachineDetail';

import Menu from 'components/Menu';
import Drawer from 'components/Drawer';
import { timeMachineUrlByType, timeMachineUrlByDex } from 'pages/TimeMachine/constants';
import { timeMachineDetailFullUrl } from 'pages/TimeMachineDetail/constants';
import NotFound from 'pages/NotFound';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { GoogleAnalyticsProvider } from 'contexts/GoogleAnalyticsContext';
import { MixpanelProvider } from 'contexts/MixpanelContext';
import 'react-perfect-scrollbar/dist/css/styles.css';
import WrappedMainContainer from 'components/MainContainer';
import Footer from 'components/Footer';
import { Helmet } from 'react-helmet';
import { getEnv, isDev } from 'config';
import TimeMachine from './pages/TimeMachine';

dayjs.extend(relativeTime);
dayjs.extend(utc);

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

function App() {
  // useEagerConnect();
  return (
    <>
      <CssBaseline />
      <Router>
        {isDev && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
)}
        <GoogleAnalyticsProvider>
          <MixpanelProvider>
            <Menu />
            <Drawer />
            {/**  [SEO] Remainder update the route in server/index.js after update client side */}
            <Switch>
              <Route exact path="/"><Redirect to="/discover" /></Route>
              <Route exact path={timeMachineUrlByType} component={TimeMachine} />
              <Route exact path={timeMachineUrlByDex} component={TimeMachine} />
              <Route exact path={timeMachineDetailFullUrl} component={TimeMachineDetail} />
              <Route exact path="/404" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
            <WrappedMainContainer>
              <Footer />
            </WrappedMainContainer>
          </MixpanelProvider>
        </GoogleAnalyticsProvider>
      </Router>
    </>
  );
}

export default App;
