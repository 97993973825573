import {
  Area, Chart, Line, Axis,
  Tooltip, Interval, Legend, View
} from 'bizcharts';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { formatPercent, formatPriceCurrency } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { alpha } from '@mui/material';
import DataSet from '@antv/data-set';

const TimeMachineDetailLiquidityGraph:React.FC<{
  data: any[],
  fields: {[key: string]: {name: string, alias: string, color: string, active: boolean}},
  [key: string]: any,
}> = ({
  data,
  fields,
  ...otherProps
}) => {
  const newData = useMemo(() => {
    return _.map(data, v => ({
      ...v,
      tvlUSD: fields['tvlUSD'].active ? v.tvlUSD : undefined,
      volumeUSD: fields['volumeUSD'].active ? v.volumeUSD : undefined,
      utilization: fields['utilization'].active ? v.volumeUSD / v.tvlUSD : undefined,
    }))
  }, [data, fields])
  const minY = _.min([_.get(_.minBy(newData, "volumeUSD"), "volumeUSD"), _.get(_.minBy(newData, "tvlUSD"), "tvlUSD")]);
  const maxY = _.max([_.get(_.maxBy(newData, "volumeUSD"), "volumeUSD"), _.get(_.maxBy(newData, "tvlUSD"), "tvlUSD")]);
  const scale = {
    query_ts: {
      // type: 'time',
      sync: true,
      formatter: v => {
        return dayjs(v).utc().format('YYYY-MM-DD');
      }
    },
    value: {
      nice: true,
      alias: fields['tvlUSD'].alias,
      tickCount: 7,
      type: 'linear-strict',
      min: minY,
      max: maxY,
      formatter: formatPriceCurrency,
    },
    key: {
      formatter: (v) => {
        return fields[v]?.alias;
      }
    },
    // tvlUSD: {
    //   nice: true,
    //   alias: fields['tvlUSD'].alias,
    //   tickCount: 7,
    //   type: 'linear-strict',
    //   min: minY,
    //   max: maxY,
    //   formatter: formatPriceCurrency,
    // },
    // volumeUSD: {
    //   nice: true,
    //   alias: fields['volumeUSD'].alias,
    //   tickCount: 7,
    //   type: 'linear-strict',
    //   min: minY,
    //   max: maxY,
    //   formatter: formatPriceCurrency,
    // },
    utilization: {
      nice: true,
      tickCount: 7,
      alias: fields['utilization'].alias,
      type: 'linear-strict',
      formatter: formatPercent,
    },
  };
  const dataInterval = useMemo(() => {
    const ds = new DataSet();
    const dv = ds.createView().source(newData);
    dv.transform({
      type: 'fold',
      fields: [
        'tvlUSD',
        'volumeUSD',
      ],
      //   fields: ['time'],
      // 展开字段集
      key: 'key',
      // key字段
      value: 'value', // value字段
    });
    return dv.rows;
  }, [newData]);
  return (
    <Chart {...otherProps} scale={scale} data={dataInterval} autoFit>
      <Tooltip shared />
      <Axis 
        name="query_ts"
        label={{
          formatter: v => dayjs(v).format('D MMM')
        }}
      />
      {/* <Axis 
        position="right"
        name="utilization"
      />
      <Interval position="query_ts*utilization" color={alpha(fields['utilization'].color, 0.8)} />
      <Axis 
        position="left"
        name="tvlUSD"
      />
      <Line color={fields['tvlUSD'].color} position="query_ts*tvlUSD" />
      <Axis 
        position="left"
        name="volumeUSD"
      />
      <Line color={fields['volumeUSD'].color} position="query_ts*volumeUSD" /> */}
      <Axis 
        position="right"
        name="utilization"
      />
      <Axis title={{text: 'USD'}} name='value' position='left' />
      <Interval 
          adjust={[
            {
              type: 'dodge',
              marginRatio: 0,
            },
          ]}
          color={["key", (v) => fields[v].color]}
          position='query_ts*value'
      />
      <Line position="query_ts*utilization" color={alpha(fields['utilization'].color, 0.8)} />
      <Legend visible={false} />
    </Chart>
  )
}

export default TimeMachineDetailLiquidityGraph;