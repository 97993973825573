  import React, { useMemo } from 'react';
  import {
 formatPercent, formatPrice, formatPriceCurrency, formatPriceLong, formatPercentDetailed, formatNumberLong,
} from 'utils/formatNumber';
  import { Box, styled, experimental_sx as sx } from '@mui/system';
  import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
  import { Text, TrendText } from 'components/Text';

  const TimeMachineTimeTunnelShareTooltipCoinDetail:React.FC<{
    coin: string,
    coinPrice: number,
    coinPercentage: number,
  }> = ({
    coin,
    coinPrice,
    coinPercentage,

  }) => (
    <Box
      key={coin}
      sx={{
  backgroundColor: '#F6F9FC',
  borderRadius: '5px',
  padding: '2px 8px',
  width: 200,
}}
    >
      <InlineMiddleFlex sx={{ typography: 'body2', my: 1, fontSize: '12px' }} display="flex" justifyContent="space-between">

        <Text color="#656E78">{coin}</Text>
        <Text color="#656E78" bold>
          <>

            {formatNumberLong(coinPrice, 0, 2)}
            (
            <TrendText component="span" value={coinPercentage}>
              {formatPercentDetailed(coinPercentage, null, {
 style: 'percent',
    maximumFractionDigits: 2,
})}

            </TrendText>
            )
          </>
        </Text>
      </InlineMiddleFlex>
      {' '}

    </Box>
  );

  export default TimeMachineTimeTunnelShareTooltipCoinDetail;
