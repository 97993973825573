import { FarmVolumeAndTVLSummaryDto } from '@iddy/time-machine';
import TabList from '@mui/lab/TabList';
import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, experimental_sx as sx } from '@mui/system';
import { Text } from 'components/Text';
import _ from 'lodash';
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { formatPrice } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { TextToggleButtonGroup } from 'components/TextToggleButtonGroup';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { GraphLegendItem } from 'components/GraphLegendItem';
import { CircleIcon } from 'components/CircleIcon';
import HistoricalCardPaper from './HistoricalCardPaper';
import TimeMachineDetailLiquidityGraph from './TimeMachineDetailLiquidityGraph';
import { useFarmLiquidityVolumeGraphData } from '../hooks/useFarmLiquidityVolumeGraphData';
import { useDetailPageContext } from '../context';

const StyledContainer = styled(Box)(
  sx({
    p: 3,
    pt: 0.5,
  }),
);

const TimeMachineDetailLiquidityAverageController:React.FC<{}> = (props) => {
  const { info } = useDetailPageContext();
  const summary = useFarmLiquidityVolumeGraphData();
  const [period, setPeriod] = useState('1M');
  const handleChange = (e, v) => (v ? setPeriod(v) : null);
  const [fields, setFields] = useState<{[key: string]: {name: string, alias: string, color: string, active: boolean}}>({
    tvlUSD: {
 name: 'tvlUSD', alias: 'Liquidity', color: '#84CBF2', active: true,
},
    volumeUSD: {
 name: 'volumeUSD', alias: 'Volume', color: '#E57EC3', active: true,
},
    utilization: {
 name: 'utilization', alias: 'Utilization', color: '#7480FF', active: true,
},
  });
  const graphFields = fields;

  const fetch = () => {
    if (!info) {
      return;
    }
    const today = dayjs().startOf('d');
    let startDate; let
endDate;
    switch (period) {
      case 'ytd':
        startDate = today.startOf('y');
        endDate = today;
        break;
      case 'max':
        startDate = dayjs(info.dateMinMax.minDate);
        endDate = today;
        break;
      default:
        const match = period.match(/(\d+)(\w+)/);
        if (match) {
          startDate = today.subtract(Number.parseFloat(match[1]), match[2]);
        }
        endDate = today;
    }
    summary.fetch({ poolId: info.poolId, fromDate: startDate.format(), toDate: endDate.endOf('d').format() });
  };
  useEffect(() => {
    if (info) {
      fetch();
    }
  }, [info, period]);
  const handleLegendClick = (e, v) => {
    const newValue = {
      ...fields,
      [v.name]: {
        ...v,
        active: !v.active,
      },
    };
    if (_.some(_.values(newValue), { active: true })) {
      setFields({
        ...fields,
        [v.name]: {
          ...v,
          active: !v.active,
        },
      });
    }
  };

  return (
    <HistoricalCardPaper title="Daily Liquidity & Volume (UTC)">
      <StyledContainer>
        <InlineMiddleFlex sx={{ mt: 1, mb: 3.5, flexWrap: 'wrap' }} justifyContent="space-between" gap={1}>
          <InlineMiddleFlex flex="0 1 300px" component="div" gap={2} sx={{ width: 'min-content' }}>
            {_.map(graphFields, (v) => (
              <GraphLegendItem color={v.color} active={v.active} component="li" key={v.name} onClick={(e) => handleLegendClick(e, v)}>
                <CircleIcon />
                {' '}
                <Text component="span">{v.alias}</Text>
              </GraphLegendItem>
            ))}
          </InlineMiddleFlex>
          <TextToggleButtonGroup
            size="small"
            value={period}
            exclusive
            onChange={handleChange}
            aria-label="text alignment"
          >
            <ToggleButton value="1M" aria-label="left aligned">
              1M
            </ToggleButton>
            <ToggleButton value="3M" aria-label="centered">
              3M
            </ToggleButton>
            <ToggleButton value="6M" aria-label="right aligned">
              6M
            </ToggleButton>
            <ToggleButton value="ytd" aria-label="justified">
              YTD
            </ToggleButton>
            <ToggleButton value="1y" aria-label="justified">
              1Y
            </ToggleButton>
            <ToggleButton value="max" aria-label="justified">
              Max
            </ToggleButton>
          </TextToggleButtonGroup>
        </InlineMiddleFlex>
        <Box position="relative" height={300}>
          <Box position="absolute" width="100%">
            {summary.isReady
            ? <TimeMachineDetailLiquidityGraph fields={fields} data={summary.data} height={300} />
            : <Skeleton variant="rectangular" width="100%" height={300} />}
          </Box>
        </Box>
      </StyledContainer>
    </HistoricalCardPaper>
);
};

export default TimeMachineDetailLiquidityAverageController;
