import React, { useState, useEffect, useMemo } from 'react';
import { ChartResult, FarmSimulateDto } from '@iddy/time-machine';
import _ from 'lodash';

export const useFormattedGraphDataForChartLib = (param: {
  data: ChartResult,
  principal: number,
  showPrincipal?: boolean,
  keyFields: any[],
  view?: string
}) => useMemo(() => {
    const {
 data, principal, view, showPrincipal, keyFields,
} = param;
    const cur:FarmSimulateDto[] = data[view];
    const result = [];
    const keyList = keyFields;
    // for testing filter one day data
    // if(cur.length > 0){
    //   let minQueryTs = _.min(_.map(cur, 'query_ts'));
    //   console.log('real cur', cur.length)
    //   console.log(minQueryTs)
    //   cur = _.filter(cur, (v) => dayjs(v.query_ts).unix() - dayjs(minQueryTs).unix() > 86400)
    //   console.log('filter cur', cur.length)
    // }

    _.forEach(cur, (item) => {
      const obj = {};
      const time = item.query_ts;
      let latestValue = 0;
      _.forEach(keyList, (key, idx) => {
        let value = _.get(item, key);
        if (key === 'totalDexYield' && item.totalExtraReward) {
          value += item.totalExtraReward;
        }
          const endValue = latestValue + value;
        obj[key] = {
          value,
          area: [latestValue, endValue],
          line: (idx + 1 === keyList.length) ? endValue : null,
          percentage: value,
        };
        latestValue = endValue;
      });

      if (view === 'roi') {
        _.forEach(keyList, (key, idx) => {
          const cur = obj[key];
          // let base = (showPrincipal) ? 1 : 0;
          const base = 0;

          obj[key] = {
            value: cur.value * principal,
            area: [(base + cur.area[0]) * principal, (base + cur.area[1]) * principal],
            line: cur.line ? (base + cur.area[1]) * principal : null,
            percentage: cur.percentage,
          };
        });
      }

      _.forEach(keyList, (key) => {
        const cur = obj[key];
        result.push({
          lineApr: (view === 'apr') ? cur.line : undefined,
          time,
          type: key,
          ...cur,
        });
      });
    });
    return result;
  }, [param]);
