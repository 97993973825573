import { TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { experimental_sx as sx } from '@mui/system';
import { styled } from '@mui/material';

const StyledTextPanel = styled(TabPanel)(
  sx({
    p:0,
  })
)

const MultiLineGrid = styled(Grid)(
  sx({
    display: 'flex',
    height: '2.86em',
    alignItems: 'center',
  })
)

const RealReturnRateDetail:React.FC<{title?, tradingFee, dexYield, priceImpact, impermanentLoss}> = ({
  title, tradingFee, dexYield, priceImpact, impermanentLoss, ...otherProps
}) => (
  <Box mb={1.5} {...otherProps}>
    {/* <Text sx={{mb: 1.5}} typography="small" color={'secondary'} bold>{title}&nbsp;</Text> */}
    <Grid
      rowGap={1}
      container
      flexDirection="column"
    >
      <Grid item>{tradingFee}</Grid>
      <Grid item>{dexYield}</Grid>
      <StyledTextPanel value="priceImpact">
        <MultiLineGrid item>{priceImpact}</MultiLineGrid>
      </StyledTextPanel>
      <StyledTextPanel value="impermanentLoss">
        <MultiLineGrid item>{impermanentLoss}</MultiLineGrid>
      </StyledTextPanel>
    </Grid>
  </Box>
);

export default RealReturnRateDetail;
