import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, useTheme } from "@mui/system";
import { Button, Collapse, Divider, Grid, Paper, styled, Tab, Tabs, useMediaQuery } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken } from "components/Image/helpers";
import images from "assets/images";
import { Text } from "components/Text";
import { useChainList, useDexList } from "lib/timeMachineHelpers";
import { useForm, FormProvider, useFormContext, useController } from "react-hook-form";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TimeMachineFilterGroup from "./TimeMachineFilterGroup";
import TimeMachineFilterItem from "./TimeMachineFilterItem";
import TimeMachineComingSoonFilterItem from "./TimeMachineComingSoonFilterItem";
import _, { truncate } from "lodash";
import InfoDescriptionPopover from "components/InfoDescriptionPopover";
import { useTimeMachinePageContext } from "../context";


const HeaderGrid = styled(Grid)({})
const StyledContainer = styled(Paper)(
  sx({
    px: 3,
    py: 1.5,
  })
)
const FiltersContainer = styled(Box)({});
const FiltersGroupContainer = styled(Box)(
  sx({
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
  })
);
const FilterDivider = styled(Divider)(
  sx({
    "&.MuiDivider-root": {
      my: 1.5,
      borderStyle: 'dashed',
      color: "#DADCDE",
    }
  })
);
const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)(
  sx({
    color: 'text.secondary'
  })
)

const NewLabel = styled(Text)(
  sx({
    background: "#00DB5C",
    px: 1,
    borderRadius: 20,
    color: 'white',
  })
)

const FilterBtn = styled(Text)(
  sx({
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
  })
)

const ResetBtn = styled(Text)(
  sx({
    cursor: 'pointer',
  })
)

const FormControlGrid = styled(Grid)(
  sx({

  })
)

const ApplyButton = styled(Button)(
  sx({
    py: 0.25,
    px: 2,
    borderRadius: 1,
    fontWeight: 500,
  })
)

const TimeMachineFilterForm = (props) => {
  const { breakpoints } = useTheme();
  const isTablet = window.innerWidth > breakpoints.values.lg;
  const [open, setOpen] = useState(isTablet);
  const { reset } = useFormContext();
  const handleClickOpen = () => setOpen(!open);
  const handleResetClick = () => reset();

  return <Box {...props}>
    <StyledContainer>
      <HeaderGrid container alignItems={['center']} justifyContent={'space-between'}>
        <Grid container alignItems={'center'} flexWrap={'nowrap'} item flex="0" onClick={handleClickOpen}>
          <FilterBtn>
            <Text textTransform="uppercase" bold color="secondary">
              Filter
            </Text>
            <StyledKeyboardArrowUpIcon sx={!open && {'transform': 'rotate(180deg)'}} />
          </FilterBtn>
        </Grid>
        <Grid item flex="0" onClick={handleResetClick}>
          <ResetBtn typography="body2" color="secondary"><u>Reset</u></ResetBtn>
        </Grid>
      </HeaderGrid>
      {open !== null && (
        <Collapse in={open}>
          <FiltersContainer>
            <FilterDivider></FilterDivider>
            <FiltersGroupContainer>
              <DexFilterGroup />
              <TvlFilterGroup />
              <AssetsTypeFilterGroup />
              <MetricFilterGroup />
            </FiltersGroupContainer>
          </FiltersContainer>
        </Collapse>
      )
      }
    </StyledContainer>
  </Box>;
}

const DexFilterGroup = () => {
  const context = useTimeMachinePageContext();
  const { data } = useDexList({chain: context?.chain});
  const { control } = useFormContext();
  const name = 'dex_in';
  const cont = useController({
    control,
    name,
    defaultValue: []
  });
  return <TimeMachineFilterGroup label={"DEX"}>
    <FilterMultipleAllItem 
      controlReturn={cont}
      name={name}
    />
    {_.map(data, (dex, idx) => (dex.launch
        ?
          <FilterMultipleItem 
            key={idx}
            controlReturn={cont}
            name={name}
            value={dex.exchange}
            startIcon={<img height="18px" src={getImageUrlFromToken(dex.exchange, 'dex')} />}
          >
            {dex.name}
          </FilterMultipleItem>
        :
          <TimeMachineComingSoonFilterItem
            key={idx}
            controlReturn={cont}
            name={name}
            value={dex.exchange}
            startIcon={<img height="18px" src={getImageUrlFromToken(dex.exchange, 'dex', false, true)} />}
          >
            {dex.name}
          </TimeMachineComingSoonFilterItem>
        )
    )}
  </TimeMachineFilterGroup>
}

const TvlFilterGroup = () => {
  const { control } = useFormContext();
  const name = 'totalValueLock_between_in';
  const options = useMemo(() => [
    {
      label: '$ 5 M - 10 M',
      value: [5e6, 10e6],
    },
    {
      label: '$ 10 M - 50 M',
      value: [10e6, 50e6],
    },
    {
      label: '$ 50 M or above',
      value: [50e6, null],
    },
  ], []);
  const cont = useController({
    control,
    name,
    defaultValue: []
  });
  return <TimeMachineFilterGroup label={"Liquidity"}>
    <FilterMultipleAllItem 
      controlReturn={cont}
      name={name}
    />
    {_.map(options, (option, idx) => 
      <FilterMultipleItem 
        key={idx}
        controlReturn={cont}
        name={name}
        value={option.value}
      >
        {option.label}
      </FilterMultipleItem>
    )}
  </TimeMachineFilterGroup>
}

const AssetsTypeFilterGroup = () => {
  const { control } = useFormContext();
  const name = 'assetsType';
  const options = useMemo(() => [
    {
      label: 'Stable Coins Pair',
      value: 'stable',
    },
    {
      label: 'Non-stable + Stable Coins Pair',
      value: 'stableAndNonStable',
    },
    {
      label: 'Native Token',
      value: 'includeNative',
    },
    {
      label: 'Others',
      value: 'others',
    },
  ], []);
  const cont = useController({
    control,
    name,
    defaultValue: []
  });
  return <TimeMachineFilterGroup label={"Assets Type"}>
    <FilterMultipleAllItem 
      controlReturn={cont}
      name={name}
    />
    {_.map(options, (option, idx) => 
      <FilterMultipleItem 
        key={idx}
        controlReturn={cont}
        name={name}
        value={option.value}
      >
        {option.label}
      </FilterMultipleItem>
    )}
  </TimeMachineFilterGroup>
}

const MetricFilterGroup = () => {
  const { control } = useFormContext();
  const name = 'metric';
  const options = useMemo(() => [
    {
      label: 'Trading Fees > Yield Farming',
      value: 'lpRewardHigherThenYield',
      info: 'Metric LP Reward',
    },
    {
      label: 'Price Effect  > 0',
      value: 'positivePriceImpact',
      info: 'Metric Price Impact',
    },
    {
      label: 'Fees Growth Rate > 1',
      value: 'positiveGrowRate',
      info: 'Metric Growth Rate',
    },
    {
      label: 'Positive Return in 180D',
      value: 'positiveReturnIn180D',
      info: 'Metric Positive Return',
    },
  ], []);
  const cont = useController({
    control,
    name,
    defaultValue: []
  });
  return <TimeMachineFilterGroup 
    label={<>
        Metric <NewLabel component={'span'} typography="caption" bold >Featured</NewLabel>
    </>}
  >
    <FilterMultipleAllItem 
      controlReturn={cont}
      name={name}
    />
    {_.map(options, (option, idx) => 
      <FilterMultipleItem 
        key={idx}
        controlReturn={cont}
        name={name}
        value={option.value}
      >
        <InfoDescriptionPopover description={option.info}>
          {option.label}
        </InfoDescriptionPopover>
      </FilterMultipleItem>
    )}
  </TimeMachineFilterGroup>
}

const FilterMultipleAllItem = ({name, controlReturn, ...props}) => {
  const cont = controlReturn;
  const { field } = cont;
  const formValue = field.value;
  const checked = formValue === null || (Array.isArray(formValue) && formValue.length == 0);
  const handleClick = (() => {
    if(!checked){
      field.onChange(null);
    }
  })
  return  <TimeMachineFilterItem onClick={handleClick} active={checked} {...props}>All</TimeMachineFilterItem>;
}

const FilterSingleItem = ({name, value, children, controlReturn, ...props}) => {
  const cont = controlReturn;
  const { field } = cont;
  const formValue = field.value;
  const checked = formValue == value;
  const handleClick = (() => {
    if(!checked){
      const newValue = value;
      field.onChange(newValue);
    }else{
      const newValue = null;
      field.onChange(newValue);
    }
  })
  return  <TimeMachineFilterItem onClick={handleClick} active={checked} {...props}>{children}</TimeMachineFilterItem>;
}

const FilterMultipleItem = ({name, value, children, controlReturn, ...props}) => {
  const cont = controlReturn;
  const { field } = cont;
  const formValue = field.value ?? [];
  const checked = _.includes(formValue, value);
  const handleClick = (() => {
    let curValue = (_.isArray(formValue)?formValue:[]);
    if(!checked){
        let newValue = [...curValue, value];
        field.onChange(newValue);
    }else{
        let newValue = _.filter(curValue, v => v !== value);
        field.onChange(newValue);
    }
  })
  return  <TimeMachineFilterItem onClick={handleClick} active={checked} {...props}>{children}</TimeMachineFilterItem>;
}

export default TimeMachineFilterForm;