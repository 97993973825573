import { styled } from '@mui/material/styles';

const Paper = styled('div')<{ color?: string}>`
  padding: 20px 33px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px 0px;
  }
  border-top: 2px solid ${({ theme, color }) => (color === 'secondary'
    ? theme.palette.secondary.main
    : theme.palette.primary.main)};
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
`;

export default Paper;
