import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import { Text } from 'components/Text';
import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { formatPrice } from 'utils/formatNumber';
import { FarmVolumeAndTVLSummaryDto } from '@iddy/time-machine';
import HistoricalCardPaper from './HistoricalCardPaper';
import { useDetailPageContext } from '../context';
import { useFarmLiquidityVolumeSummaryData } from '../hooks/useFarmLiquidityVolumeSummaryData';

const StyledContainer = styled(Box)(
  sx({
    py: 2,
    px: 3,
    width: '100%',
  }),
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  sx({
    '& .MuiToggleButtonGroup-grouped': {
      background: 'unset',
      color: 'text.secondary',
      margin: 0.5,
      border: 0,
      '&.Mui-selected': {
        background: 'unset',
        color: 'primary.main',
      },
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: 1,
      },
      '&:first-of-type': {
        borderRadius: 1,
      },
    },
  }),
);

const AverageLiquidityGrid = styled(Grid)(
  sx({
    justifyContent: 'space-between',
    gap: 3,
    '& > *': {
        flex: '0 1 60px',
    },
  }),
);

const TimeMachineDetailLiquidityAverageController:React.FC<{}> = (props) => {
  const { info } = useDetailPageContext();
  const summary = useFarmLiquidityVolumeSummaryData();
  useEffect(() => {
    if (info) {
      summary.fetch({ poolId: info.poolId });
    }
  }, [info]);
  if (!summary.isReady) {
    return <></>;
  }
  return (
    <Grid container gap={2}>
      <Grid item flex="1 1 250px">
        <HistoricalCardPaper title="Average Liquidity">
          <StyledContainer>
            <AverageLiquidityGrid container>
              <Grid item>
                <LiquidityAverageItem field="avgTVLUSD" data={summary.data} period="24h" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgTVLUSD" data={summary.data} period="7d" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgTVLUSD" data={summary.data} period="30d" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgTVLUSD" data={summary.data} period="90d" />
              </Grid>
            </AverageLiquidityGrid>
          </StyledContainer>
        </HistoricalCardPaper>
      </Grid>
      <Grid item flex="1 1 250px">
        <HistoricalCardPaper title="Average Daily Volume">
          <StyledContainer>
            <AverageLiquidityGrid container>
              <Grid item>
                <LiquidityAverageItem field="avgDailyVolumeUSD" data={summary.data} period="24h" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgDailyVolumeUSD" data={summary.data} period="7d" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgDailyVolumeUSD" data={summary.data} period="30d" />
              </Grid>
              <Grid item>
                <LiquidityAverageItem field="avgDailyVolumeUSD" data={summary.data} period="90d" />
              </Grid>
            </AverageLiquidityGrid>
          </StyledContainer>
        </HistoricalCardPaper>
      </Grid>
    </Grid>
);
};

const LiquidityAverageItem = ({
  field,
  data,
  period,
}) => {
  const periodData:FarmVolumeAndTVLSummaryDto = useMemo(() => _.find(data, { period }), [period, data]);
  return (
    <Box>
      <Text typography="small" color="text.secondary" bold="500">{_.toUpper(period)}</Text>
      <Text sx={{ mt: 1 }} typography="body2" bold="600">{formatPrice(_.get(periodData, field), '-')}</Text>
    </Box>

  );
};

export default TimeMachineDetailLiquidityAverageController;
