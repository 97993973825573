import { FarmDataDto, FarmDetailDto } from '@iddy/time-machine';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Paper, styled } from '@mui/material';
import { Box, experimental_sx as sx } from '@mui/system';
import { getImageUrlFromTokenDto } from 'components/Image/helpers';
import TokenImage from 'components/Image/TokenImage';
import { Text } from 'components/Text';
import _ from 'lodash';
import React from 'react';
import {
 formatPercent, formatPriceCurrency, formatNumber, formatTokenAmount, formatPrice,
} from 'utils/formatNumber';

const StyledContainer = styled(Paper)(
  sx({
    m: 1,
    borderRadius: 4,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    py: 1,
    px: 2,
    typography: 'body2',
    fontWeight: 500,

    '& > *': {
      my: 1,
    },
  }),
);

const TokenLockInfo = styled(Box)(
  sx({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 1,
  }),
);

const FarmTvlPopoverContent:React.FC<{
  item
}> = ({ item }) => (
  <StyledContainer>
    <Text bold color="secondary">Liquidity</Text>

    <Text>{formatPrice(item.totalValueLocked, '', { notation: 'standard', maximumSignificantDigits: undefined })}</Text>

    <TokenLockInfo>
      <TokenImage token={item.pool.tokenEntity.symbol} />
      <Text>{formatTokenAmount(item.reserve0)}</Text>
      <Text>
        (1
        {item.pool.tokenEntity.symbol}
        {' '}
        =
        {formatNumber(item.tokenPrice, 0, 8)}
        {' '}
        USD)
      </Text>
    </TokenLockInfo>

    <TokenLockInfo>
      <TokenImage token={item.pool.quoteTokenEntity.symbol} />
      <Text>{formatTokenAmount(item.reserve1)}</Text>
      <Text>
        (1
        {item.pool.quoteTokenEntity.symbol}
        {' '}
        =
        {formatNumber(item.quoteTokenPrice, 0, 8)}
        {' '}
        USD)
      </Text>
    </TokenLockInfo>

  </StyledContainer>
);

export default FarmTvlPopoverContent;
