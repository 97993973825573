import React, { createContext, useState } from 'react';

interface DrawerContextProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DrawerContext = createContext<DrawerContextProps>(undefined);

export const DrawerProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(true);
  return (
    <DrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};
