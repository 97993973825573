import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button, styled, Tab, Tabs } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import IconMenuTab from './IconMenuTab';
import { getImageUrlFromToken } from "components/Image/helpers";
import images from "assets/images";
import { generatePath, matchPath } from "react-router-dom";
import { useRouter } from "hooks/useRouter";
import { timeMachineUrlByDex, timeMachineUrlByType } from "pages/TimeMachine/constants";
import { ContinuousLegend } from "@antv/g2/lib/dependents";
import { getDiscoverLink, getDiscoverLinkByChain, getNewPoolLink } from "utils/linkHelpers";
import { useChainList } from "lib/timeMachineHelpers";
import _ from 'lodash';
import ComingSoonTooltip from "components/ComingSoonTooltip";
import { Text } from "components/Text";
import InfoPopover from "components/InfoPopover";
import InfoDescriptionPopover from "components/InfoDescriptionPopover";

const StyledTabs = styled(Tabs)(
  sx({
    "& .MuiTabs-scroller": {
      overflow: 'visible',
    },
    "& .MuiTabs-flexContainer": {
      gap: '24px',
    },
    '& .MuiTabs-indicator': {
      left: 0,
      width: 4,
    },
  })
)

const DashboardTabs = (props) => {
  const route = useRouter();
  const [tabValue, setTabValue] = useState<string>(route.pathname);
  const {data: chains} = useChainList();
  
  const handleChainClick = (link) => route.history.push(link); 
  
  const dashLink = getDiscoverLink();
  const newPoolLink = getNewPoolLink();
  const handleDashboardClick = () => {
    route.push(dashLink);
  }
  
  const handleNewPoolsClick = () => {
    route.push(newPoolLink);
  }
  
  useEffect(() => {
    setTabValue(route.pathname)
  }, [route.pathname])
  
  return <Box {...props}>
    <StyledTabs
      orientation="vertical"
      value={tabValue}
    >
      <IconMenuTab
        value={dashLink}
        onClick={handleDashboardClick}
        icon={<img src={images.dashboard} />}
        label={'Discover All'}
      />
      {chains && 
        _.map(chains, (chain) => {
          const link = getDiscoverLinkByChain(chain.symbol);
          if(chain.launch){
            return <IconMenuTab 
              value={link}
              key={chain.name}
              icon={<img src={getImageUrlFromToken(chain.symbol, "chain")} />}
              label={chain.name}
              onClick={() => handleChainClick(link)}
            />;
          }else{
            return <ComingSoonTooltip>
                <IconMenuTab 
                  key={chain.name}
                  icon={<img src={getImageUrlFromToken(chain.symbol, "chain", false, true)} />}
                  label={<Text color="secondary">{chain.name}</Text>}
                />
            </ComingSoonTooltip>
          }
        })
      }
    </StyledTabs>
  </Box>
}

export default DashboardTabs;