import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import MainContainer from 'components/MainContainer';
import { Text } from 'components/Text';
import { useRouter } from 'hooks/useRouter';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Grid, Button, Theme } from '@mui/material';
import { RadiusButton } from 'components/RadiusButton';
import { useFarmSummary } from '../hooks/useFarmSummary';
import { useDetailPageContext } from '../context';
import TimeMachineDetailReturnRateTable from './TimeMachineDetailReturnRateTable';
import HistoricalCardPaper from './HistoricalCardPaper';

const TimeMachineDetailReturnRateController:React.FC<{}> = (props) => {
  const { info } = useDetailPageContext();
  const summary = useFarmSummary();
  useEffect(() => {
    if (info) {
      summary.fetch({ poolId: info.poolId });
    }
  }, [info]);
  return (
    <HistoricalCardPaper title="Return Rate ​(ROI)​ Summary">
      {summary.isReady
        ? <TimeMachineDetailReturnRateTable data={summary.data} />
        : <></>}
    </HistoricalCardPaper>
  );
};

export default TimeMachineDetailReturnRateController;
