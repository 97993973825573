import React from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TypeBackground } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground{
    navigation: string,
  }
}

declare module '@mui/material/styles' {

  interface Palette {    
    greyColor: Palette["primary"];
    single: Palette["primary"];
  }
  interface PaletteOptions {    
    greyColor: PaletteOptions["primary"];
    single: PaletteOptions["primary"];
  } 
  interface PaletteColor {
    mainGradient?: string;
    lightGradient?: string;
    darkGradient?: string;
  }

  interface SimplePaletteColorOptions {
    mainGradient?: string;
    lightGradient?: string;
    darkGradient?: string;
  }

  interface TypographyVariants {
    small: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
  }

}

const themePalette = createTheme({
  palette: {
    background: {
      default: '#F6F9FC',
      navigation: '#4B3F8C',
    },
    primary: {
      main: '#7480FF',
      mainGradient: 'linear-gradient(176.49deg, #7480FF 2.89%, rgba(116, 128, 255, 0) 97.11%)',
      light: '#DEE1FF',
      lightGradient: 'linear-gradient(90.58deg, #817FF91A 11%, #8D72FF1A 109.9%)',
      dark: '#5762D9',
      darkGradient: 'linear-gradient(86.23deg, #363674 -5.24%, #5642A7 101.47%)',
      contrastText: 'white',
    },
    secondary: {
      main: '#F9D47C',
      mainGradient: 'linear-gradient(248.66deg, #FFDA7C -16.33%, #F4CD00 106.68%)',
      light: 'rgb(255,250,232)',
      contrastText: 'white',
    },
    single: {
      main: '#7480FF',
      mainGradient: 'linear-gradient(90.89deg, #8B75FE 1.54%, #88B7FF 47.48%, #88F5F8 98.46%);',
      contrastText: 'white',
    },
    greyColor: {
      main: '#A8B4C1',
      contrastText: 'white',
      dark: '#656E78',
      light: '#DADCDE',
    },
    success: {
      main: '#2DCE89',
      contrastText: 'white',
    },
    error: {
      main: '#E84142',
      contrastText: 'white',
    },
    text: {
      primary: '#32325d',
      secondary: '#A8B4C1',
    },
  },
  typography: {
    fontFamily: '"Poppins","Helvetica","Arial",sans-serif;',
    fontSize: 14,
    h1:{
      fontSize: "56px",
    },
    h2:{
      fontSize: "44px",
    },
    h3:{
      fontSize: "30px",
    },
    h4:{
      fontSize: "20px",
    },
    h5:{
      fontSize: "18px",
    },
    h6:{
      fontSize: "16px",
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    small: {
      fontSize: '12px',
    },
    button:{
      fontSize: "16px",
    },
    caption:{
      fontSize: "10px",
    }
  },
});

const { palette, breakpoints } = themePalette;

const theme = createTheme(responsiveFontSizes(themePalette), {

  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
        position: 'static',
      },
      styleOverrides: {
        root: {
          boxShadow: '0 0',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          textTransform: 'capitalize',
          fontWeight: '600',
          boxShadow: '0 0',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiTableCell:{
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          paddingLeft: '8px;',
          paddingRight: '8px;',
        }
      }
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          boxShadow: "0px 4px 20px rgba(97, 53, 208, 0.06)",
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid white',
          // borderRadius: '27px',
          // border: '1px solid rgba(0,0,0,0.25)',
          // height: '52px',
        },
        // flexContainer: {
        //   padding: '3px 0',
        //   justifyContent: 'space-evenly',
        // },
        // scroller: {
        //   padding: '0 4px',
        // },
        indicator: {
          bottom: '2px',
          height: '2px',
          zIndex: 10,
          // '&:after': {
          //   content: '""',
          //   display: 'block',
          //   position: 'absolute',
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   borderRadius: '21px',
          //   background: palette.primary.mainGradient,
          // },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          flex: '1 0 96px',
          fontSize: '14px',
          borderBottom: `2px solid ${palette.primary.light}`,
          [breakpoints.down('sm')]: {
            fontSize: '12px',
          },
          zIndex: 1,
          color: palette.text.secondary,
          fontWeight: 'bold',
          '&.Mui-selected': {
            fontWeight: 'bold',
          },
          // whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'capitalize',
          minHeight: 44,
          '&:hover': {
            opacity: 1,
          },
        },
      },
    },
  },
});

export const CustomMuiThemeProvider: React.FC = ({ children }) => (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
);