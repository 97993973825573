import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend, Interval, Interaction, Point, Annotation,
} from 'bizcharts';
import React, { useMemo } from 'react';
import { formatPercent, formatPrice, formatPriceLong } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled, experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { useTheme } from '@mui/material';
import GraphRoiTooltips from './GraphRoiTooltips';
import GraphRrrTooltips from './GraphRrrTooltips';

const graphSplitRatio = 0.6;

const TimeMachineTimeTunnelShareGraph:React.FC<{
  data: {type: string, value: number, time: string, area: number[], line: number}[],
  tokenData:{type: string, valueToken: number, time: string, percentageToken: number}[],
  dataApr: {type: string, value: number, time: string, area: number[], line: number}[],
  lossView: string,
  formatter?: any,
  fields: {[key: string]: any},
  tooltip?: JSX.Element,
  showBreakDown?: boolean,
  height: number,
  [key: string]: any,
}> = ({
  data,
  tokenData,
  dataApr,
  lossView,
  fields,
  formatter = (v) => v,
  tooltip,
  showBreakDown,
  height,
  ...otherProps
}) => {
  const theme = useTheme();
  const min = showBreakDown ? _.min([..._.map(data, 'value'), ..._.map(data, 'line')]) : undefined;
  const max = showBreakDown ? _.max([..._.map(data, 'value'), ..._.map(data, 'line')]) : undefined;
  // const min = _.min(_.map(data, v => v.line));
  // const max = _.max(_.map(data, v => v.line));
  const scale = {
    area: {
      type: 'linear-strict',
      min,
      max,
      sync: true,
    },
    line: {
      sync: true,
      alias: 'Return Value',
      type: 'linear-strict',
      // formatter: formatter,
      min,
      max,
    },
    value: {
      sync: true,
      type: 'linear-strict',
      // formatter: formatter,
      min,
      max,
    },
    time: {
      type: 'time',
      sync: true,
    },
    lineApr: {
      sync: true,
      min: null,
      max: null,
      alias: 'Annualized Rate',
    },
  };
  const tooltipA = useMemo(() => (
    <GraphRoiTooltips
      lossView={lossView}
      show={(x, y) => y < height * graphSplitRatio}
      linkage="key"
      shared
      showCrosshairs
      tokenData={tokenData}
      showMarkers
      fields={fields}
    />
), [lossView]);
  const tooltipB = useMemo(() => (
    <GraphRrrTooltips
      lossView={lossView}
      show={(x, y) => y >= height * graphSplitRatio}
      linkage="key"
      shared
      showCrosshairs
      showMarkers
      fields={fields}
    />
), [lossView]);
  return (
    <Chart key={String(min + max)} height={height} {...otherProps} scale={scale} data={data} autoFit>
      {/* {tooltip} */}
      <Tooltip visible={false} />
      <View
        data={data}
        region={{
          start: { x: 0, y: 0 },
          end: { x: 1, y: graphSplitRatio },
        }}
      >
        {tooltipA}
        <Axis title={{ style: { fontFamily: 'Poppins' } }} name="line" label={{ formatter }} position="left" />
        <Axis title={{ style: { fill: 'transparent' } }} name="lineApr" label={{ style: { fill: 'transparent' }, formatter: (v) => formatPercent(v) }} grid={null} position="left" />
        <Axis name="area" label={{ style: { fill: 'transparent' } }} />
        <Axis name="value" label={{ style: { fill: 'transparent' } }} />
        <Axis name="valueToken" visible={false} />
        <Axis name="percentageToken" visible={false} />
        <Line size={2} position="percentageToken*valueToken" />
        {/* <Axis name="value" position='right'/> */}
        <Axis
          label={null}
          tickLine={null}
          name="time"
        />
        <Interval
          color={['type', (v) => (showBreakDown ? fields[v].color : 'transparent')]}
          position="time*value"
          shape="smooth"
          opacity={0.8}
          adjust={{ type: 'stack' }}
        />
        <Line size={2} color={['type', theme.palette.primary.main]} position="time*line" />
        <Line size={2} position="time*lineApr" />
      </View>
      <View
        data={dataApr}
        region={{
          start: { x: 0, y: graphSplitRatio },
          end: { x: 1, y: 1 },
        }}
      >
        {tooltipB}
        <Axis title={{ style: { fontFamily: 'Poppins' } }} name="lineApr" label={{ formatter: (v) => formatPercent(v) }} position="left" />
        <Axis name="value" label={{ style: { fill: 'transparent' } }} grid={null} position="left" />
        <Axis
          name="time"
          label={{
            formatter: (v) => dayjs(v).format('D MMM'),
          }}
        />
        <Line size={2} color={['type', theme.palette.primary.main]} position="time*lineApr" />
        <Interval
          color={['type', (v) => 'transparent']}
          position="time*value"
          shape="smooth"
          adjust={{ type: 'stack' }}
        />
      </View>
      <Legend visible={false} />
    </Chart>
  );
};

export default TimeMachineTimeTunnelShareGraph;
