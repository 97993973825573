import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, styled, Tab, Tabs } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';

const TabButton = styled(Button)(
  sx({
    p: 0,
    textAlign: 'left',
    justifyContent: 'flex-start',
    typography: 'body1',
    fontWeight: 'medium',
    color: 'text.primary',
  })
)

const Icon = styled(Box)({
  height: 24,
  "& > *": {
    height: '100%',
  }
});

const Label = styled(Box)(
  sx({
    ml: 2,
  })
);

const IconMenuTab:React.FC<{icon:any, label:any, value?:any, [key:string]: any}> = ({
  icon,
  label,
  ...otherProps
}) => {
  return <TabButton {...otherProps}>
    <Icon>
      {icon}
    </Icon>
    <Label>
      {label}
    </Label>
  </TabButton>
}

export default IconMenuTab;