import { TokenInfoDto } from 'lib/timeMachineHelpers';
import React, { createContext, useContext } from 'react';



interface TimeMachinePageContextData {
  chain: string,
}

export const TimeMachinePageContext = createContext<TimeMachinePageContextData>(null);

export const useTimeMachinePageContext = () => useContext(TimeMachinePageContext);

export const TimeMachinePageContextProvider: React.FC<{value: TimeMachinePageContextData}> = ({ children, value }) => {

  return (
    <TimeMachinePageContext.Provider value={value}>
      {children}
    </TimeMachinePageContext.Provider>
  );
};
