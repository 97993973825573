import { TokenDto } from '@iddy/time-machine';
import { DateRangePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
 alpha, Button, Grid, IconButton, InputAdornment, Paper, Skeleton, TextField, Theme, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx, SxProps } from '@mui/system';
import images from 'assets/images';
import { GradientButton } from 'components/GradientButton';
import TokenImage from 'components/Image/TokenImage';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import {
 CalenderIcon, EditIcon, EnterIcon, ExchangeIcon,
} from 'components/Svg';
import { Text } from 'components/Text';
import { TextToggleButtonGroup } from 'components/TextToggleButtonGroup';
import dayjs from 'dayjs';
import { TokenInfoDto } from 'lib/timeMachineHelpers';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import {
 formatNumber, formatPercent, formatPrice, formatTokenAmount, formatNumberLong,
} from 'utils/formatNumber';
import { useDetailPageContext } from '../context';
import { usePrincipalSpiltCoin } from '../hooks/usePrincipalSpiltCoin';

const StyledContainer = styled(Paper)(
  sx<Theme>({
    p: {
      xs: 1.5,
      md: 3,
    },
    bgcolor: 'background.paper',
    width: '100%',
  }),
);

const LiquidityPeriodButtonGroup = styled(ToggleButtonGroup)(
  sx<Theme>({
    typography: 'body2',
    '& .MuiButtonBase-root': {
      textTransform: 'unset',
      bgcolor: 'background.paper',
      px: 1.5,
      py: 0,
    },
    '& .MuiButtonBase-root.Mui-selected': {
      bgcolor: 'primary.main',
      color: 'primary.contrastText',
    },
    '& .MuiButtonBase-root.Mui-selected *': {
      fill: (theme) => theme.palette.primary.contrastText,
    },
  }),
);

const StyledTextField = styled(TextField)(
  sx({
    '& .MuiOutlinedInput-root': {
      pl: 1.5,
      pr: 1,
      py: 0.25,
      bgcolor: 'background.paper',
    },
    '& input': {
      pl: 0.25,
      pr: 1,
      py: 0.5,
    },
  }),
);

const DateDisplay = styled(Box)(
  sx({
    borderLeft: '2px solid red',
    borderColor: 'primary.main',
    pl: 2,
    py: 0.5,
  }),
);

const InputSubmitButton = styled(Button)(
  sx({
    py: 0,
    px: 1,
  }),
);

const TimeMachineTimeTunnelFilter:React.FC<{info: TokenInfoDto, onSubmit: () => void}> = ({
  info,
  onSubmit = () => {},
  ...props
}) => {
  const { watch } = useFormContext();
  const filterFields = watch(['startDate', 'endDate', 'principal', 'token', 'quoteToken']);
  useEffect(() => {
    onSubmit();
  }, [...filterFields]);
  return (
    <StyledContainer {...props}>
      <Grid container flexDirection="column" justifyContent="center" gap={2}>
        <TimeMachineTimeTunnelInputs info={info} />
      </Grid>
    </StyledContainer>
);
};

const LpSupplyInput = () => {
  const { field: fieldPeriod } = useController({ name: 'period' });
  const { watch } = useFormContext();
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const displayFrom = dayjs(startDate).format('YYYY-MM-DD');
  const displayTo = dayjs(endDate).diff(dayjs(), 'd') === 0 ? 'Now' : dayjs(endDate).format('YYYY-MM-DD');

  const handleChange = (e, v) => {
    if (v !== null) {
      fieldPeriod.onChange(v);
    }
  };

  return (
    <InputLabel label="LP supply period">
      <Box height={30}>
        <Text sx={{ position: 'relative', bottom: -2 }} typography="h4" bold>
          {displayFrom}
          {' '}
          -
          {' '}
          {displayTo}
        </Text>
      </Box>
      <TextToggleButtonGroup
        color="primary"
        exclusive
        value={fieldPeriod.value}
        onChange={handleChange}
      >
        <ToggleButton key="1d" value="1d">24H</ToggleButton>
        <ToggleButton key="7d" value="7d">7D</ToggleButton>
        <ToggleButton key="30d" value="30d">30D</ToggleButton>
        <ToggleButton key="90d" value="90d">90D</ToggleButton>
        <ToggleButton key="180d" value="180d">180D</ToggleButton>
        <ToggleButton key="max" value="max">Max</ToggleButton>
        {DateRangePickerToggleItem()}
      </TextToggleButtonGroup>
    </InputLabel>
  );
};

const PrincipalDisplayBox = styled(Box)(
  sx<Theme>({
    cursor: 'pointer',
    px: 1,
    ml: -1,
    borderRadius: 1,
    '&:hover': {
      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
    },
  }),
);

const PrincipalInput:React.FC<{info: TokenInfoDto}> = ({
  info,
}) => {
  const ref = useRef<HTMLElement>();
  const { data, isReady } = usePrincipalSpiltCoin(info.poolId);
  const [edit, setEdit] = useState(false);
  const [editToken, setEditToken] = useState(false);
  const [editQuoteToken, setEditQuoteToken] = useState(false);
  const { watch } = useFormContext();
  const startDate = watch('startDate');
  const { field: fieldPrincipal } = useController({ name: 'principal' });
  const [value, setValue] = useState(fieldPrincipal.value);
  const { field: fieldToken } = useController({ name: 'token' });
  const [valueToken, setValueToken] = useState(fieldToken.value);
  const { field: fieldQuoteToken } = useController({ name: 'quoteToken' });
  const [valueQuoteToken, setValueQuoteToken] = useState(fieldQuoteToken.value);

  const handleEnterClick = () => {
    fieldToken.onChange(null);
    fieldQuoteToken.onChange(null);
  fieldPrincipal.onChange(value);
    setEdit(false);
  };
  const handleTokenEnterClick = () => {
    fieldQuoteToken.onChange(null);
    fieldPrincipal.onChange(null);
    fieldToken.onChange(valueToken);
    setEditToken(false);
  };
  const handleQuoteTokenEnterClick = () => {
    fieldToken.onChange(null);
    fieldPrincipal.onChange(null);
    fieldQuoteToken.onChange(valueQuoteToken);
    setEditQuoteToken(false);
  };
  useEffect(() => {
    if (edit && ref.current) {
      setValue('');
      ref.current.querySelector('input').focus();
    }
    if (editToken && ref.current) {
      setValueToken('');
      ref.current.querySelector('input').focus();
    }
    if (editQuoteToken && ref.current) {
      setValueQuoteToken('');
      ref.current.querySelector('input').focus();
    }
  }, [edit, editToken, editQuoteToken]);
  const handleKeyPress = (e) => {
    // handle enter press
    if (e.charCode === 13) {
      handleEnterClick();
    }
  };
  const handleTokenKeyPress = (e) => {
    // handle enter press
    if (e.charCode === 13) {
      handleTokenEnterClick();
    }
  };
  const handleQuoteTokenKeyPress = (e) => {
    // handle enter press
    if (e.charCode === 13) {
      handleQuoteTokenEnterClick();
    }
  };

  return (
    <Grid container gap={1.5}>
      <Grid item>
        <InputLabel label={`Principal (${dayjs(startDate).format('YYYY-MM-DD')})`}>
          <Box height={30}>
            {
                edit
                ? (
                  <StyledTextField
                    onChange={(e) => setValue(e.target.value)}
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      onKeyPress: handleKeyPress,
                      ref,
                      value,
                      sx: { width: 200 },
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment sx={{ m: 0 }} position="end">
                          <Text color="text.primary">$</Text>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <InputSubmitButton variant="contained" onClick={handleEnterClick}>
                            <InlineMiddleFlex>
                              <EnterIcon />
                              {' '}
                              Enter
                            </InlineMiddleFlex>
                          </InputSubmitButton>
                        </InputAdornment>
                      ),
                    }}
                  />
)
                : (
                  <PrincipalDisplayBox onClick={() => {
                    setEditQuoteToken(false);
                    setEdit(true);
                    setEditToken(false);
                    }}
                  >
                    <Text typography="h4" bold="500">
                      <b>
                        {/* {formatPrice(fieldPrincipal.value)} */}
                        {formatPrice(data?.principal)}
                      </b>
                      {' '}
                      USD
                      <IconButton size="small"><EditIcon height={20} width={20} /></IconButton>
                    </Text>
                  </PrincipalDisplayBox>
)
              }
          </Box>
        </InputLabel>
      </Grid>
      <Grid item alignSelf="center" sx={{ pt: { xs: 0, md: 2 }, flexBasis: { xs: '100%', md: 'max-content' } }}>
        <Text bold typography="small">=</Text>
      </Grid>
      <Grid item sx={{ pt: { xs: 0, md: 1 } }}>
        <InlineMiddleFlex gap={1.5}>
          {!editToken ? (
            <PrincipalTokenAmountDisplay
              loading={!isReady}
              token={info?.token}
              priceRatio={data?.tokenPrice}
              percent={0.5}
              amount={data?.tokenNum}
              edit={editToken}
              onClick={() => {
                setEditQuoteToken(false);
                setEdit(false);
                setEditToken(true);
                }}
            />
) : (
  <StyledTextField
    onChange={(e) => setValueToken(e.target.value)}
    variant="outlined"
    color="primary"
    InputProps={{
              onKeyPress: handleTokenKeyPress,
              ref,
              value: valueToken,
              sx: { width: 200 },
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment sx={{ m: 0 }} position="end">
                  <TokenImage token={info?.token} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <InputSubmitButton variant="contained" onClick={handleTokenEnterClick}>
                    <InlineMiddleFlex>
                      <EnterIcon />
                      {' '}
                      Enter
                    </InlineMiddleFlex>
                  </InputSubmitButton>
                </InputAdornment>
              ),
            }}
  />
)}

          <Text>+</Text>

          {(!editQuoteToken) ? (
            <PrincipalTokenAmountDisplay
              loading={!isReady}
              token={info?.quoteToken}
              priceRatio={data?.quoteTokenPrice}
              percent={0.5}
              amount={data?.quoteTokenNum}
              edit={editQuoteToken}
              onClick={() => {
              setEditQuoteToken(true);
              setEdit(false);
              setEditToken(false);
              }}
            />
) : (
  <StyledTextField
    onChange={(e) => setValueQuoteToken(e.target.value)}
    variant="outlined"
    color="primary"
    InputProps={{
                      onKeyPress: handleQuoteTokenKeyPress,
                      ref,
                      value: valueQuoteToken,
                      sx: { width: 200 },
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment sx={{ m: 0 }} position="end">
                          <TokenImage token={info?.quoteToken} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <InputSubmitButton variant="contained" onClick={handleQuoteTokenEnterClick}>
                            <InlineMiddleFlex>
                              <EnterIcon />
                              {' '}
                              Enter
                            </InlineMiddleFlex>
                          </InputSubmitButton>
                        </InputAdornment>
                      ),
                    }}
  />
          )}

        </InlineMiddleFlex>
      </Grid>
    </Grid>
  );
};

const PrincipalTokenPercentageDisplay = styled(Box)(
  sx({
    background: 'rgba(234, 228, 252, 0.33)',
    borderRadius: 20,
    typography: 'small',
    fontWeight: 600,
    px: 0.5,
  }),
);

const PrincipalTokenAmountDisplay:React.FC<{
  loading: boolean,
  percent: number,
  token: TokenDto,
  amount: number,
  priceRatio: number,
  edit: boolean,
  onClick: () => void,
}> = ({
  loading,
  percent,
  token,
  amount,
  priceRatio,
  edit,
  onClick,
  ...otherProps
}) => (
  <Box {...otherProps}>
    <Text typography="small">&nbsp;</Text>
    <PrincipalDisplayBox onClick={onClick}>
      <InlineMiddleFlex>

        <TokenImage token={token} />
        {!loading
      ? (
        <Text typography="h4" bold="500">
          <b>{formatTokenAmount(amount)}</b>
          {' '}
          {token.symbol}
          {' '}
          <IconButton size="small"><EditIcon height={20} width={20} /></IconButton>
        </Text>
)
      : <Skeleton width={130} />}
      </InlineMiddleFlex>
    </PrincipalDisplayBox>

    <InlineMiddleFlex>
      {!loading
      ? (
        <>
          <PrincipalTokenPercentageDisplay>
            {formatPercent(percent)}
          </PrincipalTokenPercentageDisplay>
          <Text typography="small">
            1
            {' '}
            {token.symbol}
            {' '}
            = $
            {' '}
            {formatNumberLong(priceRatio, 0, 4)}
          </Text>
        </>
)
      : <Skeleton width={130} />}
    </InlineMiddleFlex>
  </Box>
);

const TimeMachineTimeTunnelInputs:React.FC<{info: TokenInfoDto}> = ({
  info,
  ...props
}) => (
  <>
    <Grid container columnGap={5} rowGap={5}>
      <Grid item>
        <LpSupplyInput />
      </Grid>
      <Grid item>
        <PrincipalInput info={info} />
      </Grid>
    </Grid>
  </>
);

const DateRangePickerToggleItem = () => {
  const { info } = useDetailPageContext();
  const { field } = useController({ name: 'customRange' });
  const [open, setOpen] = useState(false);
  const handleItemClick = (e) => {
    if (!open) {
      e.stopPropagation();
      setOpen(true);
    }
  };
  return (
    <ToggleButton
      key="custom"
      value="custom"
      onClick={handleItemClick}
      sx={{
      '& > *': {
        pointerEvents: 'none',
        userSelect: 'none',
      },
    }}
    >
      <CustomDateRangePicker
        minDate={dayjs(info.dateMinMax.minDate)}
        maxDate={dayjs(info.dateMinMax.maxDate)}
        value={field.value}
        open={open}
        onClose={() => { setOpen(false); }}
        onChange={field.onChange}
      />
    </ToggleButton>
);
};

const StyledDateRangePicker = styled(DateRangePicker)(
  sx({
    '& .PrivateDateRangePickerToolbar-penIcon': {
      display: 'none',
    },
  }),
);

const CustomDateRangePicker = ({
  minDate,
  maxDate,
  value,
  open,
  onClose,
  onChange,
}) => (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <StyledDateRangePicker
      onClose={onClose}
      value={value}
      open={open}
      calendars={1}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(startProps, endProps) => <CalenderIcon />}
    />
  </LocalizationProvider>
);

const InputLabel:React.FC<{label: string}> = ({
  label,
  children,
  ...otherProps
}) => (
  <Box {...otherProps}>
    <Text sx={{ mb: 1 }} typography="small" bold color="greyColor.dark">{label}</Text>
    {children}
  </Box>
);

const NumberFormatCustom = React.forwardRef<{}, {name: string, onChange: any}>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
});

export default TimeMachineTimeTunnelFilter;
