import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { experimental_sx as sx } from '@mui/system';

export const RadiusButtonToggleButtonGroup = styled(ToggleButtonGroup)(
  sx<Theme>({
    '&.MuiToggleButtonGroup-root': {
      gap: 2,
      flexWrap: 'wrap',
    },
    '& .MuiToggleButtonGroup-grouped': {
      bgcolor: '#F6F9FC',
      color: 'greyColor.main',
      fontWeight: 700,
      margin: 0.5,
      border: 0,
      gap: 3,
      textTransform: 'unset',
      "& svg": {
        fill: (theme) => theme.palette.text.secondary,
      },
      '&.Mui-selected': {
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        "& svg": {
          fill: (theme) => theme.palette.primary.main,
        }
      },
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: 20,
      },
      '&:first-of-type': {
        borderRadius: 20,
      },
    },
    '& .MuiButtonBase-root': {
      m:0,
      py:0.5,
      px: 2,
    }
  })
)