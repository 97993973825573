import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useDetailPageContext } from '../context';

export type FarmFilterFormFields = {
  period: '1M' | '3M' | '6M' | 'ytd' | '1y' | 'max' | 'custom',
  customStartDate: string,
  customEndDate: string,
  startDate: Date,
  endDate: Date,
  principal: number,
}

export const useFarmFilterForm = () => {
  const { info } = useDetailPageContext();
  const form = useForm({
    defaultValues: {
      period: '7d',
      customRange: [dayjs(info.dateMinMax.minDate), dayjs(info.dateMinMax.maxDate)],
      startDate: dayjs().startOf('d').format('YYYY-MM-DD'),
      endDate: dayjs().startOf('d').subtract(3, 'd').format('YYYY-MM-DD'), // for debug
      principal: 10000,
    },
  });
  const hour = dayjs().hour();
  const { watch } = form;
  const period = watch('period');
  const customRange = watch('customRange');
  useEffect(() => {
    let startDate; let
endDate;
    const today = dayjs(info.dateMinMax.maxDate);
    switch (period) {
      case 'ytd':
        startDate = today.startOf('y');
        endDate = today;
        break;
      case 'max':
        startDate = dayjs(info.dateMinMax.minDate);
        endDate = today;
        break;
      case 'custom':
       { const [start, end] = form.getValues('customRange');
        startDate = start || dayjs(info.dateMinMax.minDate);
        endDate = end || dayjs(info.dateMinMax.maxDate);
        break; }
      default:
        { const match = String(period).match(/(\d+)(\w+)/);
        const todayToMinDate = dayjs().diff(info.dateMinMax.minDate, 'd');
        const selectedDayRange = parseInt(match[1]);
        if (match) {
          if (selectedDayRange > todayToMinDate) {
            startDate = dayjs(info.dateMinMax.minDate);
          } else { startDate = today.subtract(Number.parseFloat(match[1]), match[2]); }
        }
        endDate = today; }
    }
    form.setValue('startDate', (startDate) ? startDate.startOf('d').hour(hour).format() : null);
    form.setValue('endDate', (endDate) ? endDate.startOf('d').hour(hour).format() : null);
  }, [period, customRange]);
  return form;
};
