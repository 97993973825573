import { Chain } from 'state/types';

const blocksPerYear: number = (secondsPerBlock: number) => (60 / secondsPerBlock) * 60 * 24 * 365;

export const Chains : Record<number, Chain> = {
  42161: {
    name: 'Arbitrum',
    symbol: 'AETH',
    chainId: 42161,
    rpcUrls: [
      'https://arb1.arbitrum.io/rpc',
    ],
  },
  43113: {
    name: 'Avalanche FUJI Testnet',
    symbol: 'AVAX',
    chainId: 43113,
    rpcUrls: [
      'https://api.avax-test.network/ext/bc/C/rpc',
    ],
    blocksPerYear: blocksPerYear(3),
  },
  43114: {
    name: 'Avalanche',
    symbol: 'AVAX',
    chainId: 43114,
    rpcUrls: [
      'https://api.avax.network/ext/bc/C/rpc',
    ],
  },
  56: {
    name: 'Binance Smart Chain',
    symbol: 'BSC',
    chainId: 56,
    rpcUrls: [
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed.binance.org',
    ],
  },
};

export default Chains;
