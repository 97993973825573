import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, styled } from '@mui/system';
import _ from 'lodash';
import { experimental_sx as sx } from '@mui/system';
import { Skeleton, TableSortLabel } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { alpha } from "@mui/lab/node_modules/@mui/system";
import { Text } from './Text';
import PerfectScrollbar from 'react-perfect-scrollbar'

export type SimpleFarmSort = {
  id: string,
  direction: 'asc' | 'desc',
}
export type SimpleFarmPagination = {
  page: number,
  pageSize: number,
}

export type SimpleFarmActions = {
  sort: SimpleFarmSort,
  pagination: SimpleFarmPagination
} 

const ScrollBar = styled(PerfectScrollbar, {shouldForwardProp: prop => prop !== 'maxWidth' && prop !== 'maxHeight'})<{
  maxWidth: number | string,
  maxHeight: number | string
}>( (props) => 
  sx({
    "& .ps__rail-y":{
      zIndex: 999,
    },
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  })
)

export const SimpleTable:React.FC<{
  scroll?: {x?: string | number, y?: string | number}
  loading?: boolean,
  total: number,
  pagination: SimpleFarmPagination,
  sort: SimpleFarmSort,
  columns: any[],
  data: any[],
  expandedRowRender?: (item, tableOpt) => any,
  expandAll?: boolean | Boolean,
  onChange: (actions: SimpleFarmActions) => void,
  [key: string]: any,
}> = ({
  scroll = {},
  loading = false,
  total,
  columns,
  data = [],
  expandedRowRender,
  expandAll,
  onChange,
  onRowClick,
  sort,
  pagination: defaultPagination,
  ...otherProps
}) => {
  // const [sort, setSort] = React.useState<SimpleFarmSort>(defaultSort);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [pagination, setPagination] = React.useState<SimpleFarmPagination>(defaultPagination);
  const {page, pageSize: rowsPerPage} = pagination;

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      page: 0,
      pageSize: +event.target.value,
    })
  };

  const handleSortClick = (id, directions) => {
    let dir = null;
    if(!sort || sort.id !== id){
      dir = directions[0];
    }else{
      let idx = _.findIndex(directions, v => v == sort.direction);
      dir = _.get(directions, idx + 1, null);
      if(dir === null){
        dir = null;
      }
    }
    if(dir === null){
      onChange({
        sort: null,
        pagination,
      })
    }else{
      onChange({
        sort: {
          id: id,
          direction: dir,
        },
        pagination,
      })
    }
  }

  React.useEffect(() => {
    if(typeof onChange === 'function'){
      onChange({
        sort,
        pagination,
      });
    }
  }, [pagination, sort])

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return <Box {...otherProps}>
        <ScrollBar maxWidth={scroll.x} maxHeight={scroll.y} >
          <Table sx={{
            "& .MuiTableRow-root.hover":{
              background: (theme) => alpha("#EAE4FC", 0.66),
            }
          }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align as any}
                    style={{ minWidth: column.minWidth, zIndex: 99 }}
                  >
                    {
                      column.sort
                      ? 
                        <TableSortLabel
                          sx={{typography: 'small'}}
                          active={sort && column.sort == sort.id}
                          direction={sort && sort.id == column.sort && sort.direction?sort.direction:_.get(column, "sortDirections.0")}
                          onClick={() => handleSortClick(column.sort, _.get(column, "sortDirections"))}
                        >
                          {column.label}
                        </TableSortLabel>
                      :
                        <Text sx={{userSelect: 'none'}} typography='small'>{column.label}</Text>
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading 
              ?
                <TableRow>
                  <TableCell sx={{p:0, m:0}} colSpan={columns.length}>
                    <Skeleton variant="rectangular" height={400} />
                  </TableCell>
                </TableRow>
              :
                data.map((row) => 
                  <SimpleTableRow 
                    onClick={onRowClick}
                    key={row.key}
                    columns={columns}
                    row={row}
                    expandedRowRender={expandedRowRender}
                    expandAll={expandAll}
                  />)
              }
            </TableBody>
          </Table>
        </ScrollBar>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page ?? 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
  </Box>

}

const SimpleTableRow = ({row, columns, expandedRowRender, expandAll, onClick}) => {
  const [hover, setHover] = React.useState(false);
  const [expandableRowOpen, setExpandableRowOpen] = React.useState(expandAll.valueOf());
  const toggleExpandableRowOpen = () => setExpandableRowOpen(!expandableRowOpen);
  const tableOpt = {columns, expandableRowOpen, setExpandableRowOpen, toggleExpandableRowOpen};
  const handleClick = typeof onClick === 'function' ? () => onClick(row) : null;
  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };
  React.useEffect(() => {
    if(expandAll !== null){
      setExpandableRowOpen(expandAll.valueOf());
    }
  }, [expandAll]);
  return <>
    <TableRow 
      className={hover ? 'hover' : ''}
      role="checkbox"
      tabIndex={-1}
      key={row.key}
      onClick={handleClick}
      sx={[onClick && {cursor: 'pointer'}]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {columns.map((column) => {
        const value = _.get(row, column.id);
        const handleClick = typeof column.onClick === 'function' ? () => column.onClick(row) : null ;
        return (
          <TableCell onClick={handleClick} sx={[column.onClick && {cursor: 'pointer'} , expandedRowRender && {borderBottom: 'unset'}]} key={column.id} align={column.align as any}>
            {column.render
              ? column.render(value, row, tableOpt)
              : value}
          </TableCell>
        );
      })}
    </TableRow>
    {expandedRowRender && 
    <TableRow 
      className={hover ? 'hover' : ''}
      key={row.key + "-expand"}
      onClick={handleClick}
      sx={[onClick && {cursor: 'pointer'}]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {expandedRowRender(row, tableOpt)}
    </TableRow>
    }
  </>;
}