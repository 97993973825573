import React, { ReactElement, useState } from 'react';
import { styled } from '@mui/material/styles';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Zoom from '@mui/material/Zoom';
import InfoPopover from './InfoPopover';
import { itemDescriptions } from 'config/constants/itemDescriptions';
import { Box, experimental_sx as sx, SxProps } from '@mui/system';
import { Paper } from '@mui/material';
import _ from 'lodash';

type InfoDescriptionPopoverProps = {
  sx?: SxProps,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  description: string,
  render?: any,
}

const StyledContainer = styled(Paper)(
  sx({
    maxWidth: 400,
    m: 1,
    borderRadius: 4,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    py: 1,
    px: 2,
    typography: 'body2',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
    "& > *": {
      my: 1,
    }
  })
)

const InfoDescriptionPopover:React.FC<InfoDescriptionPopoverProps> = ({
  description, children, ...otherProps
}) => {
  return <InfoPopover
  component={children}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
  {...otherProps}>
    <StyledContainer>
      {_.get(itemDescriptions, description, description)}
    </StyledContainer>
  </InfoPopover>
}

export default InfoDescriptionPopover;