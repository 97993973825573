import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from '../lib/format'
import _ from 'lodash';
// import getConfig from 'next/config'
// const { publicRuntimeConfig } = getConfig()

/**
 * getEnv method for uat / production env to dynamic get config 
 * after build docker image with npm run build only env start with REACT_APP_ will be added
 */
export const getEnv = () => {
  return Object.assign(
    _.pickBy(process.env, function(value, key) {
      return _.startsWith(key, "REACT_APP_");
    }),
    globalThis._env_,
  )
}
export const isDev = () => {
  return _.get(getEnv(), 'REACT_APP_DEV') === 'true';
}

// // TODO: safe for printenv / remove after test env in production
// console.log('ENV:' , getEnv())

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 10 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeStats.tsx = 20 (40 - Amount sent to burn pool)


export const CAKE_PER_BLOCK = new BigNumber(40)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK.times(BLOCKS_PER_YEAR)


// export const BASE_URL = 'https://pancakeswap.finance'
// export const BASE_EXCHANGE_URL = 'https://exchange.pancakeswap.finance'
// export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
// export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
// export const BASE_BSC_SCAN_URL = 'https://bscscan.com'
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const DEFAULT_GAS_PRICE = 5
export const TESTNET_CHAIN_ID = '97'
export const MAINNET_CHAIN_ID = '56'


export const BSC_CHAIN_ID = getEnv().REACT_APP_BSC_CHAIN_ID? getEnv().REACT_APP_BSC_CHAIN_ID : MAINNET_CHAIN_ID;
// export const BSC_RPC_URL = process.env.REACT_APP_BSC_RPC_URL;


export const REWARD_TOKEN = 'ALPACA'
// export const REWARD_TOKEN = 'SINGLE'