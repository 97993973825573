import _ from 'lodash';
import { formatUnits } from 'ethers/lib/utils';

import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

class CustomNumberFormat extends Intl.NumberFormat {
  format(number, defaultValue = null) {
    const parseNum = Number.parseFloat(number);
    if (!_.isFinite(parseNum)) {
      return (defaultValue !== null) ? defaultValue : '';
    }
    return super.format(number);
  }
}

export const NumberFormat = new CustomNumberFormat('en-US', { maximumFractionDigits: 4 });
export const NumberShortFormat = new CustomNumberFormat('en-US', { maximumFractionDigits: 2 });
export const PercentFormat = new CustomNumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 });
export const CurrencyFormat = new CustomNumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 4 });
export const FundingRateFormat = new CustomNumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4 });
export const InvestmentUtilizationPercentFormat = new CustomNumberFormat('en-US', { style: 'percent', maximumFractionDigits: 0 });

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_NINE = new BigNumber(9);
export const BIG_TEN = new BigNumber(10);

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => new BigNumber(amount).times(BIG_TEN.pow(decimals));

/**
 * Convert to display amount
 * @param amount
 * @param decimals
 * @returns
 */
export const getBalanceAmount = (amount: BigNumber, decimals = 18) => new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));

export const getGasPriceInWei = (amountInGwei: number) => getDecimalAmount(new BigNumber(amountInGwei), 9);

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => getBalanceAmount(balance, decimals).toNumber();

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, decimalsToAppear?: number) => getBalanceAmount(balance, decimals).toFixed(decimalsToAppear);

/**
 * convert to ethers bignumber without power up or down
 * @param num
 * @returns
 */
export const convertEtherBigNumer = (num: BigNumber) => ethers.utils.parseUnits(num.toFixed(), 0);

export const convertEtherBigNumerFromBalance = (num: BigNumber) => ethers.utils.parseUnits(num.toFixed(), 18);

export const customisedCurrencyFormat = (number, minNum = 2, maxNum = 2) => {
  const format = new Intl.NumberFormat('en-US', {
 style: 'currency', currency: 'USD', minimumFractionDigits: minNum, maximumFractionDigits: maxNum,
});
  return format.format(number);
};

export const customisedNumberFormat = (number, minNum = 2, maxNum = 2) => {
  const format = new Intl.NumberFormat('en-US', { minimumFractionDigits: minNum, maximumFractionDigits: maxNum });
  return format.format(number);
};

export const customisedPercentFormat = (number, minNum = 2, maxNum = 2) => {
  const format = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: minNum, maximumFractionDigits: maxNum });
  return format.format(number);
};

export const formatNumber = (number: number, minPrecision = 0, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  };
  const parseNum = Number.parseFloat(String(number));
  if (!_.isFinite(parseNum)) {
    return '';
  }
  return parseNum.toLocaleString(undefined, options);
};

// Token with small value can show price value instead of 0 USD, i.e. 1 VVS = $0.0000237
export const formatNumberLong = (number: number, minPrecision = 0, maxPrecision = 2) => parseFloat(number.toPrecision(maxPrecision));

function stringNumberFormatToPrecision(numberStringFormat) {
  if (numberStringFormat) {
    const expo = Number.parseFloat(numberStringFormat).toExponential();
    let regex;
    // eslint-disable-next-line no-cond-assign
    if (regex = expo.match(/e([+-]\d+)/)) {
      return regex[1] * 1;
    }
  }
  return 0;
}

export const formatPercent = (number: number | string | BigNumber, minPrecision = 0, maxPrecision = 2) => {
  const options = {
    // minimumFractionDigits: minPrecision,
    maximumSignificantDigits: 3,
    // maximumFractionDigits: maxPrecision,
    style: 'percent',

  };
  const strNumber = String(number);
  const parseNum = Number.parseFloat(strNumber);
  const length = stringNumberFormatToPrecision(strNumber);
  let extraOpt = {};
  if (length > 14) {
    extraOpt = {
      notation: 'engineering',
      compactDisplay: 'short',
    };
  } else if (length > 6) {
    extraOpt = {
      notation: 'compact',
      compactDisplay: 'short',
    };
  }
  return parseNum.toLocaleString(undefined, { ...options, ...extraOpt });
};

export const formatPercentDetailed = (number: number | string | BigNumber, defaultValue?: number | string, opts?: Intl.NumberFormatOptions) => {
  const options = {
    style: 'percent',
    maximumFractionDigits: 3,
    ...(opts ?? {}),
  };
  const parseNum = Number.parseFloat(String(number));
  if (!_.isFinite(parseNum)) {
    return (defaultValue) || '';
  }
  return parseNum.toLocaleString('en-US', options);
};

export const formatTokenAmount = (number: number | string | BigNumber, defaultValue?: number | string, opts?: Intl.NumberFormatOptions): string => {
  opts = opts ?? {};
  const parseNum = Number.parseFloat(String(number));
  let extraOpt = {};
  if (parseNum >= 100000) {
    extraOpt = {
      notation: 'compact',
      compactDisplay: 'short',
    };
  }
  const options = {
    maximumSignificantDigits: 6,
    ...extraOpt,
    ...opts,
  };
  if (!_.isFinite(parseNum)) {
    return String(defaultValue) || '';
  }
  return parseNum.toLocaleString('en-US', options);
};

export const formatTrendAmount = (number: number | string | BigNumber, defaultValue?: number | string, opts?: Intl.NumberFormatOptions) => {
  opts = opts ?? {};
  const options = {
    signDisplay: 'exceptZero',
    currency: 'USD',
    maximumFractionDigits: 0,
    ...opts,
  };
  const parseNum = Number.parseFloat(String(number));
  if (!_.isFinite(parseNum)) {
    return (defaultValue) || '';
  }
  return parseNum.toLocaleString('en-US', options as any);
};

export const formatPrice = (number: number | string | BigNumber, defaultValue?: number | string, opts?: Intl.NumberFormatOptions) => {
  const parseNum = Number.parseFloat(String(number));
  let extraOpt = {};
  if (parseNum >= 100000) {
    extraOpt = {
      notation: 'compact',
      compactDisplay: 'short',
    };
  }
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    maximumSignificantDigits: 4,
    ...extraOpt,
    ...(opts ?? {}),
  };
  if (!_.isFinite(parseNum)) {
    return (defaultValue) || '';
  }
  return _.round(parseNum, options.maximumFractionDigits).toLocaleString('en-US', options);
};

export const formatPriceLong = (number: number | string | BigNumber, defaultValue?: number | string, opts?: Intl.NumberFormatOptions) => {
  const parseNum = Number.parseFloat(String(number));
  const extraOpt = {};
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...extraOpt,
    ...(opts ?? {}),
  };
  if (!_.isFinite(parseNum)) {
    return (defaultValue) || '';
  }
  return _.round(parseNum, options.maximumFractionDigits).toLocaleString('en-US', options);
};

export const formatPriceCurrency = (number: number | string | BigNumber,
  defaultValue?: number | string, opts?: Intl.NumberFormatOptions) => {
  const parseNum = Number.parseFloat(String(number));
  let extraOpt = {};
  if (parseNum >= 100000) {
    extraOpt = {
      notation: 'compact',
      compactDisplay: 'short',
    };
  }
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    maximumSignificantDigits: 4,
    ...extraOpt,
    ...(opts ?? {}),
  };
  if (!_.isFinite(parseNum)) {
    return (defaultValue) ? `$ ${defaultValue}` : '';
  }
  return `${number < 0 ? '-' : ''}${Math.abs(_.round(parseNum, options.maximumFractionDigits)).toLocaleString('en-US', options)}`;
};

// /**
//  * Method to format the display of wei given an ethers.BigNumber object
//  * Note: does NOT round
//  */
// export const formatBigNumber = (number: ethers.BigNumber | BigNumber, displayDecimals = 18, decimals = 18) => {
//   const remainder = number.mod(ethers.BigNumber.from(10).pow(decimals - displayDecimals));
//   return formatUnits(number.sub(remainder), decimals);
// };

// /**
//  * Method to format the display of wei given an ethers.BigNumber object with toFixed
//  * Note: rounds
//  */
// export const formatBigNumberToFixed = (number: ethers.BigNumber | BigNumber, displayDecimals = 18, decimals = 18) => {
//   const formattedString = formatUnits(number, decimals);
//   return (+formattedString).toFixed(displayDecimals);
// };

// /**
//  * Formats a FixedNumber like BigNumber
//  * i.e. Formats 9763410526137450427.1196 into 9.763 (3 display decimals)
//  */
// export const formatFixedNumber = (number: ethers.FixedNumber, displayDecimals = 18, decimals = 18) => {
//   // Remove decimal
//   const [leftSide] = number.toString().split('.');
//   return formatBigNumber(ethers.BigNumber.from(leftSide), displayDecimals, decimals);
// };

export const ethersToBigNumber = (ethersNumber: ethers.BigNumber) => new BigNumber(ethersNumber.toString());
