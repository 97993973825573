import React from 'react';
import { SerializedToken } from 'config/constants/types';
import SymbolImage from './SymbolImage';
import { getImageUrlFromToken, getImageUrlFromTokenDto } from './helpers';
import { styled } from '@mui/material/styles';
import { ImageProps } from './types';
import { TokenDto } from '@iddy/time-machine';

interface TokenImageProps {
  type?: 'tokens' | 'dex' | 'chain';
  solid?: boolean,
  disable?: boolean,
  token: string | TokenDto;
  width?: number;
  height?: number;
}


const TokenImage: React.FC<TokenImageProps> = ({
  type, token, width = 20, height = 20, solid, disable, ...props
}) => (
  <SymbolImage
    className = "icon"
    width={width}
    height={height}
    src={typeof token === 'object' ? getImageUrlFromTokenDto(token) : getImageUrlFromToken(token, type, solid, disable)}
    {...props}
  />
);

export default TokenImage;
