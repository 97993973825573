import { styled } from '@mui/material/styles';
import Image from './Image';
import images from 'assets/images'

const SymbolImage = styled(Image)`
  // border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  &::before {
    // border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index:7;
  }
`;

SymbolImage.defaultProps = {
  onError: ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.querySelector('img').src= images.missingTokenImg;
  }
}

export default SymbolImage;
