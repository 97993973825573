import { InitialCoinNumResult } from '@iddy/time-machine';
import dayjs from 'dayjs';
import { usePoolApi } from 'lib/api';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export const usePrincipalSpiltCoin = (poolId) => {
  const { watch } = useFormContext();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<InitialCoinNumResult>();
  const api = usePoolApi();
  const [principal, startDate, endDate, tokenNum, quoteTokenNum] = watch(
    ['principal', 'startDate', 'endDate', 'token', 'quoteToken'],
);
const { field: fieldPrincipal } = useController({ name: 'principal' });

  // useEffect)
  const fetch = async ({
    poolId,
    principal,
    fromDate,
    toDate,
    tokenNum,
    quoteTokenNum,
  }) => {
    setIsReady(false);
    const period:number = dayjs(toDate).diff(dayjs(fromDate), 'day');
    let result;
if (principal !== null) {
   result = await api.getInitialCoinNum(poolId, String(period), toDate, principal, null, null);
}
if (tokenNum !== null) {
   result = await api.getInitialCoinNum(poolId, String(period), toDate, null, tokenNum, null);
  fieldPrincipal.onChange(result.data.result.principal);
}

if (quoteTokenNum !== null) {
   result = await api.getInitialCoinNum(poolId, String(period), toDate, null, null, quoteTokenNum);
  fieldPrincipal.onChange(result.data.result.principal);
}

    setData(result.data.result);

    setIsReady(true);
  };

  useEffect(() => {
    if (startDate
      && endDate && poolId && dayjs(endDate).diff(dayjs(startDate), 'day') > 0) {
      // split scenario for case with tokenNum or quoteTokenNum or principal onChange
      if (tokenNum) {
          fetch({
            poolId, principal: null, fromDate: startDate, toDate: endDate, tokenNum, quoteTokenNum: null,
      });
    } else if (quoteTokenNum) {
      fetch({
        poolId, principal: null, fromDate: startDate, toDate: endDate, tokenNum: null, quoteTokenNum,
});
     } else if (principal) {
  fetch({
    poolId, principal, fromDate: startDate, toDate: endDate, tokenNum: null, quoteTokenNum: null,
});
}
      }
  }, [principal, startDate, endDate, poolId, tokenNum, quoteTokenNum]);

  return {
    isReady,
    data,
    fetch,
  };
};
