import { FarmSummaryData } from '@iddy/time-machine';
import dayjs from 'dayjs';
import { useSimulatePoolApi } from 'lib/api';
import { useState } from 'react';

export const useFarmSummary = () => {
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<FarmSummaryData[]>();
  const api = useSimulatePoolApi();
  const fetch = async ({
    poolId,
  }) => {
    setIsReady(false);
    const toDate = dayjs().format('YYYY-MM-DD');
    const result = await api.summary(poolId, toDate);

    setData(result.data.result);
    setIsReady(true);
  };

  return {
    isReady,
    data,
    fetch,
  };
};
