import _ from 'lodash';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers';

class CustomNumberFormat extends Intl.NumberFormat{
  format(number, defaultValue = null){
    let parseNum = Number.parseFloat(number);
    if(!_.isFinite(parseNum)){
      return (defaultValue !== null)?defaultValue:'';
    }
    return super.format(number);
  }
}

// export const NumberFormat = new CustomNumberFormat('en-US', {maximumFractionDigits: 4});
export const NumberShortFormat = new CustomNumberFormat('en-US', {maximumFractionDigits: 2});
export const PercentFormat = new CustomNumberFormat('en-US', {style: 'percent', maximumFractionDigits: 2});
export const CurrencyShortFormat = new CustomNumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0});
export const TrendAmountFormat = new CustomNumberFormat('en-US', {  signDisplay: "exceptZero", currency: 'USD', maximumFractionDigits: 0});
// export const FundingRateFormat = new CustomNumberFormat('en-US', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4});




export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)


/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

/**
 * Convert to display amount
 * @param amount 
 * @param decimals 
 * @returns 
 */
export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}


export const getGasPriceInWei = (amountInGwei: number) => {
    return getDecimalAmount(new BigNumber(amountInGwei), 9)
}


/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
    return getBalanceAmount(balance, decimals).toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, decimalsToAppear?: number) => {
  if(typeof decimalsToAppear !== "undefined"){
    return getBalanceAmount(balance, decimals).toFixed(decimalsToAppear)
  }else{
    return getBalanceAmount(balance, decimals).toFixed()
  }
}


/**
 * convert to ethers bignumber without power up or down
 * @param num 
 * @returns 
 */
export const convertEtherBigNumer = (num: BigNumber) => 
{
  return ethers.utils.parseUnits(num.toFixed(), 0);
}


export const convertEtherBigNumerFromBalance = (num: BigNumber) => 
{
  return ethers.utils.parseUnits(num.toFixed(), 18);
}


export function formatWalletAddressDisplay(wallet: string)
{
  if(wallet != null && wallet != ""){
    return wallet.substr(0, 4) + '...'+ wallet.substr(-4) 
  }else{
    return null;
  }
}

var ranges = [
  { divider: 1e9 , suffix: 'B' },
  { divider: 1e6 , suffix: 'M' },
  { divider: 1e3 , suffix: 'k' }
];

export function formatNumber(n) {
  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) return NumberShortFormat.format(n / ranges[i].divider) + ranges[i].suffix;
  }
  return n.toString();
}

export function filterTimeData(time: string, unfilteredData: any[]): any[] {
  if (time == "ALL"){
    return unfilteredData
  } else if (time == "YTD") {
    let currentYear = dayjs().get('year')
    return _.filter(unfilteredData, item => dayjs(item.query_ts).get('year') >= currentYear)
  } else if (time.substring(1) == "m"){
    let month: number = Number(time.substring(0, 1))
    let startDate = dayjs().subtract(month, 'month')
    return _.filter(unfilteredData, item => dayjs(item.query_ts) >= startDate)

  } else if (time.substring(1) == "y"){
    let year: number = Number(time.substring(0, 1))
    let startDate = dayjs().subtract(year, 'year')
    return _.filter(unfilteredData, item => dayjs(item.query_ts) >= startDate)
  }
}
