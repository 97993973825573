import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend
} from 'bizcharts';
import React, { useMemo } from 'react';
import { formatPercent, formatPrice, formatPriceCurrency, formatPriceLong } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled } from '@mui/system';
import { experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { Divider, useTheme } from '@mui/material';
import TimeMachineTimeTunnelShareGraph from './TimeMachineTimeTunnelShareGraph';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text, TrendText } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';

const TimeMachineTimeTunnelShareTooltipItem:React.FC<{
  color: string,
  name: string,
  value: number,
  view: string,
  percentage: number,
}> = ({
  color,
  name,
  value,
  view,
  percentage,
}) => (
  <InlineMiddleFlex sx={{ typography: 'body2', my:1 }} justifyContent={"space-between"}>
    <Box key={name}>
      <InlineMiddleFlex>
        <CircleIcon background={color}/>
        <Text>{name}</Text>
      </InlineMiddleFlex>
    </Box>
    <Text bold>
      {view === 'roi' 
      ?
        <>
          {formatPriceLong(value, '', {maximumFractionDigits: 2})}&nbsp;
          ( <TrendText component={'span'} value={percentage}>{formatPercent(percentage)}</TrendText> )
        </>
      :
        <TrendText component={'span'} value={percentage}>{formatPercent(percentage)}</TrendText> 
      }
    </Text>
  </InlineMiddleFlex>
)

export default TimeMachineTimeTunnelShareTooltipItem;