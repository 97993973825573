import { generatePath } from 'react-router-dom';
import { timeMachineUrlByType, timeMachineUrlByDex } from 'pages/TimeMachine/constants';
import { timeMachineDetailFullUrl } from 'pages/TimeMachineDetail/constants';

export const getDiscoverLink = () => generatePath(timeMachineUrlByType, {
  type: "discover",
})

export const getNewPoolLink = () => generatePath(timeMachineUrlByType, {
  type: "latest",
})

export const getDiscoverLinkByChain = (chain: string) => generatePath(timeMachineUrlByDex, {
  type: 'chain',
  chain: chain,
})

export const getDetailFullLink = (chain: string, exchange: string, lpAddress: string | number, lpSymbolName: string) => generatePath(timeMachineDetailFullUrl, {
  chain,
  exchange,
  id: lpAddress,
  name: String(lpSymbolName).replace(/\s/g, '-'),
})