import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend
} from 'bizcharts';
import React, { useMemo } from 'react';
import { formatPercent, formatPrice, formatPriceCurrency, formatPriceLong } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled } from '@mui/system';
import { experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { Divider, Grid, Paper, useTheme } from '@mui/material';
import TimeMachineTimeTunnelShareGraph from './TimeMachineTimeTunnelShareGraph';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text, TrendText } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';
import { keyframes } from "@emotion/react";
import images from 'assets/images';

const loadingEffect = keyframes`
  0%{
    transform: scale(1, 1)
  }
  50%{
    transform: scale(1, 0.5)
  }
  100%{
    transform: scale(1, 1)
  }
`;



const StyledContainer = styled(Grid)(
  sx({
    height: 600,
  })
)

const LoadingImageBox = styled(Box)(
  sx({
    animation: `${loadingEffect} 3000ms ease`,
    animationIterationCount: 'infinite',
    position:'relative',
  })
)

const TimeMachineLoadingScreen:React.FC<{
}> = ({
  ...otherPros
}) => (
  <StyledContainer container alignItems={'center'} justifyContent={'center'}>
    <Box>
      <LoadingImageBox>
        <img src={images.loadingWaveBg} />
      </LoadingImageBox>
      <Text sx={{mt: 1}} typography='small' align='center' bold="500">
        Traveling to the past...
      </Text>
    </Box>
  </StyledContainer>
)

export default TimeMachineLoadingScreen;