import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend
} from 'bizcharts';
import React, { useMemo } from 'react';
import { formatPercent, formatPrice, formatPriceCurrency, formatPriceLong } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled } from '@mui/system';
import { experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { Divider, Paper, useTheme } from '@mui/material';
import TimeMachineTimeTunnelShareGraph from './TimeMachineTimeTunnelShareGraph';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text, TrendText } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';

const Header = styled(Box)(
  sx({
    py: 2,
    px: 3,
    borderBottom: "1px solid #DADCDE",
  })
)

const StyledContainer = styled(Paper)(
  {
    height: '100%',
    width: '100%',
  }
)

const HistoricalCardPaper:React.FC<{
  title: string
}> = ({
  title,
  children,
  ...otherPros
}) => (
  <StyledContainer {...otherPros}>
    <Header sx={{py: 2, px: 3,}}>
      <Text bold>{title}</Text>
    </Header>
    {children}
  </StyledContainer >
)

export default HistoricalCardPaper;