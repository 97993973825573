import { Divider, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
    Tooltip,
} from 'bizcharts';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import TimeMachineTimeTunnelShareTooltipItem from './TimeMachineTimeTunnelShareTooltipItem';
import TimeMachineTimeTunnelShareTooltipCoinDetail from './TimeMachineTimeTunnelShareTooltipCoinDetail';
import { useDetailPageContext } from '../context';

  const GraphRoiTooltips:React.FC<{
    lossView: string,
    fields: {[key: string]: any},
    tokenData:{type: string, valueToken: number, time: string, percentageToken: number}[],
    show: (x, y) => boolean,
    [key: string]: any,
  }> = ({
    tokenData,
    lossView,
    fields,
    show,
    ...otherProps
  }) => {
    const theme = useTheme();
    const { info } = useDetailPageContext();

    return (
      <Tooltip
        {...otherProps}
        container="div"
        domStyles={{
            'g2-tooltip': {
            borderRadius: '20px',
            background: theme.palette.background.paper,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
            },
        }}
      >
        {(title, items, x, y) => {
        const uItems = _.uniqBy(items, 'name');
        const time = _.get(uItems, '0.data.time');
        const tokenDataFilterByTime = _.filter(tokenData, (token) => token.time === time);
        const tokenPrice = _.find(tokenDataFilterByTime, (token) => token.type === 'tokenPrice');
        const quoteTokenPrice = _.find(tokenDataFilterByTime, (token) => token.type === 'quoteTokenPrice');
        // const rewardPrice = _.find(tokenDataFilterByTime, (token) => token.type === 'rewardPrice');

        if (!show(x, y)) {
            return null;
        }
        return (
          <>
            <CustomMuiThemeProvider>
              <Box sx={{
 p: 0.5, mb: 1, width: 340, typography: 'body2',
}}
              >
                <Box className="g2-tooltip-title" sx={{ mb: 0.5 }}>
                  <InlineMiddleFlex justifyContent="space-between">
                    <Text color="secondary" bold>{dayjs(time).format('YYYY-MM-DD, HH:00')}</Text>
                    <Text color="secondary" bold>USD (ROI%)</Text>
                  </InlineMiddleFlex>
                </Box>
                <Divider sx={{ my: 1 }} />
                <ul className="g2-tooltip-list">
                  {_.map(uItems, (item) => (

                    <TimeMachineTimeTunnelShareTooltipItem
                      color={_.get(fields[item.name], 'color')}
                      name={_.get(fields[item.name], 'alias')}
                      value={item.data.value}
                      percentage={item.data.percentage}
                      view="roi"
                    />

                ))}
                  <li><Divider sx={{ my: 1, borderStyle: 'dashed' }} /></li>
                  <TimeMachineTimeTunnelShareTooltipItem
                    color="primary.main"
                    name={lossView === 'priceImpact' ? 'Return incl. Price Effect' : 'Return (incl. Imp. Loss)'}
                    value={_.sumBy(uItems, 'data.value')}
                    percentage={_.sumBy(uItems, 'data.percentage')}
                    view="roi"
                  />
                </ul>
                <Divider sx={{ my: 1 }} />
                <InlineMiddleFlex justifyContent="space-around">
                  <TimeMachineTimeTunnelShareTooltipCoinDetail
                    coin={info.token.symbol}
                    coinPrice={tokenPrice.valueToken}
                    coinPercentage={tokenPrice.percentageToken}
                  />
                  <TimeMachineTimeTunnelShareTooltipCoinDetail
                    coin={info.quoteToken.symbol}
                    coinPrice={quoteTokenPrice.valueToken}
                    coinPercentage={quoteTokenPrice.percentageToken}
                  />
                  {/* <TimeMachineTimeTunnelShareTooltipCoinDetail
                    coin={info.rewardToken.symbol}
                    coinPrice={rewardPrice.valueToken}
                    coinPercentage={rewardPrice.percentageToken}
                  /> */}
                </InlineMiddleFlex>
              </Box>
            </CustomMuiThemeProvider>
          </>
);
        }}
      </Tooltip>
    );
  };

  export default GraphRoiTooltips;
