import { Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import MainContainer from 'components/MainContainer';
import { Text } from 'components/Text';
import React from 'react';
import { Link } from 'react-router-dom';
import images from 'assets/images';
import { maxWidth } from '@mui/lab/node_modules/@mui/system';
import SEO from 'components/SEO';

const Content = styled('div')`
  overflow: hidden;
  width: 100%;
  padding: 8px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0px;
  }
`;

const StyledContainer = styled(Container)(
  sx({
    pt: 20,
  }) 
)

const StyledImage = styled('img')({
  width: '100%',
  maxWidth: 300,
});

const NotFoundContent = styled(Grid)(
  sx({
    pr: 4,
    textAlign: {
      xs: 'center',
      md: 'initial',
    },
    mb: 25,
    "& > *": {
      mt: 2,
    },
  })
)

const NotFound: React.FC = () => {
  return (
      <>
        <SEO title="Not Found" />
          <MainContainer>
              <Content>
                  <StyledContainer>
                    <Grid container sx={{typography: 'body1'}} flexDirection={'row-reverse'} gap={2}>

                      <Grid item flex="1 1 300px" sx={{textAlign: 'center'}}>
                        <StyledImage src={images.notFound} />
                      </Grid>

                      <NotFoundContent item flex="1 1 400px" sx={{pr: 4,"& > *": {mt: 2}}}>
                        <Text sx={{mb: 12}} typography="h2" bold>Oh... Lost in time</Text>
                        <Text bold="500" color="secondary">Error Code: 404</Text>
                        <Text bold="500">{`Sorry, we couldn't find the page you’re looking for.
Don’t worry, fuel up again.`}
                        </Text>
                        <Link to={'/'}>
                          <Button sx={{mt: 5}} color={'primary'} variant="contained" >Go To Homepage</Button>
                        </Link>
                      </NotFoundContent>

                    </Grid>
                  </StyledContainer>
              </Content>
          </MainContainer>
      </>
  )
}

export default NotFound;

