const images = {
  logo: require('./logo.svg').default,
  logoIcon: require('./logo-icon.svg').default,
  dashboard: require('./dashboard.svg').default,
  newPools: require('./newpools.svg').default,
  timeTunnel: require('./timetunnel.png').default,
  notFound: require('./404.svg').default,
  arrowRepeat: require('./arrow-repeat.png').default,
  missingTokenImg: '/images/tokens/default.svg',
  timeMachineBg: require('./time-machine-bg.svg').default,
  loadingWaveBg: require('./loading-wave-bg.svg').default,
}

export default images;