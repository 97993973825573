import { FarmVolumeAndTVLDto } from '@iddy/time-machine';
import dayjs from 'dayjs';
import { usePoolApi } from 'lib/api';
import { useState } from 'react';

export const useFarmLiquidityVolumeGraphData = () => {
  const api = usePoolApi();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<FarmVolumeAndTVLDto[]>();

  const fetch = async ({
    poolId,
    fromDate,
    toDate,
  }) => {
    setIsReady(false);
    const period:number = dayjs(toDate).diff(dayjs(fromDate), 'day');
    const result = await api.getVolumeAndTVL(poolId, period, toDate);
    setData(result.data.result);
    setIsReady(true);
  };

  return {
      isReady,
      data,
      fetch,
  };
};
