/**
 * Tracking GoogleAnalytics context
 */

 import ReactGA from "react-ga4";
 import React, { createContext, useContext, useEffect, useState } from 'react';
 import _ from 'lodash';
import { useRouter } from 'hooks/useRouter';
import { GA4 } from "react-ga4/types/ga4";
import { getEnv } from "config";

ReactGA.initialize([
  {
    trackingId: getEnv().REACT_APP_GOOGLE_GLOBAL_TAG,
  },
  {
    trackingId: getEnv().REACT_APP_GOOGLE_ADS,
  }
]);
 
 type GoogleAnalyticsContextData = {
   ga: GA4
 }
 
 const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextData>({
   ga: null,
 });
 
 export function useGoogleAnalyticsContext(){
   return useContext(GoogleAnalyticsContext)
 }
 
 export function GoogleAnalyticsProvider(props){
   const {children} = props;
   const router = useRouter();
   const trackPageView = () => {
     ReactGA.send('pageview');
     ReactGA.event('conversion', {send_to: `${getEnv().REACT_APP_GOOGLE_ADS}/GMI6CITciKIDENrV-7co`})
   }
   useEffect(() => {
     trackPageView();
   }, [router]);
   return (
     <GoogleAnalyticsContext.Provider value={{ga: ReactGA}}>
         {children}
     </GoogleAnalyticsContext.Provider>
   )
 }