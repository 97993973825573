import React, { createContext, useState } from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from 'components/Paper';

const ModalContainer = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
  padding: 24px;
  max-height: 95%;
  overflow-y: auto;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 16px;
    max-height: 75%;
  }
`;

/*   bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4, */

interface ModalsContext {
  isOpen: boolean;
  onPresent: (node: React.ReactNode) => void;
  onDismiss: () => void;
}

export const ModalContext = createContext<ModalsContext>({
  isOpen: false,
  onPresent: () => null,
  onDismiss: () => null,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNode, setModalNode] = useState<React.ReactNode>();

  const handlePresent = (node: React.ReactNode) => {
    setModalNode(node);
    setIsOpen(true);
  };

  const handleDismiss = () => {
    setModalNode(undefined);
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      <Modal
        open={isOpen}
        onClose={handleDismiss}
      >
        <ModalContainer>
          {React.isValidElement(modalNode)
          && React.cloneElement(modalNode, { onDismiss: handleDismiss })}
        </ModalContainer>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
