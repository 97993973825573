import { isDev } from 'config';
import _ from 'lodash';
import chainDict from './chainDict.json';

export type ChainDto = {
  name: string,
  symbol: string,
  launch: boolean,
  chainId: number
}

export const chainArr:ChainDto[] = [
  {
    symbol: 'bsc',
    name: chainDict.bsc.name,
    launch: chainDict.bsc.launch,
    chainId: chainDict.bsc.chainId,
  },
  {
    symbol: 'avalanche',
    name: chainDict.avalanche.name,
    launch: chainDict.avalanche.launch,
    chainId: chainDict.avalanche.chainId,
  },
  {
    symbol: 'cronos',
    name: chainDict.cronos.name,
    launch: chainDict.cronos.launch,
    chainId: chainDict.cronos.chainId,
  },
  {
    symbol: 'eth',
    name: chainDict.eth.name,
    launch: chainDict.eth.launch,
    chainId: chainDict.eth.chainId,
  },
  {
    symbol: 'fantom',
    name: chainDict.fantom.name,
    launch: (isDev()) ? true : chainDict.fantom.launch,
    chainId: chainDict.fantom.chainId,
  },
  {
    symbol: 'polygon',
    name: chainDict.polygon.name,
    launch: chainDict.polygon.launch,
    chainId: chainDict.polygon.chainId,
  },
];

export const chainsBySymbol = _.keyBy(chainArr, 'symbol');
