import React, { useRef, useEffect, useCallback } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import {
  styled, Theme, CSSObject, useTheme,
} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PortfolioIcon, AddToMetaMaskIcon } from 'components/Svg';
import useDrawer from 'hooks/useDrawer';
import { usePollUserPortfolioData } from 'state/portfolio/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from 'components/Svg/Icons/FacebookIcon';
import MediumIcon from 'components/Svg/Icons/MediumIcon';
import DiscordIcon from 'components/Svg/Icons/DiscordIcon';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import tokens from 'config/constants/tokens';
import useChains from 'hooks/useChains';
import { useHistory } from 'react-router-dom';
import TimeMachineMenu from './components/TimeMachineMenu';
import { Box } from '@mui/system';
import { Text } from 'components/Text';
import { experimental_sx as sx } from '@mui/system';

const drawerWidth = 300;
const StyledAddToMetaMaskIcon = styled(AddToMetaMaskIcon)``;

const FooterContainer = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  height: '50px',
  display: 'flex',
  flexShrink: '0',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(8),
  padding: theme.spacing(0, 2),
  fontWeight: 'bold',
  color: theme.palette.primary.contrastText,
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarColor: 'none',
        ...openedMixin(theme),
      },
      '& .header': {
        background: theme.palette.primary.mainGradient,
      },
      '& #portfolio-icon': {
        stroke: theme.palette.primary.contrastText,
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .header': {
        backgroundColor: 'transparent',
      },
      '& .MuiButtonBase-root': {
        padding: 0,
      },
      '& #portfolio-icon': {
        stroke: theme.palette.primary.main,
      },
    }),
  }),
);

const MobileDrawer = styled(MuiDrawer)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '& .header': {
      background: theme.palette.primary.mainGradient,
    },
    '& #portfolio-icon': {
      stroke: theme.palette.primary.contrastText,
    },
    zIndex: theme.zIndex.drawer,
  }),
);

const StyledFab = styled(Fab)`
  position: fixed;
  top: 77px;
  left: 0px;
  z-index: ${({ theme }) => theme.zIndex.drawer + 2};
  background-color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  svg {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SocialIconLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const GitbookDocumentLink = styled('a')(
  sx({
    color: 'text.primary',
    "&:hover":{
      color: 'inherit',
      textDecoration: 'underline',
    },
    "&:visited":{
      color: 'inherit',
    }
  })
)

const DrawerComponent: React.FC = () => {
  const { open, setOpen } = useDrawer();
  const { spacing, palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const prevIsMobileRef = useRef(isMobile);
  const history = useHistory();
  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile !== prevIsMobileRef.current) {
      if (isMobile) { setOpen(false); } else { setOpen(true); }
    }
    prevIsMobileRef.current = isMobile;
  }, [isMobile, prevIsMobileRef]);

  useEffect(() => {
    const removeListen = history.listen((...args) => {
      if (isMobile) {
        setOpen(false);
      }
    });
    return removeListen;
  }, [isMobile]);

  return (
    isMobile
      ? (
        <>
          {!open && (
          <StyledFab onClick={handleDrawer}>
            <PortfolioIcon id="portfolio-icon" />
          </StyledFab>
          )}
          <MobileDrawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
          >
            {open && (
            <>
              <Box mt="64px"></Box>
              <TimeMachineMenu />
              <DrawerFooterComponent />
            </>
            )}
          </MobileDrawer>
        </>
      )
      : (
        <DesktopDrawer
          anchor="left"
          variant="permanent"
          open={open}
        >
          {open && (
          <>
            <Box mt="64px"></Box>
            <TimeMachineMenu />
            <DrawerFooterComponent />
          </>
          )}
        </DesktopDrawer>
      )
  );
};

const DrawerFooterComponent = () => {
  return (
    <FooterContainer>
      <Text typography='small'>
        <GitbookDocumentLink href='https://docs.singlefinance.io/home/lp-time-machine' target="_blank">
          Documentation            
        </GitbookDocumentLink>
      </Text>
      <List sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <ListItem>
          <SocialIconLink href="https://twitter.com/single_finance" target="_blank">
            <TwitterIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://medium.com/singlefinance" target="_blank">
            <MediumIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://discord.gg/97W57CjJme" target="_blank">
            <DiscordIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://t.me/singlefinanceofficial" target="_blank">
            <TelegramIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://www.linkedin.com/company/singlefinance/" target="_blank">
            <LinkedInIcon />
          </SocialIconLink>
        </ListItem>
      </List>
      <Divider sx={{ mt: 1, mb: 2 }} flexItem />
    </FooterContainer>
  );
};

export default DrawerComponent;
