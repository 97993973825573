import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, experimental_sx as sx } from '@mui/system';

const StyledContainer = styled(Box)(
  sx<Theme>({
    textAlign: 'center',
    width: '100%',
    background: (theme) => theme.palette.single.mainGradient,
    px: 2,
    py: 1,
    color: 'single.contrastText',
    fontWeight: 500,
  })
)

export const WelcomeBannerComponent:React.FC<{}> = () => {
  return <StyledContainer>
    🎉 All Pro Features are free to use in LP Time Machine preview version 🎉
  </StyledContainer>
}