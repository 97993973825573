import React from 'react';
import { styled } from '@mui/material/styles';
import Wrapper from './Wrapper';
import { ImageProps } from './types';

const StyledImage = styled('img')`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Image: React.FC<ImageProps> = ({
  src, alt, width, height, ...props
}) => (
  <Wrapper height={height} width={width} {...props}>
    <StyledImage src={src} alt={alt} />
  </Wrapper>
);

export default Image;
