import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import { Text } from 'components/Text';
import {
  useFarmPoolInfo, useGetAddrByRefId, usePoolLastUpdateDate,
} from 'lib/timeMachineHelpers';
import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'hooks/useRouter';
import { getDetailFullLink } from 'utils/linkHelpers';
import SEO from 'components/SEO';
import { chainsBySymbol } from 'config/chain';
import { DetailPageContextProvider } from '../context';
import TimeMachineDetailHeader from './TimeMachineDetailHeader';
import TimeMachineDetailPairInfo from './TimeMachineDetailPairInfo';
import TimeMachineTimeTunnelController from './TimeMachineTimeTunnelController';
import TimeMachineDetailReturnRateController from './TimeMachineDetailReturnRateController';
import TimeMachineDetailLiquidityAverageController from './TimeMachineDetailLiquidityAverageController';
import TimeMachineDetailLiquidityGraphController from './TimeMachineDetailLiquidityGraphController';

const StyledContainer = styled(Box)(
  sx({
    p: 1,
  }),
);

const TimeMachineDetailController:React.FC<{
  lpAddress: string,
  dex: string,
  chain: string,
  name: string
}> = ({
  lpAddress,
  dex,
  chain,
  name,
}) => {
  const route = useRouter();
  const infoHook = useFarmPoolInfo();
  const lastUpdateHook = usePoolLastUpdateDate();
  const getAddrByRefIdHook = useGetAddrByRefId();
  const { data } = infoHook;
  const loading = !(infoHook.isReady && infoHook.data);
  const requestPoolId = useMemo(() => {
    const { chainId } = chainsBySymbol[chain];
   return `${chainId}-${lpAddress}`;
  }, [chain, lpAddress]);
  const {
    title,
    description,
  } = useMemo(() => {
    const info = infoHook.data;
    if (!infoHook.isReady) {
      return {};
    }
    // [SEO] Remainder update server side SEO title description in server/index.js after update client side
    const fullName = `${info.token.name} (${info.token.symbol}) - ${info.quoteToken.name} (${info.quoteToken.symbol})`;
    const title = `${fullName} on ${info.chain.name}, ${info.exchange.name}`;
    const description = `View ${info.exchange.name} ${info.lpSymbol} liquidity pool performance on ${info.chain.name}, with full breakdown of yield farming rewards marked to USD and impermanent loss.`;
    return {
      title,
      description,
    };
  }, [infoHook.data, infoHook.isReady]);

  useEffect(() => {
    if (lpAddress.length < 4) {
      getAddrByRefIdHook.fetch(lpAddress);
    }
  }, [lpAddress]);

  useEffect(() => {
    if (getAddrByRefIdHook.isReady) {
      route.replace(getDetailFullLink(chain, dex, getAddrByRefIdHook.data, name));
    }
  }, [getAddrByRefIdHook.isReady]);

  useMemo(() => {
    if (requestPoolId) {
      infoHook.fetch(requestPoolId);
      lastUpdateHook.fetch(requestPoolId);
    }
  }, [requestPoolId]);

  useEffect(() => {
    if (data) {
      if (data.chain.symbol !== chain || data.exchange.symbol !== dex || data.lpSymbol !== name) {
        route.replace(getDetailFullLink(data.chain.symbol, data.exchange.symbol, lpAddress, data.lpSymbol));
      }
    }
  }, [data]);

  return (
    <DetailPageContextProvider value={{ info: infoHook.data, lastUpdate: lastUpdateHook.data }}>
      {!loading
        && (
        <>
          <SEO
            title={title}
            description={description}
          />
          <TimeMachineDetailHeader />
          <TimeMachineDetailPairInfo sx={{ mb: 7 }} />
          <TimeMachineTimeTunnelController sx={{ mb: 4 }} />
          <Grid sx={{ mb: 4 }} container flexDirection="column" gap={2}>
            <Text typography="h4" bold>Historical Overview</Text>
            <Grid container gap={2}>
              <Grid item flex="1 1 100%" sx={{ overflowX: 'hidden' }}>
                <TimeMachineDetailReturnRateController />
              </Grid>
              <Grid item flex="1 1 100%">
                <TimeMachineDetailLiquidityAverageController />
              </Grid>
              <Grid item flex="1 1 100%">
                <TimeMachineDetailLiquidityGraphController />
              </Grid>
            </Grid>
          </Grid>
        </>
)}
      {loading
      && (
      <Grid container sx={{ mt: 2 }} gap={2} flexDirection="column">
        <Skeleton variant="rectangular" width="100%" height={300} />
        <Skeleton variant="rectangular" width="100%" height={450} />
        <Skeleton variant="rectangular" width="100%" height={600} />
        <Skeleton variant="rectangular" width="100%" height={600} />
      </Grid>
)}
    </DetailPageContextProvider>
  );
};

export default TimeMachineDetailController;
