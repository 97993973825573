/* eslint-disable max-len */
import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.0872 9.6703V2.39301C20.0872 1.62367 19.4768 1 18.7238 1H2.36337C1.6104 1 1 1.62367 1 2.39301V19.1092C1 19.8785 1.6104 20.5022 2.36337 20.5022H11.2253M4.40842 6.57205H16.4672M4.40842 10.7511H10.191M14.7747 12.5524V23H25M16.1851 20.8384L19.0059 16.5153L21.1214 19.3974L24.6474 14.3537" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default Icon;
