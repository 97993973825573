import React from 'react';
import { styled } from '@mui/material/styles';
import SymbolImage from './SymbolImage';
import { ImageProps } from './types';
import Wrapper from './Wrapper';

export interface TokenPairImageProps {
  primarySrc: string;
  secondarySrc: string;
  height: number;
  width: number;
}

const PrimaryImage = styled(SymbolImage)<ImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
`;

const SecondaryImage = styled(SymbolImage)<ImageProps>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
`;

const TokenPairImage: React.FC<TokenPairImageProps> = ({
  primarySrc,
  secondarySrc,
  width,
  height,
  ...props
}) => (
  <Wrapper width={width} height={height} {...props}>
    <PrimaryImage src={primarySrc} width={height} height={height} />
    <SecondaryImage src={secondarySrc} width={height} height={height} />
  </Wrapper>
);

export default TokenPairImage;
