import React, {
  useEffect, useRef, useState, useLayoutEffect, useMemo,
} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import * as d3 from 'd3';
import { useMatchBreakpoints } from 'hooks/useResponsive';
import { formatNumber, filterTimeData } from 'lib/format';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import _ from 'lodash';
import dayjs from 'dayjs';
import { experimental_sx as sx } from '@mui/system';
import PropTypes from 'prop-types';

export const CircleIcon = styled(Box)<{background?: any}>(({background}) => 
  sx({
    width: 8,
    height: 8,
    borderRadius: '50%',
    display: 'inline-block',
    bgcolor: background,
  })
)

CircleIcon.defaultProps = {
  className: 'circle-icon',
}

CircleIcon.propTypes = {
  background: PropTypes.string,
  sx: PropTypes.object,
}