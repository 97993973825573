import { FarmDataDto, FarmDetailDto, FarmSummaryData } from '@iddy/time-machine';
import { getDexBySymbol, useFarmDashboardList } from 'lib/timeMachineHelpers';
import _ from 'lodash';
import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import { usePrevious } from 'react-use';

type SimulateListData = FarmDetailDto & {dataKey: {[key:string]: FarmDataDto}};

export const useTableData = () => {
  const [params, setParams] = useState<any>({});
  const prevParams = usePrevious(params);
  const apiHelper = useFarmDashboardList();
  const apiData = apiHelper.data;
  const { isReady } = apiHelper;
  const {
 where, sort, skip, take, query,
} = params;

  const data:{
    data: SimulateListData[],
    count: number,
  } = useMemo(() => {
    if (!apiData) {
      return;
    }
    const formatData = _(apiData.data).map((v) => ({
      ...v,
      exchangeName: getDexBySymbol(_.get(v, 'pool.dex'))?.name,
      dataKey: _.keyBy(v.data, 'period'),
    })).value();
    return {
      data: formatData,
      count: apiData.count,
    };
  }, [apiData]);

  const fetch = (params) => {
    setParams(params);
  };

  useEffect(() => {
    if (
      where
      && sort
      && !_.isEqual(_.pick(prevParams, ['where', 'sort']), { where, sort })
    ) {
      apiHelper.fetch({
        where,
        sort,
      });
    }
  }, [where, sort]);

  // client side format result
  const filterData = useMemo(() => {
    if (!data) {
      return;
    }
    let collectionData = _(data.data).chain();
    // handle search
    if (query && query !== '') {
      collectionData = collectionData.filter((v) => {
        const symbol = _.get(v, 'pool.lpSymbol', '');
        const queryAnd = query.split(/[\s-]/);
        return _.every(queryAnd, (q) => _.toUpper(symbol).indexOf(_.toUpper(q)) !== -1);
      });
    }
    const count = collectionData.value().length;

    // handle paging
    if (skip !== null && take !== null) {
      collectionData = collectionData.slice(skip, skip + take);
    }

    return {
      data: collectionData.value(),
      count,
    };
  }, [data, skip, take, query]);

  return {
    data: filterData,
    isReady,
    fetch,
  };
};
