import React, { useState } from "react";
import { Box, SxProps } from "@mui/system";
import { Button, Divider, Grid, InputAdornment, Paper, styled, Tab, Tabs, TextField } from "@mui/material";
import { experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken } from "components/Image/helpers";
import images from "assets/images";
import { Text } from "components/Text";
import { useChainList } from "lib/timeMachineHelpers";
import { useForm, FormProvider, useFormContext, useController } from "react-hook-form";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { grey } from '@mui/material/colors';
import _ from "lodash";
import { SearchIcon } from 'components/Svg';

const StyledTextField = styled(TextField)(
  sx({
    "& .MuiOutlinedInput-root":{
      bgcolor: 'background.paper',
    },
    "& input": {
      pr: 1,
      py: 1.5,
    }
  })
)


const TimeMachineSearchInput:React.FC<{sx?: SxProps}> = ({
  ...otherProps
}) => {
  const { field } = useController({name: 'query'});
  return <StyledTextField
    variant="outlined"
    color="primary"
    InputProps={{
      sx: {width: 300},
      placeholder: 'Search by pair name',
      autoComplete: 'off',
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      )
    }}  
    {...field}
    {...otherProps}
  />
}

export default TimeMachineSearchInput;