import { createAction, createSlice } from '@reduxjs/toolkit';
import { TimeMachineState } from 'state/types';
import dayjs from 'dayjs';

export const updateConfig = createAction<TimeMachineState>('timemachine/updateConfig');
export const setSummaryStatus = createAction('timemachine/setSummaryStatus');
export const setChartStatus = createAction('timemachine/setChartStatus');
export const setBasicInfoStatus = createAction('timemachine/setBasicInfoStatus');

const initialState = {
  farmId: 2,
  period: 10,
  endDate: dayjs().subtract(1, 'days').endOf('day').startOf("hour").format("YYYY-MM-DDTHH:mm:ss") + '.000Z',
  principal: 10000,
  isReadyChartData: true,
  isReadySummaryData: true,
  isReadyBasicData: true
};

export const timemachineSlice = createSlice({
  name: 'TimeMachine',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateConfig, (state, action: {payload: TimeMachineState}) => {
      return {
        ...state, 
        ...action.payload, 
        isReadyChartData: false, 
        isReadySummaryData: false, 
        isReadyBasicData: false
      }
    })
    .addCase(setChartStatus, (state) => {
      return {...state, isReadyChartData: true};
    })
    .addCase(setSummaryStatus, (state) => {
      return {...state, isReadySummaryData: true};
    })
    .addCase(setBasicInfoStatus, (state) => {
      return {...state, isReadyBasicData: true};
    })
  },
});

export default timemachineSlice.reducer;
