import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import strategiesReducer from './strategies';
import portfolioReducer from './portfolio';
import timemachineReducer from './timemachine';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    // strategies: strategiesReducer,
    // portfolio: portfolioReducer,
    timemachine: timemachineReducer
  },
  middleware: [...getDefaultMiddleware({ thunk: true })],
});

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch();

export default store;
