import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Zoom from '@mui/material/Zoom';
import { Box, experimental_sx as sx } from '@mui/system';
import { Theme } from '@mui/material';
import { ReactElement } from 'bizcharts/lib/components/Annotation';
import { render } from '@testing-library/react';

const StyledPopover = styled(Popover)({
  "& > .MuiPaper-root": {
    background: 'transparent',
    boxShadow: 'none',
  }
});

const StyledIconButton = styled(IconButton)(
  sx({
    p: 0,
    mx: 0.5,
  })
)

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(
  sx<Theme>({
    height: 18,
    width: 18,
    fill: (theme) => theme.palette.greyColor.main,
  })
)

type InfoPopoverProps = {
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  component?: any,
}

const InfoPopover: React.FC<InfoPopoverProps> = ({
  component,
  children, anchorOrigin = {
    vertical: 'center',
    horizontal: 'center',
  }, transformOrigin = {
    vertical: 'center',
    horizontal: 'center',
  },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {component 
      ?
        <Box
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {component}
        </Box>
      : 
      <StyledIconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
      >
        <StyledInfoOutlinedIcon />
      </StyledIconButton>
      }
      <StyledPopover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
        TransitionComponent={Zoom}
        style={{ backgroundColor: 'transparent' }}
      >
        {children}
      </StyledPopover>
    </>

  );
};

export default InfoPopover;