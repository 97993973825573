import { isDev } from 'config';
import dexDict from './dexDict.json';

export type DexDto = {
  name: string,
  symbol: string,
  chain?: string,
  launch: boolean,
}

export const dexArr:DexDto[] = [
  {
    symbol: 'traderJoe',
    name: dexDict.traderJoe.name,
    chain: dexDict.traderJoe.chain,
    launch: dexDict.traderJoe.launch,
  },
  {
    symbol: 'pancakeswap',
    name: dexDict.pancakeswap.name,
    chain: dexDict.pancakeswap.chain,
    launch: dexDict.pancakeswap.launch,
  },
  {
    symbol: 'vvs',
    name: dexDict.vvs.name,
    chain: dexDict.vvs.chain,
    launch: dexDict.vvs.launch,
  },
  {
    symbol: 'spookyswap',
    name: dexDict.spookyswap.name,
    chain: dexDict.spookyswap.chain,
    launch: (isDev()) ? true : dexDict.spookyswap.launch,
  },
  {
    symbol: 'mmf',
    name: dexDict.mmf.name,
    chain: dexDict.mmf.chain,
    launch: dexDict.mmf.launch,
  },
  {
    symbol: 'uniswap',
    name: dexDict.uniswap.name,
    launch: dexDict.uniswap.launch,
  },
  {
    symbol: 'quickSwap',
    name: dexDict.quickSwap.name,
    launch: dexDict.quickSwap.launch,
  },
];
