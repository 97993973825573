import React from 'react';
import { styled } from '@mui/material/styles';
import useDrawer from 'hooks/useDrawer';

const drawerWidth = 300;

const MainContainer = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'setOpen' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.spacing(7),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  },
}));

const WrappedMainContainer = (props) => {
  const { open, setOpen } = useDrawer();
  return <MainContainer open={open} setOpen={setOpen} {...props} />;
};

export default WrappedMainContainer;
