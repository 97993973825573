import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend
} from 'bizcharts';
import React, { useMemo, useState } from 'react';
import { formatPercent, formatPrice, formatPriceCurrency, formatPriceLong } from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled } from '@mui/system';
import { experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { Collapse, Divider, Paper, Theme, useTheme } from '@mui/material';
import TimeMachineTimeTunnelShareGraph from './TimeMachineTimeTunnelShareGraph';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text, TrendText } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TickIcon } from 'components/Svg';
import { useDetailPageContext } from '../context';
import { alpha } from '@mui/lab/node_modules/@mui/system';

const StyledContainer = styled(Box)<{
  isLatest: boolean
}>(
  ({isLatest}) => 
  sx<Theme>([
    {
      minWidth: {
        xs: 40,
        md: 20,
      },
      width: 'max-content',
      typography: 'small',
      gap: 0,
      borderRadius: 20,
      fontWeight: '500',
    },
    isLatest && {
      bgcolor: 'success.main',
      color: 'success.contrastText',
    },
    !isLatest && {
      bgcolor: (theme) => alpha(theme.palette.error.main, 0.24),
      color: 'error.main',
    }
  ]
  )
)

const StyledText = styled(Text)(
  sx({
    width: {
      xs: 350,
      md: 'max-content',
    },
    // overflow: 'hidden',
  })
)

const IconBox = styled(Box)(
  sx({
    flex: {
      xs: "0 0 32px",
      md: "0 0 max-content",
    },
    mx: 0.25,
    width: 16,
    height: 16,
    "& > *":{
      width: '100%',
      height: '100%',      
    }      
  })
)

const FarmUpdateStatus = () => {
  const { lastUpdate } = useDetailPageContext();
  const lastUpdateTime = useMemo(() => {
    return _.min(_.values(lastUpdate));
  }, [lastUpdate])
  const isLatest = useMemo(() => {
    return dayjs().diff(lastUpdateTime, 'm') < 150;
  }, [lastUpdateTime]);
  const [hover, setHover] = useState(false);
  const icon = (isLatest) ? <TickIcon /> : <InfoOutlinedIcon />;
  const text = (isLatest) 
    ? `Data are up to date. Last update hour: ${dayjs(lastUpdateTime).utc().format('YYYY-MM-DD HH:mm UTC')}`
    : `Some data in this pool maybe outdated. Last update hour: ${dayjs(lastUpdateTime).utc().format('YYYY-MM-DD HH:mm UTC')}`;
  return (
  <StyledContainer 
    isLatest={isLatest}
    component={InlineMiddleFlex}
    justifyContent={'center'}
    onMouseLeave={() => setHover(false)}
    onMouseEnter={() => setHover(true)}
  >
    <IconBox>
      {icon}
    </IconBox>
    <Collapse orientation="horizontal" in={hover}>
      <StyledText sx={{ml: 0.5, mr: 1}} className={'text'}>
        {text}
      </StyledText>
    </Collapse>
  </StyledContainer >
  )
}

export default FarmUpdateStatus;