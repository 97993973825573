import DashboardTabs from "./DashboardTabs"
import BlockChainList from "./BlockChainList";
import { Box } from "@mui/material";

const TabsSx = {
  "& .MuiTabs-root":{
    mx: -2.5,
    "& .MuiTabs-flexContainer > *:not(.MuiTabs-indicator)": {
      mx: 2.5
    }  
  },
}

const TimeMachineMenu = () => {
  return <Box pt={5} px={2.5}>
    <DashboardTabs sx={TabsSx} />
  </Box>
}

export default TimeMachineMenu;