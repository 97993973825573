import { LastUpdateDateDto } from '@iddy/time-machine';
import { TokenInfoDto } from 'lib/timeMachineHelpers';
import React, { createContext, useContext } from 'react';



interface DetailPageContextData {
  info: TokenInfoDto,
  lastUpdate: LastUpdateDateDto,
}

export const DetailPageContext = createContext<DetailPageContextData>(null);

export const useDetailPageContext = () => useContext(DetailPageContext);

export const DetailPageContextProvider: React.FC<{value: DetailPageContextData}> = ({ children, value }) => {

  return (
    <DetailPageContext.Provider value={value}>
      {children}
    </DetailPageContext.Provider>
  );
};
