import { Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import { getImageUrlFromToken, getImageUrlFromTokenDto } from 'components/Image/helpers';
import TokenPairImage from 'components/Image/TokenPairImage';
import _ from 'lodash';
import { Text } from 'components/Text';
import { formatNumber, formatTokenAmount, formatNumberLong } from 'utils/formatNumber';
import TokenImage from 'components/Image/TokenImage';
import React from 'react';
import { RewarderEntity } from 'lib/timeMachineHelpers';
import { useDetailPageContext } from '../context';
import FarmUpdateStatus from './FarmUpdateStatus';

const StyledContainer = styled(Paper)(
  sx({
    px: 3,
    py: 4,
  }),
);

const MainGrid = styled(Grid)(
  sx({
  }),
);

const InfoGridItem = styled(Grid)(
  sx({
    flex: '1 1 400px',
  }),
);

const PriceRatio = styled(Box)(
  sx({
    flexWrap: 'wrap',
    mt: 2,
    display: 'flex',
    gap: 3,
    rowGap: 1,
    '& > *': {
      flex: '0 0 max-content',
    },
  }),
);

const StyledTokenImage = styled(TokenImage)(
  sx({
    width: 20,
    height: 20,
  }),
);

const TokenGridItem = styled(Grid)(
  sx({
    flex: '0 0 300px',
    '& > *': {
      flex: '1 1 50%',
    },
  }),
);

const TimeMachineDetailPairInfo = (props) => {
  const { info } = useDetailPageContext();
  return (
    <StyledContainer {...props}>
      <MainGrid container rowGap={3} columnGap={1} justifyContent="center">
        <InfoGridItem item container gap={1} flexWrap="nowrap">
          <Grid item flex="0 0 60px">
            <TokenPairImage
              primarySrc={getImageUrlFromTokenDto(info.token)}
              secondarySrc={getImageUrlFromTokenDto(info.quoteToken)}
              width={56}
              height={32}
            />
          </Grid>
          <Grid item flex="1 1 auto">
            <Text typography="h3" bold>
              {info.token.name}
              {' '}
              (
              {info.token.symbol}
              ) -
              {' '}
              {info.quoteToken.name}
              {' '}
              (
              {info.quoteToken.symbol}
              )
            </Text>
            <PriceRatio>
              <Text typography="body2">
                1
                {' '}
                {info.token.symbol}
                {' '}
                =
                {' '}
                {'$ '}
                {' '}
                {formatNumberLong(info.price.tokenPrice, 0, 4)}
              </Text>
              <Text typography="body2">
                1
                {' '}
                {info.quoteToken.symbol}
                {' '}
                =
                {' '}
                {'$ '}
                {' '}
                {formatNumberLong(info.price.quoteTokenPrice, 0, 4)}
              </Text>
            </PriceRatio>
          </Grid>
        </InfoGridItem>
        <TokenGridItem container item>
          <TokenItemWidget
            title="Chain"
            image={<StyledTokenImage solid token={info.chain.symbol} type="chain" />}
            label={info.chain.name}
          />
          <TokenItemWidget
            title="DEX"
            image={<StyledTokenImage token={info.exchange.symbol} type="dex" />}
            label={info.exchange.name}
          />
          <RewardTokensItemWidget
            image={<TokenImage token={info.rewardToken} />}
            label={info.rewardToken.symbol}
            rewarderEntity={info.rewarderEntity}
          />
        </TokenGridItem>
      </MainGrid>
      <Box sx={{ pt: 2, ml: -2, mb: -2 }}>
        <FarmUpdateStatus />
      </Box>
    </StyledContainer>
  );
};

const TokenItemWidget:React.FC<{
  title: string,
  image: any,
  label: string,
}> = ({
  title,
  image,
  label,
}) => (
  <Box>
    <Text typography="small" color="secondary">{title}</Text>
    <Grid container sx={{ mt: 0.5 }} flexWrap="nowrap" gap={1}>
      <Grid flex="0 0 20px">
        <Text color="secondary">{image}</Text>
      </Grid>
      <Grid flex="1 1 min-content">
        <Text typography="body1" bold="500">{label}</Text>
      </Grid>
    </Grid>
  </Box>
);

const RewardTokensItemWidget:React.FC<{
  image: any,
  label: string,
  rewarderEntity?: RewarderEntity[]
}> = ({
  image,
  label,
  rewarderEntity,
}) => (
  <Box>
    <Text typography="small" color="secondary">Reward Token</Text>
    <Grid container sx={{ mt: 0.5 }} flexWrap="nowrap" gap={1}>
      <Grid flex="0 0 20px">
        <Text color="secondary">{image}</Text>
      </Grid>
      <Grid flex="1 1 min-content">
        <Text typography="body1" bold="500">{label}</Text>
      </Grid>

    </Grid>
    {rewarderEntity.map((token) => (
      <Grid container sx={{ mt: 0.5 }} flexWrap="nowrap" gap={1}>
        <Grid flex="0 0 20px">
          <TokenImage token={token.rewardTokenEntity.symbol} type="tokens" />
        </Grid>
        <Grid flex="1 1 min-content">
          <Text typography="body1" bold="500">{token.rewardTokenEntity.symbol}</Text>
        </Grid>

      </Grid>
        ))}
  </Box>
);

export default TimeMachineDetailPairInfo;
