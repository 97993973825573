import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import MainContainer from 'components/MainContainer';
import { useRouter } from 'hooks/useRouter';
import React, { useEffect } from 'react';
import TimeMachineDetailController from './components/TimeMachineDetailController';
import _ from 'lodash';
import TimeMachineDetailHeader from './components/TimeMachineDetailHeader';
import { WelcomeBannerComponent } from 'components/WelcomeBannerComponent';

const Content = styled('div')`
  overflow: hidden;
  width: 100%;
  padding: 8px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0px;
  }
`;

const StyledContainer = styled(Container)(
  sx({
    mt: -0.75
  }) 
)

const TimeMachineDetail: React.FC = () => {
  const router = useRouter();
  const lpAddress = _.get(router, 'match.params.id');
  //export const timeMachineDetailFullUrl = '/detail/:chain/:exchange/:id';
  const chain = _.get(router, 'match.params.chain');
  const exchange = _.get(router, 'match.params.exchange');
  const name = _.get(router, 'match.params.name');
  
  useEffect(() => {
    window.scroll(0, 0);
  }, [router])
  return (
      <>
          <MainContainer>
              <WelcomeBannerComponent />
              <Content>
                  <StyledContainer>
                    <TimeMachineDetailController lpAddress={lpAddress} chain={chain} dex={exchange} name={name} />
                  </StyledContainer>
              </Content>
          </MainContainer>
      </>
  )
}

export default TimeMachineDetail

