import {
  Area, Chart, Line,
  Tooltip, Axis, View, Legend,
} from 'bizcharts';
import React, { useMemo } from 'react';
import {
 formatPercent, formatPrice, formatPriceCurrency, formatPriceLong,
} from 'utils/formatNumber';
import dayjs from 'dayjs';
import { Box, styled, experimental_sx as sx } from '@mui/system';
import _ from 'lodash';
import { CircleIcon } from 'components/CircleIcon';
import { Divider, useTheme } from '@mui/material';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import { Text, TrendText } from 'components/Text';
import { CustomMuiThemeProvider } from 'contexts/CustomMuiTheme';
import TimeMachineTimeTunnelShareGraph from './TimeMachineTimeTunnelShareGraph';
import TimeMachineTimeTunnelShareTooltipItem from './TimeMachineTimeTunnelShareTooltipItem';

const TimeMachineTimeTunnelTotalEvGraph:React.FC<{
  data: {type: string, value: number, time: string, area: number[], line: number, percentage: number}[],
  tokenData:{type: string, valueToken: number, time: string, percentageToken: number}[],
  dataApr: {type: string, value: number, time: string, area: number[], line: number, percentage: number}[],
  formatter?: any,
  fields: {[key: string]: any},
  lossView: string,
  [key: string]: any,
}> = ({
  data,
  tokenData,
  dataApr,
  height,
  fields,
  lossView,
  formatter = (v) => v,
  ...otherProps
}) => {
  const theme = useTheme();
  const tooltip = useMemo(() => (
    <Tooltip
      shared
      showMarkers
      container="div"
      domStyles={{
      'g2-tooltip': {
        borderRadius: '20px',
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
      },
    }}
    >
      {(title, items) => {
    const uItems = _.uniqBy(items, 'name');
    const time = _.get(uItems, '0.data.time');
    return (
      <>
        <CustomMuiThemeProvider>
          <Box sx={{
 p: 0.5, mb: 1, width: 340, typography: 'body2',
}}
          >
            <Box className="g2-tooltip-title" sx={{ mb: 0.5 }}>
              <InlineMiddleFlex justifyContent="space-between">
                <Text color="secondary" bold>{dayjs(time).format('YYYY-MM-DD, HH')}</Text>
                <Text color="secondary" bold>USD (ROI%)</Text>
              </InlineMiddleFlex>
            </Box>
            <Divider sx={{ my: 1 }} />
            <ul className="g2-tooltip-list">
              {_.map(uItems, (item) => (
                <TimeMachineTimeTunnelShareTooltipItem
                  color={_.get(fields[item.name], 'color')}
                  name={_.get(fields[item.name], 'alias')}
                  value={item.data.value}
                  percentage={item.data.percentage}
                  view="roi"
                />
          ))}
              <li><Divider sx={{ my: 1, borderStyle: 'dashed' }} /></li>
              <TimeMachineTimeTunnelShareTooltipItem
                color="primary.main"
                name="Total Value"
                value={_.sumBy(uItems, 'data.value')}
                percentage={_.sumBy(uItems, 'data.percentage')}
                view="roi"
              />
            </ul>
          </Box>
        </CustomMuiThemeProvider>
      </>
);
  }}
    </Tooltip>
  ), []);
  return (
    <TimeMachineTimeTunnelShareGraph
      lossView={lossView}
      data={data}
      tokenData={tokenData}
      dataApr={dataApr}
      height={height}
      fields={fields}
      formatter={formatPrice}
      tooltip={tooltip}
      {...otherProps}
    />
  );
};

export default TimeMachineTimeTunnelTotalEvGraph;
