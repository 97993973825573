import { useMemo } from 'react';
import {
 FarmApi, DexApi, SimulateApi, PoolApi, SimulatePoolApi,
} from '@iddy/time-machine';
import { getEnv } from 'config';

type Api<T> = { new(...args: any[]): T };

function createApi<T>(ApiClass: Api<T>, basePath: string): () => T {
    return (): T => {
        // const { accessToken } = useAuthContext();
        const accessToken = null;
        return useMemo(() => {
            if (accessToken) {
                return new ApiClass({
                    basePath,
                    baseOptions: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                });
            }
                return new ApiClass({ basePath });
        }, [accessToken]);
    };
}

export const useFarmApi = createApi(FarmApi, getEnv().REACT_APP_TIMEMACHINE_BASE);
export const useDexApi = createApi(DexApi, getEnv().REACT_APP_TIMEMACHINE_BASE);
// export const useSimulateApi = createApi(SimulateApi, getEnv().REACT_APP_TIMEMACHINE_BASE);
export const usePoolApi = createApi(PoolApi, getEnv().REACT_APP_TIMEMACHINE_BASE);
export const useSimulatePoolApi = createApi(SimulatePoolApi, getEnv().REACT_APP_TIMEMACHINE_BASE);
