import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import React from 'react';

const StyledBox = styled(Box)(
  sx({
    px: 1.25,
    py: 0.25,
    color: "primary.contrastText",
    background: "linear-gradient(117.77deg, rgba(134, 138, 255, 0.16) 9118.56%, rgba(121, 108, 239, 0.16) 11447.21%, rgba(104, 68, 216, 0.16) 15017.81%, rgba(97, 53, 208, 0.16) 16880.73%)",
    borderRadius: 20,
    typography: 'small',
    fontWeight: 600,
  })
)

const PreviewLabel = (props) => {

  return (
    <StyledBox {...props}>
      Preview
    </StyledBox>
  );
};

export default PreviewLabel;