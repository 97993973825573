import { FarmDataDto } from "@iddy/time-machine";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TabPanel } from "@mui/lab";
import { styled } from "@mui/material";
import { experimental_sx as sx } from "@mui/system";
import { TrendText, Text } from "components/Text";
import _ from "lodash";
import React from "react";
import { formatPercent } from "utils/formatNumber";

const StyledEmptyIcon = styled(ErrorOutlineIcon)(
  sx({
    fill: (theme) => theme.palette.error.main,
  })
)

const RealReturnTotal: React.FC<{value:FarmDataDto}> = ({
  value: v,
}) => {
  const totalReturn = (
    v ? 
      <TrendText
        bold
        value={_.get(v, 'totalReturn')}
      >
        {formatPercent(_.get(v, 'totalReturn'))}
      </TrendText>
    :
    <Text sx={{width: 20, textAlign: 'center'}} bold color="text.secondary">-</Text>
  )
  const sumImpermanentLoss = _.get(v, 'returnIncludeIl');
  const totalImpermanentLossReturn = (
    v ? 
      <TrendText
        bold
        value={sumImpermanentLoss}
      >
        {formatPercent(sumImpermanentLoss)}
      </TrendText>
    :
    <Text sx={{width: 20, textAlign: 'center'}} bold color="text.secondary">-</Text>
  )
  return <>
    <TabPanel sx={{p: 0}} value={'priceImpact'}>
      {totalReturn}
    </TabPanel>
    <TabPanel sx={{p: 0}} value={'impermanentLoss'}>
      {totalImpermanentLossReturn}
    </TabPanel>
  </>
}

export default RealReturnTotal;