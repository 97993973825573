import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTimeMachine } from 'state/timemachine/hooks';
import { useAppDispatch } from 'state';
import { setBasicInfoStatus, setChartStatus, setSummaryStatus } from 'state/timemachine';
import {
 DexDto, TokenDto, SimulationListDto, LastUpdateDateDto,
} from '@iddy/time-machine';
import { ChainDto as ConfigChainDto, chainArr } from 'config/chain';
import { DexDto as ConfigDexDto, dexArr } from 'config/dex';
import _ from 'lodash';
import {
 useFarmApi, useDexApi, useSimulatePoolApi, usePoolApi,
} from './api';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

export type FarmBasicInfo = {
    chain: string;
    exchange: string;
    lpSymbol: string;
    token: string;
    quoteToken: string;
    tokenName: string;
    quoteTokenName: string;
    rewardToken: string;
}

export type ChainDto = {
    chain: string,
    name: string,
}

export type TokenInfoDto = {
  id: number,
  poolId: string,
  chain: ConfigChainDto,
  exchange: ConfigDexDto,
  lpSymbol: string,
  token: TokenDto,
  quoteToken: TokenDto,
  rewardToken: TokenDto,
  price: {
    priceRatio: number,
    tokenPrice: number,
    quoteTokenPrice: number,
  },
  dateMinMax: {
    minDate: string,
    maxDate: string,
  },
  rewarderEntity?:RewarderEntity[]
}

export type RewarderEntity = {
    id:string,
    rewardToken:string,
    unit:string,
    rewardPerUnit: number,
    totalAllocPoint: number,
    rewardStartTimestamp:string,
    rewardEndTimestamp:string,
    created:string,
    rewardTokenEntity: {
        id:string,
        chain: string,
        address: string,
        decimals: number,
        symbol: string,
        name: string,
        chainId: number,
        projectLink?: string,
        isStable: boolean,
        isNative: boolean,
        created: string,
    }
}

export interface SummaryDataProps {
    name?: string;
    d1?: number;
    d7?: number;
    d30?: number;
    d90?: number;
    d180?: number;
    d365?: number;
    d1apr?: number;
    d7apr?: number;
    d30apr?: number;
    d90apr?: number;
    d180apr?: number;
    d365apr?: number;
  }

export function useChainList() {
    // TODO: Move the chain list to time machine return?
    const [data, setData] = useState<ConfigChainDto[]>();
    const [isReady, setIsReady] = useState(false);
    const fetch = async () => {
        setIsReady(false);
        setData([
            getChainBySymbol('bsc'),
            getChainBySymbol('avalanche'),
            getChainBySymbol('cronos'),
            getChainBySymbol('fantom'),
            getChainBySymbol('eth'),
            getChainBySymbol('polygon'),
        ]);
        setIsReady(true);
    };

    useEffect(() => {
        fetch();
    }, []);

    return {
        fetch,
        data,
        isReady,
    };
}

export function useDexList(opts?: {chain: string}) {
    const { chain } = opts ?? {};
    const api = useDexApi();
    const [data, setData] = useState<(DexDto & {name?: string, symbol?: string, launch?: boolean})[]>();
    const [isReady, setIsReady] = useState(false);
    const formatData = useMemo(() => {
        let filterData = data;
        if (chain) {
            filterData = _.filter(data, { chain });
        }
        return _.map(filterData, (v) => ({
                ...v,
                exchange: v.symbol,
            }));
    }, [data, chain]);

    const fetch = async () => {
        setIsReady(false);
        // const res = await api.get({});
        // setData(_.get(res, "data.result"));
        setData([
            getDexBySymbol('traderJoe'),
            getDexBySymbol('pancakeswap'),
            getDexBySymbol('vvs'),
            getDexBySymbol('spookyswap'),
            getDexBySymbol('mmf'),
            getDexBySymbol('uniswap'),
            getDexBySymbol('quickSwap'),
        ]);
        setIsReady(true);
    };

    useEffect(() => {
        fetch();
    }, []);

    return {
        fetch,
        data: formatData,
        isReady,
    };
}

export function useFarmDashboardList() {
    const api = useSimulatePoolApi();
    const [data, setData] = useState<{data: SimulationListDto[], count: number}>();
    const [isReady, setIsReady] = useState(false);

    const fetch = async (params) => {
        setIsReady(false);
        const res = await api.list(params);
        setData(_.get(res, 'data.result'));
        setIsReady(true);
    };

    return {
        fetch,
        data,
        isReady,
    };
}

export function useFarmPoolInfo() {
    const simulatePoolApi = useSimulatePoolApi();
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState<TokenInfoDto>();

    async function fetch(poolId) {
        setIsReady(false);
        let { data: { result } } = await simulatePoolApi.info(poolId);
        result = { ...result[0], ...result };
        delete result[0];
        const rewarderList = _.flatten(result.farmInfo.map(item => item['rewarderEntity']))

        const formatData:any = {
            ...result,
            poolId,
            rewarderEntity: rewarderList,
            chain: getChainBySymbol(result.chain),
            exchange: getDexBySymbol(result.exchange),
        };
        console.log('formatData', formatData)
        setData(formatData);
        setIsReady(true);
    }

    return {
        isReady,
        data,
        fetch,
    };
}

export function usePoolLastUpdateDate() {
    const poolApi = usePoolApi();

    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState<LastUpdateDateDto>();

    async function fetch(poolId) {
        setIsReady(false);
        const { data: { result } } = await poolApi.getlastUpdateDate(poolId);
        setData(result);
        setIsReady(true);
    }

    return {
        isReady,
        data,
        fetch,
    };
}

export const getChainBySymbol = (symbol) => _.find(chainArr, { symbol });

export const getDexBySymbol = (symbol) => _.find(dexArr, { symbol });

export function useGetAddrByRefId() {
    const farmApi = useFarmApi();
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState<string>();

    async function fetch(refId) {
        setIsReady(false);
        const { data: result } = await farmApi.getAddr(refId);
        setData(result);
        setIsReady(true);
    }

    return {
        isReady,
        data,
        fetch,
    };
}
