import React, { HTMLAttributes, useState } from 'react';
import { Divider, Grid, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Link, useRouteMatch, useHistory, NavLink } from 'react-router-dom';
import TokenPrice from 'components/TokenPrice';
import { useStrategies } from 'state/strategies/hooks';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMatchBreakpoints } from 'hooks/useResponsive';
import usePortfolioDrawer from 'hooks/useDrawer';
import Drawer from '@mui/material/Drawer';
import UserMenu from './UserMenu';
import { StyledDiv } from 'components/StyledDiv';
import images from 'assets/images';
import { Text } from 'components/Text';
import { experimental_sx as sx, grid } from '@mui/system';
import PreviewLabel from './Navigation/PreivewLabel';
import MenuItem from './Navigation/MenuItem';
import ComingSoonTooltip from 'components/ComingSoonTooltip';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { getEnv } from 'config';

interface MenuItemProps extends HTMLAttributes<HTMLDivElement> {
    to?: string;
}

// const MenuItem = styled('div')<MenuItemProps>`
//   text-transform: uppercase;
//   font-size: 14px;
//   padding: 16px;
//   font-weight: ${({ to }) => (useRouteMatch({ path: to, exact: true }) ? 'bold' : 400)};
//   background-image: ${({ to, theme }) => (useRouteMatch({ path: to, exact: true })
//     ? theme.palette.primary.mainGradient : 'none')};
//   background-color: ${({ theme }) => theme.palette.text.secondary};
//   background-size: 100%;
//   background-clip: text;
//   text-fill-color: transparent;
// `;

const StyledAppBar = styled(AppBar)`
  .mobile-icon {
    svg {
      transform: translate(0, 3px);
    }
    position: relative;
    border-radius: 5px;
    margin-right: -14px;
    margin-left: 0px;
    display: none;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: block;
    }
    &::after {
      position: absolute;
      top: 8px; bottom: 8px;
      left: 6px; right: 6px;
      background: ${({ theme }) => theme.palette.primary.light};
      content: '';
      z-index: -1;
      border-radius: 5px;
    }
  }
  display: flex;
  min-height: 60px;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  background-color: ${({ theme }) => theme.palette.background.navigation};
`;

const StyledMenuDrawer = styled(Drawer)(
  sx({
    minWidth: 200,
    "& .MuiPaper-root":{
      bgcolor: 'background.navigation',
    },
  })
)

const DrawerContainer = styled('div')`
  margin-top: 16px;
  padding: 80px 33px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BetaLabel = styled(Text)(
  sx({
    bgcolor: "primary.main",
    px: 1,
    borderRadius: 20,
    color: 'white',
    WebkitTextFillColor: 'white',
  })
)

const LogoWithTextIcon = styled('img')({
  height: '32px',
})

const LogoIcon = styled('img')({
  width: '32px',
  height: '32px',
  cursor: 'pointer',
})

const MenuItemLink = styled('span')(
  sx<Theme>({
    fontWeight: 700,
    WebkitTextFillColor: (theme) => theme.palette.primary.main
  })
)

const MenuItemList = styled('div')(
  sx({
    gap: 3,
    display: 'flex',
    alignItems: 'center',
  })
)

const StyledPerfectScrollbar = styled(PerfectScrollbar)(
  sx({
    flex: '1 1 max-content'
  })
)

const Menu:React.FC = () => {
  // const { singlePrice } = useStrategies();
  const singleFinanceBaseUrl = getEnv().REACT_APP_SINGLE_FINANCE_BASE;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { isMobile } = useMatchBreakpoints();
  const { open: portfolioDrawerOpen, setOpen: setPortfolioDrawerOpen } = usePortfolioDrawer();
  const handleClick = () => {
    if (portfolioDrawerOpen) { setPortfolioDrawerOpen(false); }
    setOpen((prev) => !prev);
  };
  const MenuItems = () => (
    <> 
      <MenuItem
        active={false}
        component={'a'}
        href={`${singleFinanceBaseUrl}/single-click`}
        target="_blank"
      >
        Strategy
      </MenuItem>
      <MenuItem
        active={false}
        component={'a'}
        href={`${singleFinanceBaseUrl}/farm`}
        target="_blank"
      >
        Farm
      </MenuItem>
      <MenuItem
        active={false}
        component={'a'}
        href={`${singleFinanceBaseUrl}/lend`}
        target="_blank"
      >
        Lending
      </MenuItem>
      <MenuItem
        active={false}
        component={'a'}
        href={`${singleFinanceBaseUrl}/stake`}
        target="_blank"
      >
        Locked Staking
      </MenuItem>
      <Divider sx={{ borderColor: 'greyColor.light' }} flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
      <MenuItem
        active={true}
        component={NavLink}
        to={'/'}
      >
        LP Time Machine
      </MenuItem>
    </>
  );
  return (
    <>
      <StyledAppBar position="sticky">
        <Toolbar
          variant="dense"
          sx={{
            py: 1.75,
            px: 2.5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >

          {!isMobile
            ? (
              <>
                <LogoWithTextIcon
                  src={images.logo}
                  onClick={() => history.push('/')}
                  style={{ cursor: 'pointer' }}
                />
                <PreviewLabel sx={{ml: 1}} />
                <StyledPerfectScrollbar sx={{ml: 2}}>
                  <MenuItemList>
                    <MenuItems />
                  </MenuItemList>
                </StyledPerfectScrollbar>
              </>
            )
            : (
              <>
                <LogoIcon 
                  src={images.logoIcon}
                  onClick={() => history.push('/')}
                />
                <PreviewLabel sx={{ml: 1}} />
              </>
            )}
          <StyledDiv sx={{
            display: 'flex',
            ml: 'auto',
          }}>
            {/* <TokenPrice tokenPriceUsd={singlePrice} /> */}
            {/* <UserMenu /> */}
            <IconButton
              color="primary"
              size="large"
              edge="start"
              aria-label="menu"
              className="mobile-icon"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </StyledDiv>
        </Toolbar>
      </StyledAppBar>
      <StyledMenuDrawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <MenuItems />
        </DrawerContainer>
      </StyledMenuDrawer>
    </>
  );
};

export default Menu;
