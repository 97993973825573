import { styled } from '@mui/material/styles';
import { Box, experimental_sx as sx } from '@mui/system';
import { Text } from 'components/Text';
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { GradientSpan } from 'components/GradientSpan';
import { FormProvider } from 'react-hook-form';
import _ from 'lodash';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import images from 'assets/images';
import { DetailPageContextProvider, useDetailPageContext } from '../context';
import TimeMachineDetailHeader from './TimeMachineDetailHeader';
import TimeMachineDetailPairInfo from './TimeMachineDetailPairInfo';
import TimeMachineTimeTunnelFilter from './TimeMachineTimeTunnelFilter';
import { useFarmFilterForm } from '../hooks/useFarmFilterForm';
import { useFarmPerformanceGraphData } from '../hooks/useFarmPerformanceGraphData';
import TimeMachineTimeTunnelDetail from './TimeMachineTimeTunnelDetail';

const BannerImage = styled('img')(
  sx({
    display: {
      xs: 'none',
      md: 'block',
    },
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 200,
    height: 'auto',
  }),
);

const NewLabel = styled(Text)(
  sx({
    height: 'min-content',
    background: '#00DB5C',
    ml: 1,
    px: 1,
    position: 'relative',
    bottom: 0,
    borderRadius: 20,
    color: 'white',
  }),
);

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 120,
  position: 'relative',
});

const TimeMachineTimeTunnelController = (props) => {
  const { info } = useDetailPageContext();
  const methods = useFarmFilterForm();
  const graph = useFarmPerformanceGraphData();
   const [filterData, setFilterData] = useState<any>(null);
  const onSubmit = () => {
    if (methods.getValues('principal')) {
 setFilterData({
      poolId: info.poolId,
      principal: methods.getValues('principal'),
      fromDate: methods.getValues('startDate'),
      toDate: methods.getValues('endDate'),
    });
}
  };
  useEffect(() => {
    if (info) {
      setFilterData({
        poolId: info.poolId,
        principal: methods.getValues('principal'),
        fromDate: methods.getValues('startDate'),
        toDate: methods.getValues('endDate'),
      });
    }
  }, [info]);
  useEffect(() => {
    if (filterData) {
      graph.fetch(filterData);
    }
  }, [filterData]);
  if (!info) {
    return <></>;
  }
  const detailComponent = useMemo(() => (
    <TimeMachineTimeTunnelDetail
      isReady={graph.isReady}
      data={graph.data}
      summary={graph.summary}
      principal={_.get(filterData, 'principal')}
    />
), [graph.isReady]);
  return (
    <FormProvider {...methods}>
      <Box {...props}>
        <Header>
          <Grid container justifyContent="space-between" gap={2}>
            <Grid container item flex="1 1 10%">
              <InlineMiddleFlex alginItem="center">
                <Text component={GradientSpan} sx={{ verticalAlign: 'top', height: 'min-content' }} typography="h3" bold>
                  Time Tunnel
                </Text>
                <NewLabel component="span" typography="caption" bold>Featured</NewLabel>
              </InlineMiddleFlex>
              <Text typography="body2" bold="500" align="justify" sx={{ maxWidth: 500 }}>
                <b>History doesn't repeat itself, but it often rhymes.</b>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ whiteSpace: 'nowrap' }}>– Mark Twain</span>
              </Text>
            </Grid>
            <Grid item flex="0 0 200px">
              <BannerImage src={images.timeMachineBg} />
            </Grid>
          </Grid>
        </Header>
        <Box mb={2}>

          <Grid container gap={2}>
            <Grid container item flex="1 1 100%">
              <TimeMachineTimeTunnelFilter
                info={info}
                onSubmit={onSubmit}
              />
            </Grid>
            {detailComponent}
          </Grid>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default TimeMachineTimeTunnelController;
