import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import DiscordIcon from 'components/Svg/Icons/DiscordIcon';
import MediumIcon from 'components/Svg/Icons/MediumIcon';
import { Text } from 'components/Text';
import React from 'react';

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const SocialIconLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Content = styled(Container)(
  sx({
    my: {
      xs: 3,
      md: 7.5,
    },
  }),
);

const Footer = () => (
  <Content>
    <InlineMiddleFlex sx={{ width: '100%', flexWrap: 'wrap' }} justifyContent="space-between">
      {/* <List sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        mt: 2,
        mb: 2,
        flexBasis: {
          xs: '100%',
          md: 'auto',
        },
      }}
      >
        <ListItem>
          <SocialIconLink href="https://twitter.com/single_finance" target="_blank">
            <TwitterIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://medium.com/singlefinance" target="_blank">
            <MediumIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://discord.com/channels/911447005686026301/911480418451218463" target="_blank">
            <DiscordIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://t.me/singlefinanceofficial" target="_blank">
            <TelegramIcon />
          </SocialIconLink>
        </ListItem>
        <ListItem>
          <SocialIconLink href="https://www.linkedin.com/company/singlefinance/" target="_blank">
            <LinkedInIcon />
          </SocialIconLink>
        </ListItem>
      </List> */}
      <Text
        sx={{
          flexBasis: {
            xs: '100%',
            md: 'auto',
          },
          ml: 'auto',
        }}
        bold
        typography="body2"
      >
        Copyright ©️
        {' '}
        {(new Date()).getFullYear()}
        {' '}
        Single Finance. All rights reserved.
      </Text>
    </InlineMiddleFlex>
  </Content>
);

export default Footer;
