/**
 * Tracking Mixpanel context
 */

 import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
 import React, { createContext, useContext, useEffect, useState } from 'react';
 import _ from 'lodash';
import { useRouter } from 'hooks/useRouter';
import { getEnv } from 'config';

 mixpanel.init(getEnv().REACT_APP_MIXPANEL);
 
 type MixpanelContextData = {
   mixpanel: OverridedMixpanel
 }
 
 const MixpanelContext = createContext<MixpanelContextData>({
   mixpanel: null,
 });
 
 export function useMixpanelContext(){
   return useContext(MixpanelContext)
 }
 
 export function MixpanelProvider(props){
   const {children} = props;
   const router = useRouter();
   const trackPageView = () => {
     mixpanel.track('Page_Viewed', {
       page_name: router.location.pathname + router.location.search,
     })     
   }
   useEffect(() => {
     trackPageView();
   }, [router]);
   return (
     <MixpanelContext.Provider value={{mixpanel}}>
         {children}
     </MixpanelContext.Provider>
   )
 }