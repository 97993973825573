import { Box, Theme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import { InlineMiddleFlex } from 'components/InlineMiddleFlex';
import React from 'react';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "comingSoon"
})<{
  active: boolean,
  comingSoon?: boolean,
}>( (props) => 
  sx<Theme>([
    {
      cursor: 'pointer',
      color: 'primary.contrastText',
      display: 'flex',
      flexDirection: 'column',
      typography: 'body2',
    },
    props.active && {
      fontWeight: 700
    },
    props.comingSoon && {
      color: (theme) => alpha(theme.palette.greyColor.light, 0.4),
    },
  ])
)

export const CircleIcon = styled(Box)<{background?: any}>(({background}) => 
  sx({
    width: 6,
    height: 6,
    borderRadius: '50%',
    display: 'inline-block',
    background: "linear-gradient(308.83deg, #F7971E 3.08%, #FFD200 105.43%, #FFD200 105.43%)",
  })
)

const MenuItem:React.FC<{
  active?: boolean,
  comingSoon?: boolean,
  [key: string]: any,
}> = ({
  active,
  comingSoon,
  children,
  ...otherProps
}) => {
  return (
    <StyledBox 
      active={active}
      comingSoon={comingSoon}
      {...otherProps}
    >
      {children}
      {active && (
        <InlineMiddleFlex>
          <CircleIcon sx={{margin: '0 auto'}} />
        </InlineMiddleFlex>
      )}
    </StyledBox>
  );
};

export default MenuItem;